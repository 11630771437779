import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Gpt } from 'react-gpt-ads';
// import "./videoicons.css";
import { SlArrowDown } from "react-icons/sl";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import debounce from 'lodash.debounce';

import Footer from "../../Componets/Footer/Footer";
import { useState } from "react";
import { horizontalmodel } from "../../layout/gridmodel";
import axios from "axios";
import {
  BASE_URL,
  acceptedMediaTypes,
  access_token,
  auth_token,
} from "../../services/config";
import VideoPlayer from "../Videoplayer/VideoPlayer";
import EpisodeSubCategories from "./episodesubcategories";
import LiveVideoPlayer from "../Videoplayer/LiveVideoPlayer";
import { setRelatedDetails } from "../../Redux/Slice/showSlice";
import Loader from "../../Componets/loader";
import { setIsrelatedVideosClicked, setVideoDetails } from "../../Redux/Slice/videodetailSlice";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { setEpisodeDates } from "../../Redux/Slice/episodeSlice";
import FilterEpisodesResult from "./filterresult";
import { setFilterApplied } from "../../Redux/Slice/filterappliedSlice";
import { setFilterEpisodeData, setFilterEpisodeItemsData } from "../../Redux/Slice/filterEpisodeSlice";
import { incrementFilterPage, setFilterPage } from "../../Redux/Slice/filterdateSlice";
import { setSeasondropdownData } from "../../Redux/Slice/seasonsSlice";
// import GPTAdTwo from "./gptaddtwo";
import { guestuserid } from "../../App";
import GPTAdThree from "./gptaddthree";
import GPTAd from "./gptadd";
import { setMainTraynamedata } from "../../Redux/Slice/navlinkcallSlice";
import { setThroughSeach } from "../../Redux/Slice/throughSearchSlice";

const VideoDetails = () => {
  function getPageName() {
    const path = window.location.pathname;
    const parts = path.split('/'); // Split path by '/'
    return parts[1] ?? "home"; // Return the second part after localhost/
  }
  const sourcetab = localStorage.getItem("selectedMenuItem");
  useEffect(() => {
    const isMobile = window.innerWidth <= 768; 

    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, []);
  const vegicon = "/images/veg.png";
  const nonvegicon = "/images/nonveg.png";
  const verticaldefultimage = "/images/horizontaldefault.jpg";
  const getLoginStatus = localStorage.getItem("login_status")
  const getUserId = localStorage.getItem("user_id");

  const adSlotRef = useRef(null);
  const regionsData = useSelector((state) => state.region.regionsData);
  const { filterEpisodeData, filterEpisodeItemsData } = useSelector(
    (state) => state.filter_episode_details
  );
  const location = useLocation();
  let isSliding = false;
  const [datacontentId, setdatacontentId] = useState("");
  const [redirectSeourl, setredirectSeourl] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const effectRan = useRef(false)

  const [hasFilterApplied, setHasFilterApplied] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const [catalogListData, setCatalogListData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
  });
  const {filterPage, filterFromDate, filterToDate} = useSelector(
    (state) => state.filter_date
  );

  const [page, setPage] = useState(0);
  const [fromItem, setFromItem] = useState(0);
  const [isMoreAdded, setIsMoreAdded] = useState(true);
  const [selectedTab, setSelectedTab] = useState("relatedinfo");
  const [subcategoriesdata, setSubcategoriesdata] = useState([]);
  const [subcatUrl, setSubcatUrl] = useState("");
  const [contentiddata, setContentiddata] = useState("");
  const [catalogiddata, setCatalogiddata] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterdataLoading, setFilterdataLoading] = useState(false);
  const [moreDetailsLoading, setMoreDetailsLoading] = useState(true);
  const [mediaList, setMediaList] = useState("");
  const [subcategoryrelated, setSubcategoryrelated] = useState({});
  const [subcategoryrelatedList, setSubcategoryrelatedList] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [episodetypedetails, setEpisodetypedetails] = useState({});
  const [promotypedetails, setPromotypedetails] = useState({});
  const [miniclipsdetails, setMiniclipsdetails] = useState({});
  const [behindthescenesdetails, setBehindthescenesdetails] = useState({});
  const [movieVideoListsData, setMovieVideoListsData] = useState(null);
  const [movieVideoListsCategories, setMovieVideoListsCategories] =
    useState(null);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [medialistsdata, setMedialistsdata] = useState([]);
  const [mediaListsDataLoading, setMediaListsDataLoading] = useState(false);
  const [selectedlng, setSelectedlng] = useState("eng");
  const [showPerformance, setShowPerformance] = useState(false);
  const performanceMenuRef = useRef(null);
  const [isRequiredRelated, setIsRequiredRelated] = useState(0);
  const [episodeloadTypeTags, setEpisodeloadTypeTags] = useState([]);
  const [isrecordsfound, setIsrecordsfound] = useState(true);
  const [isdropdownContentSelected, setIsdropdownContentSelected] = useState(false)
  const [selectedValue, setSelectedValue] = useState('');

  // const showPerformanceDropdown = () => {
  //   setIsdropdownContentSelected(true)
  //   setShowPerformance(!showPerformance);
  // };


    useEffect(() => {
    setShowThumbnail(true); 
  }, [isdropdownContentSelected])

  useEffect(() => {
    localStorage.setItem("more_parent_id", 0);
    localStorage.setItem("more_category", "");
    const handleClickOutside = (event) => {
      if (
        performanceMenuRef.current &&
        !performanceMenuRef.current.contains(event.target)
      ) {
        setShowPerformance(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { category, main, subcategory, seo_url, seo_url_friendly } =
    useParams();

    // const { thumbnailposition} = useSelector((state) => state.navmenu_called);

    // console.log("thumbnailpositionsd", thumbnailposition)

  const getDetailsUrl = () => {

    if (category === "telugu-movies" || category === "original-movies") {
      // return `${BASE_URL}catalogs/${category}/items/${seo_url}?auth_token=${auth_token}&access_token=${access_token}`;
      
      return `${BASE_URL}catalogs/${category}/items/${seo_url}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
      } else if (category === "shows") {
        if (category && seo_url) {
          return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
          } else {
            return `${BASE_URL}catalogs/${category}/items/${subcategory}/subcategories/${main}/episodes/${seo_url_friendly}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
            }
            } else if (category === "events") {
              if (category && subcategory) {
                return `${BASE_URL}catalogs/${category}/items/${subcategory}/episodes/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
                } else {
                  return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
                  }
                  } else if (category === "win-exclusive") {
                    return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
                    }
                    // else if (category === "original-movies") {
                      //   return `${BASE_URL}catalogs/${category}/items/${seo_url}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
                      // }
                      else if (category === "serials") {
                        return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
                        } else if (category === "news") {
                          if (category && subcategory) {
                            return `${BASE_URL}catalogs/${category}/items/${subcategory}/episodes/${seo_url}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
                            } else {
                            }
                            } else if (seo_url === "channels") {
                              return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
                              } else if (category === "recipes") {
                                return `${BASE_URL}catalog_lists/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;
                                } else {
                                  if (category !== "" && seo_url !== "") {
        // console.log("catatatta",category )
        return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      } else if (category !== "" && seo_url !== "" && seo_url_friendly !== "") {
        return `${BASE_URL}catalogs/${category}/items/${seo_url_friendly}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      }
    }
  };

  const detailsUrl = getDetailsUrl();

  const fetchDetailsPage = async (url) => {
    setLoading(true);
    setMoreDetailsLoading(true);
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        setLoading(false);
        const genres = response?.data?.data.genres;
        // const relatedcontent_url = `${BASE_URL}/catalogs/${category}/items/${response?.data?.data?.friendly_id}/related.gzip??item_language=${selectedlng}&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&genres=${genres}`;
        // fetchRelatedVideosData(relatedcontent_url);
        setSubCat(response?.data?.data?.subcategories || []);
        const lastEpisode = response?.data?.data?.last_episode;
        // if (category !== "live") {
        //   if (response.data.data.last_episode.seo_url !== "") {
        //     setredirectSeourl(response.data.data.last_episode.seo_url);
        //   }
        // }

        if (lastEpisode) {
          setContentiddata(
            lastEpisode.content_id || response?.data?.data?.content_id
          );
          setdatacontentId(
            lastEpisode.content_id || response?.data?.data?.content_id
          );
          setCatalogiddata(
            lastEpisode.catalog_id || response?.data?.data?.catalog_id
          );
        } else {
          setContentiddata(response?.data?.data?.content_id);
          setCatalogiddata(response?.data?.data?.catalog_id);
          setdatacontentId(response?.data?.data?.content_id || response?.data?.data?.content_id);
        }

        fetchVideoLists(response?.data?.data?.content_id);

        if (response?.data?.data?.catalog_list_items) {
          setMoreDetailsLoading(false);
          setCatalogListData((prevData) => ({
            ...prevData,
            data: [
              ...(prevData.data || []),
              ...(response?.data?.data?.catalog_list_items || []),
            ],
            total_items_count: response?.data?.data?.total_items_count || 0,
            title: response?.data?.data?.display_title,
          }));
        } else {
          setDetailsData(response?.data?.data);
          dispatch(
            setVideoDetails({
              videodetailsitems: response.data.data,
            })
          );
        }
        setSubcategoriesdata(response?.data?.data?.subcategories);

        if (category !== "channels") {
          const subcategoriess = response?.data?.data?.subcategories;
          const catg = subcategoriess && subcategoriess.length > 0 && subcategoriess.map((item) => item?.friendly_id);
          // const friendlyId = catg[0];
          const friendlyId = catg?.[0];
          // console.log("hssjjjs", friendlyId)
          setSubcatUrl(friendlyId);
          const medialist = response?.data?.data?.subcategories?.map(
            (item, index) => item.episodetype_tags.map((it, innerIndex) => it)
          );
          
          var totalcount_items_related = 0; 
           const loadTypeTags = [];
          if (response?.data?.data?.subcategories?.length > 0) {
            if (response?.data?.data?.subcategory_flag === "yes") {
              const zerosubcat = response?.data?.data?.subcategories[0];
              totalcount_items_related = zerosubcat.total_items_count;
                loadTypeTags.push(zerosubcat?.episodetype_tags);
            }else{
              loadTypeTags.push(response?.data?.data?.episodetype_tags);
            }
          }else{
            loadTypeTags.push(response?.data?.data?.episodetype_tags);
          }
           if(loadTypeTags.length > 0){
            loadTypeTags[0]?.forEach(
              (subitem, subindex) => {
                  EpisoderelatedVideosData(
                    subitem?.display_title,
                    subitem?.name,
                    selectedlng,
                    response?.data?.data?.friendly_id
                  );
            
               if (subitem?.item_type === "list-2d") {
                  // setEpisodetypedata(item?.episodetype_tags);
                  setMediaListsDataLoading(true);
                  fetchMediaLists(
                    subitem?.display_title,
                    subitem.media_list,
                    selectedlng
                  );
                }
              }
            );
           }
           
          // Flatten the nested arrays
          const flatMedialist = [].concat(...medialist);

          // Find the object with the name "mini_clips"
          const miniClipsObject = flatMedialist.find(
            (item) => item.name === "mini_clips"
          );

          // Get the media_list value if the object is found
          const mediaListValue = miniClipsObject
            ? miniClipsObject.media_list
            : null;

          setMediaList(mediaListValue);
        }
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };


  const fetchMediaLists = async (display_title, friendly_id, selectedlng) => {
    try {
      const fetchmediaurl = `${BASE_URL}/catalog_lists/${friendly_id}.gzip?item_language=${selectedlng}&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      const response = await axios.get(fetchmediaurl);
      if (response.status === 200) {
        if (response.data.data) {
          const catalogListItems = response.data.data.catalog_list_items;
          dispatch(setSeasondropdownData({ seasondropdownData: catalogListItems }));
          if (Array.isArray(catalogListItems)) {
            if (catalogListItems.length > 0) {
              setIsRequiredRelated(1);
            }
            const newMediaListsData = [];
            catalogListItems.forEach((element) => {
              const isDuplicate = newMediaListsData.some(
                (item) => item.display_title === element.display_title
              );
              if (!isDuplicate) {
                newMediaListsData.push({
                  display_title: element.display_title,
                  Item: element.catalog_list_items,
                  seo_url: element.seo_url,
                  total_items_count: element.total_items_count,
                });
              }
            });
            setMedialistsdata(newMediaListsData);
          } else {
            console.error("catalogListItems is not an array.");
          }
        } else {
          console.error("No data found in response.data.data.");
          setMediaListsDataLoading(false);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    // When the component loads, check if the current URL matches any of the subCat URLs
    const matchedItem = subCat.find(item => item.seo_url === location.pathname);
    if (matchedItem) {
      setSelectedValue(matchedItem.title); // Set the dropdown value based on the current URL
    }
  }, [location, subCat]);

  const episodeseas = subCat ? subCat.map((item) => item.friendly_id) : "";

  const episodeSeason =
    episodeseas && episodeseas?.length > 0 && episodeseas[0];

  const getSubCatgEpisodetypeurl = `${BASE_URL}catalog_lists/${mediaList}.gzip??item_language=eng&region=${regionsData?.country_code2}&nested_list_items=false&auth_token=${auth_token}&access_token=${access_token}`;

  const episodesdatesURL = `${BASE_URL}catalogs/${category}/items/${seo_url}/subcategories/${episodeSeason}/episode_dates?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;

  const getEpisodedates = async () => {
    try {
      const response = await axios.get(episodesdatesURL);
      if (response.status === 200) {
        dispatch(
          setEpisodeDates({
            episodeDates: response?.data?.data?.episode_dates,
          })
        );
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };

  const EpisoderelatedVideosData = async (
    display_title,
    episode_type,
    selectedlng,
    subcatUrl
  ) => {
    try {
      const episodetypeepisode = `${BASE_URL}catalogs/${category}/items/${subcatUrl}/episodes.gzip?item_language=${selectedlng}&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&episode_type=${episode_type}`;

      const response = await axios.get(episodetypeepisode);
            // console.log("mediatype",episode_type+"--"+response?.data?.data?.total_items_count);
      if (response.status === 200) {
          if (response?.data?.data?.total_items_count > 0) {
             setIsRequiredRelated(1);
          }
          if (episode_type === "promo") {
            setPromotypedetails(response?.data?.data);
          }
          if (episode_type === "mini_clips") {
            setMiniclipsdetails(response?.data?.data);
          }
  
          if (episode_type === "behind_the_scenes") {
            setBehindthescenesdetails(response?.data?.data);
          }
          if (episode_type === "episode") {
            setEpisodetypedetails(response?.data?.data);
          }
        
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };

  const fetchsubcategoryrelated = async () => {
    try {
      const response = await axios.get(getSubCatgEpisodetypeurl);
      if (response.status === 200) {
        if (response?.data?.data?.catalog_list_items.length > 0) {
          setIsRequiredRelated(1);
        }
        setSubcategoryrelated(response?.data?.data);
        setSubcategoryrelatedList(response?.data?.data?.catalog_list_items);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };

  
  const { filterApplied } = useSelector(
    (state) => state.filter_applied
  );
  
  useEffect(() => {
    setHasFilterApplied(filterApplied);
  }, [filterApplied]); 
  

  
useEffect(() => {
  dispatch(setFilterEpisodeItemsData({filterEpisodeItemsData: []}))
  dispatch(setFilterPage({filterPage: 0})) 
}, [location.pathname])
  
  
  useEffect(() => {
    dispatch(
      setFilterApplied({
        filterApplied: false
      })
    );
  }, [location.pathname]);

  useEffect(() => {
    if (category === "shows" && mediaList) {
      fetchsubcategoryrelated();
    } else {
      return;
    }
  }, [category, mediaList]);

  const fetchRelatedVideosData = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        if (response?.data?.data?.items?.length > 0) {
          setIsRequiredRelated(1);
        } else {
        }
        dispatch(setRelatedDetails({ relatedDetails: response?.data?.data }));
        localStorage.setItem("relatedtitle", JSON.stringify(response?.data?.data?.title))
      } else {    
      }
    } catch (error) {
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };

  // useEffect(() => {
  //   fetchDetailsPage(detailsUrl);
  // }, [detailsUrl, catalogiddata, contentiddata]);





  // const relatedcontent_url = `${BASE_URL}/catalogs/${category}/items/${response?.data?.data?.friendly_id}/related.gzip??item_language=${selectedlng}&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&genres=${genres}`;
  // fetchRelatedVideosData(relatedcontent_url);

  useEffect(() => {
    if (!effectRan.current || contentiddata) {
      fetchDetailsPage(detailsUrl);
      return () => {
        effectRan.current = true
        setMediaListsDataLoading(false)
        setMedialistsdata([]); 
        setEpisodetypedetails({})
        setPromotypedetails({})
        setMiniclipsdetails({})
      }
    }
  }, [detailsUrl]);





  useEffect(() => {
    // Set up ad refresh every 10 seconds
    const refreshInterval = setInterval(() => {
      if (window.googletag && adSlotRef.current) {
        window.googletag.pubads().refresh([adSlotRef.current]);
        console.log('Ad refreshed');
      }
    }, 10000); // Refresh every 10 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(refreshInterval);
  }, [detailsUrl, location.pathname, catalogiddata, contentiddata]);


  // useEffect(() => {
  //   return () => {
  //     setMedialistsdata([]); // Reset media list data on component unmount
  //   };
  // }, []);


  useEffect(() => {
    if (detailsData?.friendly_id && detailsData?.genres) {
      const genres = detailsData.genres;
      const relatedcontent_url = `${BASE_URL}/catalogs/${category}/items/${detailsData.friendly_id}/related.gzip??item_language=${selectedlng}&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&genres=${genres}`;
      fetchRelatedVideosData(relatedcontent_url);
     
    }
  }, [catalogiddata, contentiddata]);
  
  
  // useEffect(() => {
  //   if (category !== "channels") {
  //     const catg = detailsData.subcategories?.map(
  //       (item, index) => item.friendly_id
  //     );
  //     const friendlyId = catg?.[0];
  //     setSubcatUrl(friendlyId);

  //     // Check if subcategories and their episodetype_tags are defined
  //     const medialist = detailsData.subcategories?.map((item, index) =>
  //       item.episodetype_tags?.map((it, innerIndex) => it)
  //     ) || [];

  //     let totalcount_items_related = 0;
  //     const loadTypeTags = [];

  //     if (detailsData.subcategories?.length > 0) {
  //       if (detailsData.subcategory_flag === "yes") {
  //         const zerosubcat = detailsData.subcategories[0];
  //         totalcount_items_related = zerosubcat.total_items_count;
  //         loadTypeTags.push(zerosubcat.episodetype_tags);
  //       } else {
  //         loadTypeTags.push(detailsData.episodetype_tags);
  //       }
  //     } else {
  //       loadTypeTags.push(detailsData.episodetype_tags);
  //     }

  //     if (loadTypeTags.length > 0) {
  //       loadTypeTags[0]?.forEach((subitem, subindex) => {
  //         EpisoderelatedVideosData(
  //           subitem.display_title,
  //           subitem.name,
  //           selectedlng,
  //           detailsData.friendly_id
  //         );

  //         if (subitem.item_type === "list-2d") {
  //           setMediaListsDataLoading(true);
  //           fetchMediaLists(subitem.display_title, subitem.media_list, selectedlng);
  //         }
  //       });
  //     }

  //     // Flatten the nested arrays
  //     const flatMedialist = [].concat(...medialist);

  //     // Find the object with the name "mini_clips"
  //     const miniClipsObject = flatMedialist.find(
  //       (item) => item.name === "mini_clips"
  //     );

  //     // Get the media_list value if the object is found
  //     const mediaListValue = miniClipsObject ? miniClipsObject.media_list : null;

  //     setMediaList(mediaListValue);
  //   }
  // }, [category])
  

  useEffect(() => {
    if (episodeSeason) {
      getEpisodedates();
    }
  }, [episodeSeason]);

  // useEffect(() => { 
  //   window.addEventListener("scroll", loadMoreData);
  // }, [isMoreAdded]);

  // const loadMoreData = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop >=
  //     document.scrollingElement.scrollHeight - 100
  //   ) {
  //     if ((page + 1) * 24 >= catalogListData?.data?.total_items_count) return;

  //     let pageNumber = page + 1;
  //     let from = page * 24;
  //     window.removeEventListener("scroll", loadMoreData);
  //     setPage(pageNumber);
  //     setFromItem(from);
  //     setTimeout(() => {
  //       setIsMoreAdded(!isMoreAdded);
  //     }, 1000);
  //   }
  // };


  const loadMoreData = () => {
    // Ensure scrollingElement is available and fallback safely
    const scrollingElement = document.scrollingElement || document.documentElement;
  
    if (scrollingElement) {
      // Check if we are near the bottom of the page
      if (window.innerHeight + scrollingElement.scrollTop >= scrollingElement.scrollHeight - 100) {
        if ((page + 1) * 24 >= catalogListData?.data?.total_items_count) return;
  
        let pageNumber = page + 1;
        let from = page * 24;
  
        // Temporarily remove scroll listener to prevent multiple calls
        window.removeEventListener("scroll", loadMoreData);
  
        // Update states
        setPage(pageNumber);
        setFromItem(from);
  
        // Add delay before re-adding the scroll listener
        setTimeout(() => {
          setIsMoreAdded(!isMoreAdded);
        }, 1000);
      }
    }
  };
  
  
  useEffect(() => {
    const handleScroll = () => loadMoreData();
    
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll); 
    };
  }, [isMoreAdded]); 
  

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}${month}${day}`;
  };


  function formatReleaseDate(dateString) {
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/,/g, '');
  }

  const fetchNextPage = async () => {
    if (filterdataLoading) return;
    setFilterdataLoading(true);
    try {
        const formattedFromDate = filterFromDate ? formatDate(filterFromDate) : "";
        const formattedToDate = filterToDate ? formatDate(filterToDate) : "";
        const nextPage = filterPage + 1; 
        const dataFilterURL = `${BASE_URL}catalogs/${category}/items/${seo_url}/episodes.gzip?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&start_time=${formattedFromDate}&end_time=${formattedToDate}&page=${nextPage}`;
        const response = await axios.get(dataFilterURL);
        if (response.status === 200) {
            dispatch(setFilterEpisodeItemsData({
                filterEpisodeItemsData: [...filterEpisodeItemsData, ...response.data.data.items]
            }));

            
            dispatch(setFilterEpisodeData({
              filterEpisodeData: [...filterEpisodeData, ...response.data.data]
          }));

            

            dispatch(setFilterPage({
                filterPage: nextPage // Update filterPage to nextPage
            }));

            setFilterdataLoading(false);

            if (response?.data?.data?.items?.length === 0) {
                setIsrecordsfound(false);
            }
        }
    } catch (error) {
        console.error("Error fetching details:", error);
        setFilterdataLoading(false);
    }
};

  
  
const handleScroll = debounce(() => {
  if (!filterdataLoading && filterEpisodeItemsData?.length > 0 && isrecordsfound && window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
      fetchNextPage();
  }
}, 300); 

useEffect(() => {
  window.addEventListener('scroll', handleScroll);

  return () => {
      window.removeEventListener('scroll', handleScroll);
  };
}, [filterdataLoading, filterPage, filterEpisodeItemsData]);

  const fetchVideoLists = async (selectedcontentid) => {
    try {
      const movieVideoListsURL = `${BASE_URL}/catalog_lists/movie-videolists/?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData.country_code2}&parent_id=${selectedcontentid}`;
      const response = await axios.get(movieVideoListsURL);
      if (response.status === 200) {
        if (response?.data?.data.catalog_list_items.length > 0) {
          setIsRequiredRelated(1);
        }
        setMovieVideoListsData(response.data.data);
        setMovieVideoListsCategories(response.data.data.catalog_list_items);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const param1Value = getPageName();
  const renderFiltereditemTitle = filterEpisodeItemsData.map((item) => item?.show_name);

  const handleGotoPage = async (link, data, index, datamain) => {
    dispatch(setThroughSeach({ throughSeach: false }));
    // console.log("ssbbsbsss", index)
    // console.log("datamainsss", datamain)
    // console.log("hshshhshs", data)
    // console.log("linksjjsjs", link)
    dispatch(setMainTraynamedata({ maintraynamedata:  datamain}));

    setDetailsData(data);
    dispatch(setIsrelatedVideosClicked({ isrelatedVideosClicked: true }))
    setCatalogiddata(data.catalog_id);
    setContentiddata(data.content_id);
    const dynamicUrl = link.startsWith("/") ? link : `/${link}`;
    navigate(dynamicUrl);
    setShowThumbnail(true);
   

    // console.log("param1Valuejsjs", param1Value)

    if (window && window.sdk && window.sdk.trackEvent) {
      window.sdk.trackEvent("artwork_tapped", {
        source: sourcetab?.toLowerCase(),
        video_name: data?.title,
        tray_name: datamain?.display_title?.toLowerCase(),
        platform: "web",
        event_id: 21,
        event_time: new Date().toISOString().slice(0, 19),
        u_id: getLoginStatus === "true" ? getUserId : guestuserid,
        country: regionsData?.country_name,
        content_type: data?.media_type ? data?.media_type : 'NA',
        position_within_tray: index + 1,
        artwork_type: data?.media_type ? data?.media_type : 'NA',
      });
    }
  };


  const getContentInfo = (data, index) => {
    dispatch(setThroughSeach({ throughSeach: false }));
    // console.log("bsbsbsbbs", data)
    if (window && window.sdk && window.sdk.trackEvent) {
      window.sdk.trackEvent("artwork_tapped", {
        source: sourcetab?.toLowerCase(),
        video_name: data?.title,
        tray_name: "NA",
        platform: "web",
        event_id: 21,
        event_time: new Date().toISOString().slice(0, 19),
        u_id: getLoginStatus === "true" ? getUserId : guestuserid,
        country: regionsData?.country_name,
        content_type: data?.media_type ? data?.media_type : 'NA',
        position_within_tray: index + 1,
        artwork_type: data?.media_type ? data?.media_type : 'NA',
      });
    }
  }

  const handleMore = (link, data, identifyunic) => {
    localStorage.removeItem("event_title");
    localStorage.removeItem("event_genres");
    localStorage.removeItem("event_identifyunic");
    localStorage.removeItem("event_seo_url_friendly");
    localStorage.removeItem("event_seo_url");
    localStorage.removeItem("relatedmovietitle");
  
    localStorage.removeItem("event_first");
    localStorage.removeItem("event_secund");
    localStorage.removeItem("event_three");
    localStorage.removeItem("event_four");
    localStorage.removeItem("event_five");
    localStorage.removeItem("SublistTitle");
    localStorage.setItem("more_parent_id", data.parent_id);
    localStorage.setItem("more_category", data.category);

    if (identifyunic == "all") {
      let urlmore = "/more" + link;
      navigate(urlmore);
    }
  };
  const handleRelatedMore = (link, data, identifyunic,relatedmovietitle) => {
    localStorage.removeItem("event_title");
    localStorage.removeItem("event_genres");
    localStorage.removeItem("event_identifyunic");
    localStorage.removeItem("event_seo_url_friendly");
    localStorage.removeItem("event_seo_url");
    localStorage.removeItem("relatedmovietitle");
  
    localStorage.removeItem("event_first");
    localStorage.removeItem("event_secund");
    localStorage.removeItem("event_three");
    localStorage.removeItem("event_four");
    localStorage.removeItem("event_five");
    localStorage.removeItem("SublistTitle");
    localStorage.setItem("relatedmovietitle", relatedmovietitle);
    localStorage.setItem("event_seo_url", link);
    localStorage.setItem("event_seo_url_friendly", seo_url);
    localStorage.setItem("event_identifyunic", "related");
    localStorage.setItem("event_genres", detailsData.display_genres);

    if (identifyunic === "movies") {
      let urlmore = "/" + link + "/related-" + link + "/all";
      navigate(urlmore);
    }
  };

  useEffect(() => {
    if (isRequiredRelated === 1) {
      setSelectedTab("relatedinfo");
    } else {
      setSelectedTab("moreinfo");
    }
  }, [isRequiredRelated]); // Specify dependencies here

  const seeAllItems = (carouselItems, title, catalog) => {
    localStorage.removeItem("event_title");
    localStorage.removeItem("event_genres");
    localStorage.removeItem("event_identifyunic");
    localStorage.removeItem("event_seo_url_friendly");
    localStorage.removeItem("event_seo_url");
    localStorage.removeItem("relatedmovietitle");
  
    localStorage.removeItem("event_first");
    localStorage.removeItem("event_secund");
    localStorage.removeItem("event_three");
    localStorage.removeItem("event_four");
    localStorage.removeItem("event_five");
    localStorage.removeItem("SublistTitle");
    localStorage.setItem("event_seo_url_friendly", subcatUrl);
    localStorage.setItem("event_seo_url", seo_url);
    localStorage.setItem("event_identifyunic", catalog);
    localStorage.setItem("event_title", title);

    const newPath = `/${catalog}/${title.split(" ").join("-")}/all`;
    navigate(newPath);
  };

  const [imageLoadedArray, setImageLoadedArray] = useState(
    new Array(0).fill(false)
  );
  const handleImageLoad = (index) => {
    setImageLoadedArray((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const [imageLoadedArrayMedialist, setImageLoadedArrayMedialist] = useState(
    new Array(0).fill(false)
  );

  const handleImageLoadMedialist = (index) => {
    setImageLoadedArrayMedialist((prevState) => {
      const newState = [...prevState];
      newState[index] = true; // Set the value at the specified index to true
      return newState; // Return the updated state
    });
  };

  const renderCatalogList = () => {
    if (catalogListData?.data?.length > 0) {
      return (
        <div className="main-padding-110">
          <Container fluid className="pading-left-right-class">
              <Row>
                <Col>
                  <h4 style={{ textTransform: "capitalize" }}>
                    {catalogListData?.title}
                  </h4>
                </Col>
                <Col style={{ textAlign: "end", padding: "0px" }}></Col>
              </Row>
            <Row>
              {catalogListData?.data?.map((innerlist, innerIndex) => (
                <Col key={innerIndex} lg={2} md={3} sm={4} xs={6}>
                  <Link to={`${innerlist?.friendly_id}`} key={`${innerIndex}`}>
                    <div className="carousel-car">
                      <div className="extra lock-icon-class cms-container">
                        <div onClick={() => getContentInfo(innerlist, innerIndex)} className="thumbnailMovieCard relateddet">
                          <img
                            src={
                              imageLoadedArray[innerIndex]
                                ? innerlist?.thumbnails?.medium_16_9?.url
                                : verticaldefultimage
                            }
                            onLoad={() => handleImageLoad(innerIndex)}
                            loading="lazy"
                            alt=""
                          />

                          {!loading &&
                            innerlist?.media_type &&
                            acceptedMediaTypes.includes(
                              innerlist.media_type
                            ) && (
                              <div className="iconcame">
                                <FaPlay />
                              </div>
                            )}
                        </div>
                        <p style={{ fontSize: "15px" }}>
                          {innerlist?.title && innerlist?.title?.length > 30
                            ? innerlist.title.slice(0, 20) + "..."
                            : innerlist?.title}
                        </p>
                        <div className="FoodType">
                          <img
                            src={
                              innerlist?.sub_genres?.includes("veg")
                                ? vegicon
                                : nonvegicon
                            }
                            loading="lazy"
                            alt=""
                          />
                        </div>

                        <div></div>
                        {!loading && !innerlist?.access_control?.is_free && (
                          <div className="lock-postion-class-related"></div>
                        )}
                      </div>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      );
    }
  };

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <GoChevronLeft />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <GoChevronRight />
      </div>
    );
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);


  // useEffect(() => {
  //   // if(seasondropdownData?.length < 0){
  //   //   setShowThumbnail(false);
  //   // }else{
  //   //   setShowThumbnail(true);
  //   // }
  //   if(getPreviewWithoutLogin?.stream_info?.adaptive_url !== ''){
  //     setShowThumbnail(false);
  //   }

  //   // console.log("seasondropdownData", seasondropdownData)
  // }, [seasondropdownData, getPreviewWithoutLogin])

  // setShowThumbnail(false);

const episodelayoutType = episodetypedetails?.web_layout_type || episodetypedetails?.layout_type
const promolayoutType = promotypedetails?.web_layout_type || promotypedetails?.layout_type
const miniclipslayoutType = miniclipsdetails?.web_layout_type || miniclipsdetails?.layout_type
const behindsceneslayoutType = behindthescenesdetails?.web_layout_type || behindthescenesdetails?.layout_type

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 640);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const commonSettings = (data) => ({
    dots: false,
    infinite: false,
    speed: 500,
    lazyload: "anticipated",
    autoplay: false,
    centermode: false,
    delay: 3000,
    slidestoshow: 6.5,
    slidestoscroll: 3,
    initialSlide: 0,
    nextarrow: <SampleNextArrow />,
    prevarrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidestoshow: 3.5,
          slidestoscroll: 3,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidestoshow: getSlidesToShow(data),
          slidestoscroll: 2,
          initialSlide: 2,
          dots: false,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidestoshow: getSlidesToShow(data),
          slidestoscroll: 1,
          initialSlide: 2,
          dots: false,
          infinite: true,
        },
      },
    ],
  });

  function getSlidesToShow(data) {
    return data && data.total_items_count > 2 ? 2 : 1;
  }

  function generateSlider(data, commonSettings) {
    return (
      <Swiper
        {...commonSettings(data)}
        className="slider-one"
        navigation={!isMobile ? true : false}  modules={[Pagination, Navigation]}
        slidesPerView={2}
        spaceBetween={0}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => SampleNextArrow}
        breakpoints={{
          "640": {
            slidesPerView: 3,
            spaceBetween: 11
          },
          "768": {
            slidesPerView: 4,
            spaceBetween: 11
          },
          "1024": {
            slidesPerView: 6.5,
            spaceBetween: 0
          }
        }}
      >
        {data &&
          (data?.items || data?.catalog_list_items || data.Item) &&
          (data?.items || data?.catalog_list_items || data.Item).map((item, index) => {
            const maxCharacters = 24;
            let displayTitle =
              item?.title?.length > maxCharacters
                ? item?.title?.substring(0, maxCharacters) + "..."
                : item?.title;
            return (
              <SwiperSlide key={index}>
              <div
                key={index}
                onClick={(event) => {
                  handleGotoPage(item.seo_url, item, index, data);
                }}
              >
                <div className="carousel-car continuewatch">
                  <div className="extra lock-icon-class cms-container  cw-card">
                    <div className="thumbnailMovieCard relateddet">
                    <img
  src={
    !imageLoadedArray[index]
      ? verticaldefultimage
      : (data?.web_layout_type || data?.layout_type) === 'tv_shows' ||
        (data?.web_layout_type || data?.layout_type) === 'movies'
      ? (item?.thumbnails?.medium_3_4?.url || item?.thumbnails?.high_4_3?.url)
      : (item?.thumbnails?.medium_16_9?.url || item?.thumbnails?.high_4_3?.url)
  }
  width="100%"
  alt={`Slider ${index}`}
  onLoad={() => handleImageLoad(index)}
  loading={
    !imageLoadedArray[index]
      ? "eager"
      : "lazy"
  }
  className="img-border-radis"
/>

                      {!loading &&
                        item?.media_type &&
                        acceptedMediaTypes.includes(item.media_type) && (
                          <div className="iconcame">
                            <FaPlay />
                          </div>
                        )}
                    </div>
                    {item.show_meta_data === "yes" && item?.media_type && (
                      <p className="displayTitle">{displayTitle}</p>
                    )}

                    {!loading && !item?.access_control?.is_free && (
                      <div className="lock-postion-class-related"></div>
                    )}
                  </div>
                </div>
              </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    );
  }

  const { relatedDetails } = useSelector((state) => state.shows);

  return (
    <div className='main-page'>
      <div className="banner"></div>
      {(!filterEpisodeItemsData.length > 0 && filterApplied === false) ? (
        <>
          {catalogListData?.data?.length > 0 ? (
            renderCatalogList()
          ) : (
            <>
              {!loading ? (
                <>
                  {category === "live" ? (
                    <LiveVideoPlayer
                      catalogid={catalogiddata}
                      contentid={contentiddata}
                    />
                  ) : (
                    <VideoPlayer
                    isdropdownContentSelected={isdropdownContentSelected}
                    episodeSeason={episodeSeason}
                      catalogid={catalogiddata}
                      contentid={contentiddata}
                      setShowThumbnail={setShowThumbnail}
                      showThumbnail={showThumbnail}
                    />
                  )}
                                            { subCat.length > 1 && (

                    <Container fluid className="left-padding-grid">
                      {/* <div
                        className="performance-menu-container"
                        ref={performanceMenuRef}
                        onClick={showPerformanceDropdown}
                      >
                        {subCat[0]?.title}
                        <SlArrowDown
                          style={{
                            color: "#fff",
                            marginLeft: "5px",
                            fontSize: "12px",
                            fontWeight: "900",
                          }}
                          onClick={showPerformanceDropdown}
                        />
                        {showPerformance && (
                          <div className="performance-menu">
                            <ul style={{ height: subCat.length <= 8 ? "auto" : "200px" }}>
                              {subCat.map((item) => (
                                <li>
                                  <Link to={item.seo_url}>{item.title}</Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div> */}

<div className="showsFilter" ref={performanceMenuRef}>
      <Row className="align-items-center">
      <Col lg="auto" md="auto" sm="auto" xs="auto" className="d-flex align-items-center">
      {subCat.length > 0 && <p className="mb-0">Seasons:</p>}
    </Col>
        <Col lg="auto" md="auto" sm="auto" xs="auto">
          {subCat.length > 0 && (
            <>
            <select
              className=""
              value={selectedValue} 
              onChange={(event) => {
                setIsdropdownContentSelected(true)
                const selectedItem = subCat.find((item) => item.title === event.target.value);
                if (selectedItem) {
                  setSelectedValue(selectedItem.title); 
                  navigate(selectedItem.seo_url); 
                }
              }}
            >
              {subCat.map((item, index) => (
                <option key={index} value={item.title}>
                 {item.title}
                </option>
              ))}
            </select>
            </>
          )}
        </Col>
      </Row>
    </div>
                    </Container>
                  )}
                  {(!filterEpisodeItemsData?.length > 0 && filterApplied === false) ? (
                    <Tabs
                      activeKey={selectedTab}
                      onSelect={(tab) => setSelectedTab(tab)}
                      id="uncontrolled-tab-example"
                      className="mb-3 container-fluid pading-left-right-class"
                    >
                      {isRequiredRelated === 1 && (
                        <Tab eventKey="relatedinfo" title="Related">
                          <>
                         

                            {/* <EpisodeSubCategories
                            subcategoryrelatedList={subcategoryrelatedList}
                          /> */}



                            <div className="first-block">
                              <div className="first-slider">
                                <Container
                                  fluid
                                  className="pading-left-right-class"
                                >
                                  <Row>
                                    <Col xs="auto">
                                      {episodetypedetails &&
                                        episodetypedetails.items &&
                                        episodetypedetails.total_items_count >
                                          0 && (
                                          <h5>
                                            Episodes &nbsp;
                                            <span className="text-danger">
                                              {
                                                episodetypedetails.total_items_count
                                              }
                                            </span>
                                          </h5>
                                        )}
                                    </Col>
                                    {episodetypedetails &&
                                      episodetypedetails.items &&
                                      episodetypedetails.total_items_count >=
                                        10 && (
                                        <Col
                                          style={{
                                            textAlign: "end",
                                            padding: "0px",
                                          }}
                                        >
                                          <span
                                            onClick={() =>
                                              seeAllItems(
                                                episodetypedetails,
                                                "episode",
                                                category
                                              )
                                            }
                                            className="more-heading"
                                          >
                                            More
                                            <span>
                                              <FaAngleDoubleRight />
                                            </span>
                                          </span>
                                        </Col>
                                      )}
                                  </Row>
                                </Container>
                                <Container fluid className="left-padding-grid">
                                <div className={`smooth-slider ${episodelayoutType ? episodelayoutType : 'related_shows'}`}>
                                    {generateSlider(
                                      episodetypedetails,
                                      commonSettings
                                    )}
                                  </div>
                                </Container>
                              </div>
                            </div>

                            <div className="first-block">
                              <div className="first-slider">
                                <Container
                                  fluid
                                  className="pading-left-right-class"
                                >
                                  <Row>
                                    <Col xs="auto">
                                      {promotypedetails &&
                                        promotypedetails.items &&
                                        promotypedetails.total_items_count >
                                          0 && (
                                          <h5>
                                            Promos &nbsp;
                                            <span className="text-danger">
                                              {
                                                promotypedetails.total_items_count
                                              }
                                            </span>
                                          </h5>
                                        )}
                                    </Col>
                                  </Row>
                                </Container>
                                <Container fluid className="left-padding-grid">
                                <div className={`smooth-slider ${promolayoutType ? promolayoutType : 'related_shows'}`}>
                                    {generateSlider(
                                      promotypedetails,
                                      commonSettings
                                    )}
                                  </div>
                                </Container>
                              </div>
                            </div>

                            <div className="first-block">
                              <div className="first-slider">
                                <Container
                                  fluid
                                  className="pading-left-right-class"
                                >
                                  <Row>
                                    <Col xs="auto">
                                      {miniclipsdetails &&
                                        miniclipsdetails.items &&
                                        miniclipsdetails.total_items_count >
                                          0 && (
                                          <h5>
                                            Mini Clips &nbsp;
                                            <span className="text-danger">
                                              {
                                                miniclipsdetails.total_items_count
                                              }
                                            </span>
                                          </h5>
                                        )}
                                    </Col>
                                    {miniclipsdetails &&
                                      miniclipsdetails.items &&
                                      miniclipsdetails.total_items_count >=
                                        10 && (
                                        <Col
                                          style={{
                                            textAlign: "end",
                                            padding: "0px",
                                          }}
                                        >
                                          <span
                                            onClick={() =>
                                              seeAllItems(
                                                miniclipsdetails,
                                                "mini_clips",
                                                category
                                              )
                                            }
                                            className="more-heading"
                                          >
                                            More
                                            <span>
                                              <FaAngleDoubleRight />
                                            </span>
                                          </span>
                                        </Col>
                                      )}
                                  </Row>
                                </Container>
                                <Container fluid className="left-padding-grid">
                                  <div className={`smooth-slider ${miniclipslayoutType ? miniclipslayoutType : 'related_shows'}`}>
                                    {generateSlider(
                                      miniclipsdetails,
                                      commonSettings
                                    )}
                                  </div>
                                </Container>
                              </div>
                            </div>

                            <div className="first-block">
                              <div className="first-slider">
                                <Container
                                  fluid
                                  className="pading-left-right-class"
                                >
                                  <Row>
                                    <Col xs="auto">
                                      {behindthescenesdetails &&
                                        behindthescenesdetails.items &&
                                        behindthescenesdetails.total_items_count >
                                          0 && (
                                          <h5>
                                            Behind Scenes &nbsp;
                                            <span className="text-danger">
                                              {
                                                behindthescenesdetails.total_items_count
                                              }
                                            </span>
                                          </h5>
                                        )}
                                    </Col>
                                  </Row>
                                </Container>
                                <Container fluid className="left-padding-grid">
                                  <div className={`smooth-slider ${behindsceneslayoutType ? behindsceneslayoutType : 'related_shows'}`}>
                                    {generateSlider(
                                      behindthescenesdetails,
                                      commonSettings
                                    )}
                                  </div>
                                </Container>
                              </div>
                            </div>

                            {movieVideoListsData?.catalog_list_items?.map(
                              (relitem, relind) => {
                                return (
                                  <>
                                    {relitem?.total_items_count > 0 && (
                                      <div key={relind} className="first-block">
                                        <div className="first-slider">
                                          <Container
                                            fluid
                                            className="pading-left-right-class"
                                          >
                                            <Row style={{ justifyContent: "space-between" }}>
                                              <Col xs="auto">
                                                <h5 className="PopularMiniClips">
                                                  {relitem.display_title} &nbsp;
                                                  <span className="text-danger">
                                                    {relitem.total_items_count}
                                                  </span>
                                                </h5>
                                              </Col>
                                              {relitem?.total_items_count >=
                                              10 ? (
                                                <Col
                                                  xs="auto"
                                                  style={{
                                                    textAlign: "end",
                                                    padding: "0px",
                                                  }}
                                                >
                                                  <span
                                                    className="more-heading"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleMore(
                                                        relitem.seo_url,
                                                        relitem
                                                          .catalog_list_items[0],
                                                        "all"
                                                      )
                                                    }
                                                  >
                                                    More
                                                    <span>
                                                      <FaAngleDoubleRight />
                                                    </span>
                                                  </span>
                                                </Col>
                                              ) : (
                                                ""
                                              )}
                                            </Row>
                                          </Container>
                                          <Container
                                            fluid
                                            className="left-padding-grid"
                                          >
                                            <div className={`smooth-slider related_shows`}>
                                              {generateSlider(
                                                relitem,
                                                commonSettings
                                              )}
                                            </div>
                                          </Container>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              }
                            )}
                            {mediaListsDataLoading ? (
                              <>
                                
                                {medialistsdata.length > 0 ? (
                                  <>
                                    
                                    {medialistsdata?.map((relitem, relind) => {
                                      return (
                                        <>
                                          {relitem.total_items_count > 0 && (
                                            <div key={relind} className="first-block">
                                              <div className="first-slider">
                                                <Container
                                                  fluid
                                                  className="pading-left-right-class"
                                                >
                                                  <Row>
                                                    <Col xs="auto">
                                                      <h5 className="PopularMiniClips">
                                                        {relitem.display_title}
                                                        &nbsp;
                                                        <span className="text-danger">
                                                          {
                                                            relitem.total_items_count
                                                          }
                                                        </span>
                                                      </h5>
                                                    </Col>
                                                    {relitem?.total_items_count >=
                                                    10 ? (
                                                      <Col
                                                        style={{
                                                          textAlign: "end",
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <Link
                                                          to={`/more${relitem.seo_url}`}
                                                          className="more-heading"
                                                        >
                                                          More
                                                          <span>
                                                            <FaAngleDoubleRight />
                                                          </span>
                                                        </Link>
                                                      </Col>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Row>
                                                </Container>
                                                <Container
                                                  fluid
                                                  className="left-padding-grid"
                                                >
                                                  <div className={`smooth-slider ${(relitem?.web_layout_type || relitem?.layout_type) ? (relitem?.web_layout_type || relitem?.layout_type) : 'related_shows'}`}>
                                                  {generateSlider(
                                      relitem,
                                      commonSettings
                                    )}
                                                  </div>
                                                </Container>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <div className="text-center mt-4">
                                    <Spinner
                                      animation="border"
                                      variant="danger"
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <></>
                            )}

<div className="first-block">
                            <div className="first-slider">
                              <Container
                                fluid
                                className="pading-left-right-class"
                              >
                                <Row>
                                  <Col xs="auto">
                                    {relatedDetails?.display_title &&
                                      relatedDetails?.total_items_count > 0 && (
                                        <h5 className="displaytit">
                                          {relatedDetails?.display_title} &nbsp;
                                          <span className="text-danger">
                                          {relatedDetails?.total_items_count}
                                          </span>
                                        </h5>
                                      )}
                                  </Col>
                                  {relatedDetails?.total_items_count >= 10 ? (
                                    <Col
                                      style={{
                                        textAlign: "end",
                                        padding: "0px",
                                      }}
                                    >
                                      <span
                                       className="more-heading"
                                       style={{ cursor: 'pointer'}}
                                        onClick={() =>
                                          handleRelatedMore(
                                            category,
                                            relatedDetails,
                                            "movies",
                                            relatedDetails?.display_title
                                          )
                                        }
                                      >
                                        More
                                        <span>
                                          <FaAngleDoubleRight />
                                        </span>
                                      </span>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                              </Container>

                              <Container fluid className="left-padding-grid">
                                <div className={`smooth-slider ${(relatedDetails?.web_layout_type || relatedDetails?.layout_type) ? (relatedDetails?.web_layout_type || relatedDetails?.layout_type) : 'related_shows'}`}>
                                {generateSlider(
                                      relatedDetails,
                                      commonSettings
                                    )}
                                </div>
                              </Container>
                            </div>
                            {/* <GPTAdTwo /> */}
                            <>
                            {/* <div className='text-center d-lg-block d-md-block d-none'>
      <center>
        <Gpt 
          adUnit="/23111733658/ca-pub-6245873721150554-tag/Test1D" 
          name="div-gpt-ad-1728045293321-0" 
          size={[[960, 90], [728, 90]]}
          sizeMapping={[
            { viewport: [1024, 0], sizes: [[960, 90]] }, // Serve 960x90 for screens 1024px and above
            { viewport: [768, 0], sizes: [[728, 90]] },  // Serve 728x90 for screens between 768px and 1024px (you can choose to include this if needed)
            { viewport: [0, 0], sizes: [] },             // No ad for screens below 768px (mobile view)
          ]}
          onSlotRenderEnded={(event) => {
            console.log("Ad rendered: ", event);
            adSlotRef.current = event.slot; // Capture the slot reference after rendering
          }}
        />
      </center>
    </div> */}
                            </>
                          </div>
                          <GPTAdThree /> 
                          </>
                        </Tab>
                      )}
                    
                      <Tab
                        className="container-fluid pading-left-right-class"
                        eventKey="moreinfo"
                        title="More Info"
                      >
                        <>
                          <div className="video-title">
        <h3>{detailsData.title}  {detailsData?.release_date_string ? "|" : ""} <span className="release-date"> {detailsData?.release_date_string ? formatReleaseDate(detailsData?.release_date_string) : ""}</span></h3>
      </div>
                        </>
                        <br />
                        {detailsData.duration_string && (
                          <div className="">
                            Duration: {detailsData.duration_string}
                          </div>
                        )}
                        <br />
                        <div className="">
                          {detailsData?.description
                            ?.split("\n")
                            .map((paragraph, index) => (
                              <p key={index}>{paragraph}</p>
                            ))}
                        </div>

                        {detailsData.cbfc_rating && (
                          <div className="">
                            Rating: <span>{detailsData.cbfc_rating}</span>
                          </div>
                        )}

                      {detailsData.display_genres && (
  <div className="">
    <span>Genres: </span> {detailsData.display_genres.join(', ')}
  </div>
)}


                      </Tab>
                    </Tabs> 
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </>
      ) : null}

      {((filterEpisodeItemsData && filterEpisodeItemsData?.length > 0) || filterApplied === true) &&
      <>
      <div className="main-padding-110">
      <FilterEpisodesResult
            renderFiltereditemTitle={renderFiltereditemTitle}
            imageLoadedArrayMedialist={imageLoadedArrayMedialist}
            verticaldefultimage={verticaldefultimage}
            handleImageLoadMedialist={handleImageLoadMedialist}
            filterdataLoading={filterdataLoading}
          />
      </div>
      </>
      }
      {!filterEpisodeItemsData?.length && filterApplied === true &&
      <div className="main background-layer"></div>
      }

      {!loading && relatedDetails && detailsData && <Footer />}
      {loading && <Loader />}
    </div>
  );
};

export default VideoDetails;
