import React, { useState, useEffect } from "react";
import crypto from "crypto-js";
// import "./video.css";
import { useDispatch, useSelector } from "react-redux";
import "video-react/dist/video-react.css";
import "shaka-player/dist/controls.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import { Helmet } from 'react-helmet';

import {
  GET_ALL_DETAILS,
  access_token,
  auth_token,
  getSecretKey,
} from "../../services/config";
import axios from "axios";

import CustomVideoPlayer from "../../Componets/CustomVideoPlayer";
import { SkeletonVideoThumbnail } from "../skeletonLoad/skeletonthumbnail";
import { setModalOpen } from "../../Redux/Slice/loginModalSlice";
import WatchListFeature from "../videodetails/watchlistfeature";
import Likefeature from "../videodetails/likefeature";
import ShareFeature from "../videodetails/share";
import FollowFeature from "../videodetails/followfeature";
import FilterByDate from "../videodetails/filterEpisodes";
import StarRating from "../videodetails/StarRating";
import { setShowLogoutAllModal } from "../../Redux/Slice/logoutAllModalSlice";
import { setGetallDetails } from "../../Redux/Slice/getalldetailsSlice";
import GPTAd from "../videodetails/gptadd";
import { setSubscribeSource } from "../../Redux/Slice/subscriptionsource";
import { setAccountData } from "../../Redux/Slice/accountsDataSlice";
import { setAuth } from "../../Redux/Slice/authSlice";
import GPTAdTwo from "../videodetails/gptaddtwo";
import AdSection from "../videodetails/gptadload";
import GPTAdSide from "../videodetails/gptaddside";
import GPTAdSideTwo from "../videodetails/gptaddsidet";

const VideoPlayer = ({episodeSeason, catalogid, contentid, setShowThumbnail, showThumbnail, isdropdownContentSelected }) => {
  const { layoutname } = useSelector((state) => state.shows);
  const gettray = localStorage.getItem("traynamedata")
  const gettrayname = localStorage.getItem("selectedItem")
  // console.log("gettraynames", gettrayname)
  // console.log("gettraysss", gettray)
  const tv_shows_banner_image = "/images/tv_shows_banner.png";
  const mainBanner = "/images/mainbannerdefault.jpg";
  const addBanner = "/images/add-01.png";

  // console.log("showThumbnail", showThumbnail)
  const { sessionId } = useSelector((state) => state.auth);
  let sessionID = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id") : sessionId;
  const { episodedatacontent } = useSelector((state) => state.episodes);
  const isUserLoggedIn = localStorage.getItem("login_status");
  const showSeoUrl = episodedatacontent?.last_episode?.seo_url;
  const { isLoggedIn } = useSelector((state) => state.auth);
  const regionsData = useSelector((state) => state.region.regionsData);
  const dispatch = useDispatch();
  const { category, seo_url } = useParams();

  const [detailssubData, setvideodetailsitems] = useState({});
  const [getPreviewWithoutLogin, setGetPreviewWithoutLogin] = useState({});
  const [showPreviewVideo, setShowPreviewVideo] = useState(true);

  const [redirectSeourl, setredirectSeourl] = useState("");
  const [layer, setLayer] = useState(true);
  const { videodetailsitems } = useSelector((state) => state.video_details);
  const isLoginRequiredMand = false
 const navigate = useNavigate()
  useEffect(() => {
    setredirectSeourl(videodetailsitems?.last_episode?.seo_url);
  }, []);

  useEffect(() => {
    if (isUserLoggedIn === "true") {
      setShowPreviewVideo(true);
    }
  }, [isUserLoggedIn]);

  const handleShowLayer = () => {
    setLayer(false);
  };

  const getPreviewVideosWithoutLogin = async () => {

    const timeStamp = JSON.stringify(Math.floor(new Date().getTime() / 1000.0));
    if (sessionID == null) {
      sessionID = "";
    }
    const md5val = catalogid + contentid + sessionID + timeStamp + getSecretKey;
    const md5Hash = crypto.MD5(md5val).toString();

    if (sessionID == null) sessionID = "";
    try {
      const response = await axios.post(GET_ALL_DETAILS, {
        auth_token,
        access_token,
        catalog_id: catalogid,
        category: "",
        content_id: contentid,
        id: sessionID,
        region: regionsData?.country_code2,
        md5: md5Hash,
        ts: timeStamp,
      }, 
      // {
      //   headers: {
      //    "Content-Type": "application/json",
      //    "User-Agent": navigator.userAgent,
      //           "Accept-Encoding": "gzip",
      //           "Content-Length":244,
      //   },
      // }
    );

      if (response.status === 200) {
        // console.log("sjjsjsjj", response.data.data.stream_info.adaptive_url)
        if(isUserLoggedIn === "true" && response.data.data.login_status === false){
          localStorage.removeItem("session_id");
          localStorage.clear()
          localStorage.setItem("login_status", false);
          localStorage.setItem("showSubscripeButton", true);
          dispatch(setAccountData({ accountData: {} }));
          dispatch(setAuth({ isLoggedIn: false, usersessionID: "" }));
        }

        if(isLoginRequiredMand === true && response?.data?.data?.login_status === false){
          dispatch(setModalOpen())
        }else{
          setShowPreviewVideo(false);
        setLayer(false);
        setGetPreviewWithoutLogin(response.data.data);
        dispatch(setGetallDetails({getallDetails: response.data.data}))
        }
      }
    } catch (error) {
      setLayer(true);
      setShowPreviewVideo(true);
      console.error("Error fetching data:", error);
    }
  };
  const {seasondropdownData} = useSelector(state => state.dropdown_data)

  // console.log("seasondropdownData", seasondropdownData?.length)
  // console.log("getPreviewWithoutLogin", getPreviewWithoutLogin?.stream_info?.adaptive_url)

  // useEffect(() => {

  //   if(getPreviewWithoutLogin?.stream_info?.adaptive_url !== ''){
  //     setShowThumbnail(false);
  //   }
  // }, [getPreviewWithoutLogin])

  
  useEffect(() => {
    // if(seasondropdownData?.length < 0){
    //   setShowThumbnail(false);
    // }else{
    //   setShowThumbnail(true);
    // }
    if(getPreviewWithoutLogin?.stream_info?.adaptive_url !== ''){
      setShowThumbnail(false);
    }

    // console.log("seasondropdownData", seasondropdownData)
  }, [seasondropdownData, getPreviewWithoutLogin])


  if (category === "live") {
    videodetailsitems = detailssubData;
  }
  const [imageLoadedArray, setImageLoadedArray] = useState(
    new Array(0).fill(false)
  );
  const handleImageLoad = (index) => {
    setImageLoadedArray((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);


const { loginDetails } = useSelector((state) => state?.signin_data || {});

// useEffect(() => {
// if(loginDetails?.email_id){
//  window.location.reload()
// }
// }, [loginDetails])

// useEffect(() => {
  // if(videodetailsitems?.access_control?.login_required === true){
  //   dispatch(setModalOpen())
  // }
//   }, [videodetailsitems?.access_control?.login_required])

// console.log("loginDetails", loginDetails)

  const renderVideoPlayer = () => {
    let myseourl = "";
    if (category === "shows") {
      myseourl = window.location.origin + showSeoUrl;
    } else if (category === "serials") {
      myseourl = window.location.origin + redirectSeourl;
    } else if (category === "events") {
      myseourl = window.location.origin + showSeoUrl;
    }

    if (showPreviewVideo) {
      const url = videodetailsitems?.share_url || 'ETVWin';
const extractedUrl = url.replace(/^https?:\/\//, ''); 
      return (
        <>
         <Helmet>
    <meta charSet="utf-8" />

  <title>{videodetailsitems?.title || 'ETVWin'}</title>
    <meta charset="utf-8" />
     <meta http-equiv="X-UA-Compatible" content="IE=edge" />
     <meta name="csrf_token" content="" />
     <meta property="type" content="website" />
     <meta property="url" content="" />
     <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
     <meta name="msapplication-TileColor" content="#ffffff" />
     <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
     <meta name="theme-color" content="#ffffff" />
     <meta name="_token" content="" />
     <meta name="robots" content="noodp" />
     <meta property="title" content={videodetailsitems?.title} />
     <meta property="quote" content="" />
     <meta name="description" content={videodetailsitems?.description || 'Share Check out this video on ETVWin'} />
     <meta property="image" content={videodetailsitems?.thumbnails?.medium_4_3?.url || 'default-image-url'} />
     <meta property="og:locale" content="en_US" />
     <meta property="og:type" content="website" />
     <meta property="og:title" content={videodetailsitems?.title} />
     <meta property="og:quote" content="" />
     <meta property="og:hashtag" content="" />
     <meta property="og:image" content={videodetailsitems?.thumbnails?.medium_4_3?.url || 'default-image-url'} />
     <meta content="image/*" property="og:image:type" />
     <meta property="og:url" content={videodetailsitems?.web_share_url} />
     <meta property="og:site_name" content="CampersTribe" />
     <meta property="og:description" content={videodetailsitems?.description || 'Share Check out this video on ETVWin'}  />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@etvwin" />
        <meta name="twitter:creator" content="@etvwin" />
        <meta name="twitter:title" content={videodetailsitems?.title || 'ETVWin'} />
        <meta name="twitter:description" content={videodetailsitems?.description || 'Check out this video on ETVWin'} />
        <meta name="twitter:image" content={videodetailsitems?.thumbnails?.medium_4_3?.url || 'default-image-url'} />
        <meta name="twitter:url" content={videodetailsitems?.web_share_url} />
      </Helmet>
          <div className="image-containers">
            <div className="imgabove-layer">
           

              {!(videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.medium_16_9?.url) ?  <SkeletonVideoThumbnail height={430} /> :  <img
                src={
                  imageLoadedArray[0]
                    ? isMobile ? videodetailsitems?.thumbnails?.high_16_9?.url : videodetailsitems?.thumbnails?.web_banner?.url
                    : mainBanner
                }
                onLoad={() => handleImageLoad(0)}
                alt=""
              />}


              

              <div className="showvideodetailsbelow">
                <div className="horizontal-divider-container">
                  <div className="horizontal-divider"></div>
                </div>
                <Container fluid className="pading-left-right-class">
                  <Row>
                    <Col>
                      <div id="contentvideo" className="flex-container videop">
                        <div className="combination">
                          <div className="imgabove-layer">
                            {videodetailsitems?.thumbnails?.web_banner?.url && (
                              <div className="circle-icon">
                                {category === "shows" ||
                                category === "serials" ||
                                category === "events" ? (
                                  <Link to={redirectSeourl}>
                                    <FaPlay />
                                  </Link>
                                ) : (
                                  <Link to={redirectSeourl} onClick={getPreviewVideosWithoutLogin}>
                                    <FaPlay />
                                  </Link>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="text">Watch Now</div>
                        </div>

                        {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates}  /> */}
                        <div className="divider"></div>
                        {videodetailsitems?.media_type === "episode" && (
                          <div className="combination mb-3">
                            {videodetailsitems.average_user_rating && (
                              <div className="section-header">
                                <StarRating
                                  rating={parseFloat(
                                    videodetailsitems.average_user_rating
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        )}

                        <ShareFeature />

                        {videodetailsitems?.media_type === "episode" && (
                          <FollowFeature />
                        )}
                        {episodeSeason && <FilterByDate />}
{/* <FilterByDate /> */}

                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      (!getPreviewWithoutLogin?.login_status ||
        getPreviewWithoutLogin?.login_status) &&
      !getPreviewWithoutLogin?.is_subscribed &&
      (getPreviewWithoutLogin?.stream_info?.preview_available === true ||
        getPreviewWithoutLogin?.stream_info?.adaptive_url !== "")
    ) {
      return (
        <>
        <div className="content-container">
          <Row>
            <Col lg={8}>
              <CustomVideoPlayer videoSource={getPreviewWithoutLogin} />
              <div id="contentvideo" className="flex-container videop watchlistblock">
                <WatchListFeature />
                <Likefeature />
                <ShareFeature />
              </div>
            </Col>
            <Col lg={4}>
            {/* <AdSection /> */}
            <GPTAdSide />
            <GPTAdSideTwo />
            </Col>
          </Row>
        </div>
        </>
      );
    } else if (
      !getPreviewWithoutLogin?.login_status &&
      !getPreviewWithoutLogin?.is_subscribed &&
      !getPreviewWithoutLogin?.stream_info?.preview_available
    ) {
      const handleSubscribe =  () => {
        dispatch(setSubscribeSource({
          subscribeSource: true,
        }))
  
         if(getPreviewWithoutLogin?.login_status === true){
        navigate("/profile?tab=subscription");
      }else{
        navigate('/subscription-plans');
      } 
        }

      // videodetailsitems?.access_control?.login_required
      return (
        <>
          {layer ? "" : <div className="layer-image"></div>}

          {layer ? (
            ""
          ) : (
            <div className="banner-login-conte">
              <h6 className="text-center">Subscribe to watch this content</h6>

              <Row className="justify-content-center">
                <Col
                  lg={6}
                  md={6}
                  sm="auto"
                  xs="auto"
                  style={{ textAlign: "center" }}
                >
                  {/* <Login name="Login" className="login-button" /> */}
                  <Link
                    to=""
                    className="login-button"
                    onClick={() => dispatch(setModalOpen())}
                  >
                    Login
                  </Link>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  sm="auto"
                  xs="auto"
                  style={{ textAlign: "center" }}
                >
                  <button onClick={handleSubscribe}  className="login-button">
                    Subscribe
                  </button>
                </Col>
              </Row>
            </div>
          )}

          <div className="image-containers">
            <div className="imgabove-layer">
            {!videodetailsitems?.thumbnails?.web_banner?.url ? <SkeletonVideoThumbnail height={430} /> : <img
                src={
                  !imageLoadedArray[0] ||
                  !videodetailsitems?.thumbnails?.web_banner?.url
                    ? mainBanner
                    : videodetailsitems?.thumbnails?.web_banner?.url
                }
                onLoad={() => handleImageLoad(0)}
                alt=""
              />}
             
            </div>
          </div>

          <div className="showvideodetailsbelow">
            <div className="horizontal-divider-container">
              <div className="horizontal-divider"></div>
            </div>
            <Container fluid className="pading-left-right-class">
              <Row>
                <Col>
                  <div id="contentvideo" className="flex-container videop">
                    {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates}  />
                      <div className="divider"></div> */}

                    <div className="combination">
                      <div className="imgabove-layer">
                        {videodetailsitems?.thumbnails?.web_banner?.url && (
                          <div className="circle-icon">
                            {videodetailsitems?.thumbnails?.web_banner?.url && (
                              <Link to="" onClick={handleShowLayer}>
                                <FaPlay />
                              </Link>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="text">Watch Now</div>
                    </div>

                    {/* <WatchListFeature  />
                      <Likefeature likedContentid={likedContentid}  /> */}
                    <ShareFeature />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    } else if (
      getPreviewWithoutLogin?.login_status &&
      !getPreviewWithoutLogin?.is_subscribed &&
      !getPreviewWithoutLogin?.stream_info?.preview_available
    ) {
      const handleSubscribe =  () => {
        dispatch(setSubscribeSource({
          subscribeSource: true,
        }))
  
         if(getPreviewWithoutLogin?.login_status === true){
        navigate("/profile?tab=subscription");
      }else{
        navigate('/subscription-plans');
      } 
        }
      return (
        <>
          {layer ? "" : <div className="layer-image"></div>}

          {layer ? (
            ""
          ) : (
            <div className="banner-login-conte">
              <h6 className="text-center">
                {getPreviewWithoutLogin?.stream_info?.upgrade_plan === true
                  ? "Please Upgrade your Account to Start Streaming"
                  : "Subscribe to watch this content"}
              </h6>

              <Row className="justify-content-center">
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: "center" }}
                >
                  {/* <Link to="/Subscription" className="login-button">
                    {getPreviewWithoutLogin?.stream_info?.upgrade_plan === true
                      ? "upgrade"
                      : "subscribe"}
                  </Link> */}
                  <button onClick={handleSubscribe}  className="login-button">
                  {getPreviewWithoutLogin?.stream_info?.upgrade_plan === true
                      ? "upgrade"
                      : "subscribe"}
                  </button>
                </Col>
              </Row>
            </div>
          )}

          <div className="image-containers">
            <div className="imgabove-layer">
         
              

              
            {!videodetailsitems?.thumbnails?.web_banner?.url ? <SkeletonVideoThumbnail height={430} /> : <img
                src={
                  imageLoadedArray[0]
                    ? videodetailsitems?.thumbnails?.web_banner?.url ||
                      videodetailsitems?.thumbnails?.medium_4_3?.url ||
                      mainBanner
                    : mainBanner
                }
                onLoad={() => handleImageLoad(0)}
                alt=""
              />}
              <div className="showvideodetailsbelow">
                <div className="horizontal-divider-container">
                  <div className="horizontal-divider"></div>
                </div>

                <Container fluid className="pading-left-right-class">
                  <Row>
                    <Col>
                      <div id="contentvideo" className="flex-container videop">
                        {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates}  />
                      <div className="divider"></div> */}

                        <div className="combination">
                          <div className="imgabove-layer">
                            {videodetailsitems?.thumbnails?.web_banner?.url && (
                              <div className="circle-icon">
                                {videodetailsitems?.thumbnails?.web_banner
                                  ?.url && (
                                  <Link to="" onClick={handleShowLayer}>
                                    <FaPlay />
                                  </Link>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="text">Watch Now</div>
                        </div>

                        {/* <WatchListFeature  />
                      <Likefeature likedContentid={likedContentid}  /> */}
                        <ShareFeature />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      getPreviewWithoutLogin?.login_status &&
      getPreviewWithoutLogin?.is_subscribed 
    ) {
      return (
        <>
          <Row>
            <Col lg={8}>
              {getPreviewWithoutLogin?.stream_info?.is_stream_limit_exceed ===
              true ? (
                <div className="StreamLimits" style={{
                  backgroundImage: `url(${videodetailsitems?.thumbnails?.high_16_9?.url})`
                }} >
                              <div className="info">
                                <h6 className="text-center">Maximum Simultaneous streams reached</h6>
                                <p>Please stop playing on other devices & click retry</p>
                                <p>or</p>
                                <p>logout of all / any devices to continue watching</p>
            
                                <Row>
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              style={{ textAlign: "center" }}
                            >
                               <Link onClick={() => {
  if (getPreviewWithoutLogin?.stream_info?.is_stream_limit_exceed === true) {
    getPreviewVideosWithoutLogin();
  } else {
    window.location.reload();
  }
}} className="login-button w-100">
  Retry
</Link>

                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              style={{ textAlign: "center" }}
                            >
                              <Link to='' className="login-button w-100" onClick={() =>  dispatch(setShowLogoutAllModal({ showLogoutAllModal: true }))} > Signout all devices </Link>
                            </Col>
                          </Row>
                              </div>
                            </div>
              ) : (
                <CustomVideoPlayer
                videoSource={getPreviewWithoutLogin}
              />
              )}

              <div id="contentvideo" className="flex-container videop playerpage">
                {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates}  />
                      <div className="divider"></div> */}
                <WatchListFeature />
                <Likefeature />
                <ShareFeature />
              </div>
            </Col>
            <Col lg={4}>
            {/* <GPTAd width = {300} height = {250} /> */}
            {/* <GPTAd /> */}
              {/* <Link>
                <img
                  src={imageLoadedArray[0] ? addBanner : mainBanner}
                  onLoad={() => handleImageLoad(0)}
                  className="w-100 add-banner"
                  alt=""
                />
              </Link> */}
            </Col>
          </Row>
        </>
      ); 
    }
  };

  return (
    <>
      <div className={` main-video ${getPreviewWithoutLogin?.stream_info?.is_stream_limit_exceed === true ? 'streamlimitexceed' : ""}`}>
        <div className="mainbanner">
          <div className="videomainid">{renderVideoPlayer()}</div>
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
