import React, { useEffect, useState } from 'react';

const GPTAdSideTwo = () => {
  const adId = 'div-gpt-ad-1731658475526-0'; // Unique ID for this ad slot
  const [adLoaded, setAdLoaded] = useState(false);
  const [adSlotEmpty, setAdSlotEmpty] = useState(true);

  useEffect(() => {
    const loadGPT = () => {
      if (window.googletag && window.googletag.cmd) {
        window.googletag.cmd.push(() => {
          try {
            // Check for existing ad slot
            const existingSlot = window.googletag.pubads().getSlots().find(slot => slot.getSlotElementId() === adId);
            if (existingSlot) {
              // console.log('Destroying existing slot for', adId);
              window.googletag.destroySlots([existingSlot]);
            }

            // Define a new ad slot
            const adSlot = window.googletag
              .defineSlot('/23111733658/ca-pub-6245873721150554-tag/Test1D', [300, 250], adId)
              .addService(window.googletag.pubads());

            // console.log('Ad slot defined:', adSlot);

            // Enable services and display the ad
            window.googletag.pubads().enableSingleRequest();
            window.googletag.enableServices();
            window.googletag.display(adId);

            // Listen for SlotRenderEndedEvent to check if the ad was loaded
            window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
              if (event.slot.getSlotElementId() === adId) {
                if (event.isEmpty) {
                  setAdSlotEmpty(true)
                  // console.log('Ad slot is empty:', adId);
                } else {
                  setAdSlotEmpty(false)
                  // console.log('Ad loaded successfully for:', adId);
                }
                setAdLoaded(!event.isEmpty); // Set adLoaded to true if ad is not empty
              }
            });

            // Set up a refresh interval
            const refreshInterval = setInterval(() => {
              // console.log('Refreshing ad slot:', adId);
              window.googletag.pubads().refresh([adSlot]);
            }, 20000);

            return () => clearInterval(refreshInterval);

          } catch (e) {
            console.error('Error setting up GPT:', e);
          }
        });
      } else {
        console.error('GPT is not loaded or googletag.cmd is not available.');
      }
    };

    let script;
    if (window.googletag && window.googletag.apiReady) {
      loadGPT();
    } else {
      script = document.createElement('script');
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.async = true;
      script.onload = loadGPT;
      document.head.appendChild(script);
    }

    return () => {
      if (script && document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="ad-container text-center d-lg-block d-md-none d-none">
      <center>
        <div
          id={adId}
          className="gpt-ad"
          style={{
            display: adSlotEmpty ? 'none' : 'block',
            width: '300px',
            opacity: adLoaded ? 1 : 0,
            height: '250px',
            backgroundColor: adSlotEmpty ? 'transparent' : '#fff',
          }}
        ></div>
      </center>
    </div>
  );
};

export default GPTAdSideTwo;
