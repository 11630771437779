import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../Componets/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Componets/Footer/Footer";
import { useParams } from "react-router";
import { BASE_URL, acceptedMediaTypes,access_token, auth_token } from "../../services/config";
import axios from "axios";
import Slider from "react-slick";
import { horizontalmodel, verticalmodel } from "../../layout/gridmodel";
import { useNavigate, Link } from "react-router-dom";
import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import Loader from "../../Componets/loader.js";
import {
  useComponentUtils,
} from "../../layout/utilscomponent";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  SkeletonLoad,
  SkeletonLoader,
  SkeletonLoadBox,
  SkeletonLoadBoxShows,
  SkeletonLoadSlideWinOriginals,
  SkeletonLoadSlider,SkeletonLoadBoxMOre,
} from "../skeletonLoad/skeletonthumbnail.jsx";
import { setMainTraynamedata, setTraynamedata } from "../../Redux/Slice/navlinkcallSlice.js";
import { setThroughSeach } from "../../Redux/Slice/throughSearchSlice.js";
const EpisodeMore = () => {
  const vegicon = "/images/veg.png";
  const nonvegicon = "/images/nonveg.png";
  const verticaldefultimage = "/images/horizontaldefault.jpg";
  const horizontaldefultimage = "/images/verticaldefault.jpg";
  const etv_exclusive_banner_image = "/images/etv-exclusive_banner.png";
  const tv_shows_banner_image = "/images/tv_shows_banner.png";
  const top10_image = "/images/top10default.jpg";
  const twoGridDefault = "/images/twogridimage.jpg";
  const singleGridDefault = "/images/singlegriddefault.jpg";
  const threeressplaceholder = "/images/3_4_placeholder.png";
  const dispatch = useDispatch()
  const [movieGenresList, setMovieGenresList] = useState([]);
  const regionsData = useSelector((state) => state.region.regionsData);

  const [movieGenresData, setMovieGenresData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
  });
  const [healthGenresData, setHealthGenresData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
  });
  const [moreData, setMoreData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
    layout_type: "",
    filter_title: "",
  });
  const { sessionId } = useSelector((state) => state.auth);

  let sessionID = localStorage.getItem("session_id")
  ? localStorage.getItem("session_id")
  : sessionId;
  const [page, setPage] = useState(0);
  const [fromItem, setFromItem] = useState(0);
  const [isMoreAdded, setIsMoreAdded] = useState(true);
  const [catalogListData, setCatalogListData] = useState({
    data: [],
    total_items_count: 0,
    title: "",
  });
  const [genresCatalogListData, setGenresCatalogListData] = useState([]);
  const [healthgenresCatalogListData, setHealthGenresCatalogListData] =
    useState([]);
  const [avilFilters, setAvilFilters] = useState([]);
  const {
    pageName,
    friendly_id,
    moreseo_url,
    category,
    main,
    channelID,
    pageName2,
  } = useParams();
  const seo_url = moreseo_url;
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedvalue, setselectedValue] = useState("");
  const [selectedkey, setselectedKey] = useState("");

  const { renderCategoryBlockWithLayoutType } = useComponentUtils(
    movieGenresList,
    true,
    {},
    {},
    false,
    {},
    {},
    {}
  );
  //alert(JSON.stringify(avilFilters));
  const [lastItemIndex, setLastItemIndex] = useState(0);
  const [loadingbusy, setLoadingbusy] = useState(false);
  // Define a state variable to hold the total number of items
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640); 
  const loginStatus = localStorage.getItem("login_status")
  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;
  const getuserid = localStorage.getItem("user_id")
  function convertToSnakeCase(encodedString) {
    // Decode the URL-encoded string
    const decodedString = decodeURIComponent(encodedString);
    
    // Convert the decoded string to lowercase and replace spaces with underscores
    const formattedString = decodedString.toLowerCase().replace(/ /g, "_");
    
    return formattedString;
  }
  // console.log("pageName2" + pageName2);
  useEffect(() => {

    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, [isMobile]);
  const getMoreDetailsUrl = () => {
        const event_seo_url = localStorage.getItem('event_seo_url');
        const event_seo_url_friendly = localStorage.getItem('event_seo_url_friendly');
        const event_identifyunic = localStorage.getItem('event_identifyunic');
        const event_genres = localStorage.getItem('event_genres');
        const event_title = localStorage.getItem('event_title');
        const event_particularvideo_url = localStorage.getItem('event_particularvideo_url');
        const event_first = localStorage.getItem('event_first');
        const event_secund = localStorage.getItem('event_secund');
        const event_three = localStorage.getItem('event_three');
        const event_four = localStorage.getItem('event_four');
        const relatedmovietitle = localStorage.getItem('relatedmovietitle');
        
        // alert(JSON.stringify(pageName2));
        // console.log("event_seo_url"+event_seo_url);
        // console.log("event_seo_url_friendly"+event_seo_url_friendly);
        // console.log("event_identifyunic"+event_identifyunic);
        // console.log("event_genres"+event_genres);
        // console.log("event_title"+event_title);
        // console.log("event_first"+event_first);
        // console.log("event_secund"+event_secund);
        // console.log("event_three"+event_three);
        // console.log("event_four"+event_four);
        if((event_identifyunic === null || event_seo_url === null || event_seo_url_friendly === null) && pageName2 !== "watch-history"){

        navigate("/home");

        }else{
        if(pageName2 === "watch-history"){
         //  console.log("if 1");
        return `${BASE_URL}users/${sessionID}/playlists/watchhistory/listitems.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&from=${fromItem}&page=${page}&page_size=124&start_count=0`;

        }else if(event_identifyunic === "related" && pageName2 !== "watch-history" && event_secund !== "etv-life"){
         

          if(pageName === "telugu-movies" && event_four!=null ){
          
            if(relatedmovietitle === "Related Movies"){
                return  `${BASE_URL}catalogs/${pageName}/items/${event_seo_url_friendly}/related.gzip??item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&genres=${convertToSnakeCase(event_genres)}&from=${fromItem}&page=${page}&page_size=24&start_count=0`

            }else{
              return  `${BASE_URL}catalogs/${pageName}/items/${event_seo_url_friendly}/videolists/${event_four}/related.gzip??item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&genres=${convertToSnakeCase(event_genres)}&from=${fromItem}&page=${page}&page_size=24&start_count=0`

            }
  
          }else{
           if((pageName === "shows" || pageName === "serials") &&  event_three!==null && event_four!==null  ){
           
            return `${BASE_URL}catalogs/${pageName}/items/${event_seo_url_friendly}/subcategories/${event_three}/episodes/${event_four}/related.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}${event_title ? `&episode_type=${event_title}` : ''}&from=${fromItem}&page=${page}&page_size=24&start_count=0`;

           }else{ 

            return  `${BASE_URL}catalogs/${pageName}/items/${event_seo_url_friendly}/related.gzip??item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&genres=${convertToSnakeCase(event_genres)}&from=${fromItem}&page=${page}&page_size=124&start_count=0`


           }

          }
        }else if(event_identifyunic === "related"  && pageName2 !== "watch-history" &&  event_secund === "etv-life"){
          // console.log("if 3");
          return  `${BASE_URL}catalogs/${event_secund}/items/${event_four}/related.gzip??item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&genres=${convertToSnakeCase(event_genres)}&from=${fromItem}&page=${page}&page_size=124&start_count=0`
        }else{
        // console.log("else else if");
        // console.log("if 3");

          if(pageName === "shows"){
            return  `${BASE_URL}catalogs/${pageName}/items/${event_seo_url}/subcategories/${event_seo_url_friendly}/episodes/${event_particularvideo_url}/related.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&episode_type=${event_title}&from=${fromItem}&page=${page}&page_size=24&start_count=0`
          }else if(pageName === "events" && (event_seo_url_friendly === "undefined" || event_seo_url_friendly === undefined)){
            return  `${BASE_URL}catalogs/${pageName}/items/${event_seo_url}/episodes.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&episode_type=${event_title}&from=${fromItem}&page=${page}&page_size=24&start_count=0`
          }else{
            return  `${BASE_URL}catalogs/${pageName}/items/${event_seo_url}/subcategories/${event_seo_url_friendly}/episodes.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&episode_type=${event_title}&from=${fromItem}&page=${page}&page_size=24&start_count=0`

          } 
        }


        }

   
  };

  const getmoredetailscontent = getMoreDetailsUrl();

  function getPageName() {
    const path = window.location.pathname;
    const parts = path.split('/'); // Split path by '/'
    return parts[1] ?? "home"; // Return the second part after localhost/
  }
  const param1Value = getPageName();
  const sourcetab = localStorage.getItem("selectedMenuItem");

  const itemSelected = (index,listItem, mainData) => {
    dispatch(setThroughSeach({ throughSeach: false }));
    // console.log("mainDatasnnsn", mainData)
    // console.log("listItemdddf", listItem)
    localStorage.setItem("traynamedata", listItem.display_title)
    dispatch(setTraynamedata({ traynamedata:  listItem}));
    dispatch(setMainTraynamedata({ maintraynamedata:  mainData}));
    if (window && window.sdk && window.sdk.trackEvent) {
      window.sdk.trackEvent("artwork_tapped", {
        source:sourcetab?.toLowerCase(),
        country: regionsData?.country_name,
        event_time: new Date().toISOString().slice(0, 19),
        video_name:listItem.title,
        tray_name: (mainData.display_title?.toLowerCase() ? mainData.display_title?.toLowerCase() : mainData.title?.toLowerCase()) || "NA",
        platform: "web",
        event_id: 21,
        u_id:
        loginStatus === "true"
        ? getuserid
        : guestuserid,
        content_type: (listItem?.media_type ? listItem?.media_type : listItem?.last_episode?.media_type) || 'NA',
        position_within_tray: index + 1,
        artwork_type: (listItem?.media_type ? listItem?.media_type : listItem?.last_episode?.media_type) || 'NA',
   });
  
    } else {
      console.error('Analytics SDK is not initialized yet.');
      // Handle initialization state or queue events for later
    }
  
   
  
  };
    

  // console.log("geteventsitem_token", localStorage.getItem('event_seo_url'));
  //friendly_id
  const fetchMoreDetailsData = async (url) => {
    try {
      setLoading(true);
    const response = await axios.get(url);
    if (response.status === 200) {
      setLoading(false);
      
      setLoadingbusy(true);
      if(response?.data?.data?.items.length === 0){
        navigate('/home')
      }
      const newItems = response?.data?.data?.catalog_list_items || response?.data?.data?.items || [];
      const totalItemsCount = response?.data?.data?.total_items_count || 0;
      const uniqueTitles = new Set(moreData.data.map((item) => item.title));
    //  console.log(uniqueTitles, "uniqueTitles"); // Logging unique titles for debugging
    //  console.log("New items before filtering:", newItems);

      // Filter out new items with titles that already exist in the current data
      const filteredNewItems = newItems.filter(
        (newItem) => !uniqueTitles.has(newItem.title)
      );
      const updatedResults = [...moreData?.data,...newItems ];
      // localStorage.setItem("layout", response?.data?.data?.web_layout_type || response?.data?.data?.layout_type)
      setMoreData((prevData) => ({
        ...prevData,
        data: updatedResults, // Append only filtered new data to existing data
        total_items_count: totalItemsCount,
        title: response?.data?.data?.title || response?.data?.data?.display_title,
        layout_type: (response?.data?.data?.web_layout_type || response?.data?.data?.layout_type),
        filter_title: response?.data?.data?.filter_title,
      }));
      const newPage = page + 1;
      setPage(newPage);
      setFromItem(updatedResults?.length);

      // if (moreData?.data?.length > 0) {
      //   setLoading(true);
      // }
      setAvilFilters(response?.data?.data?.available_filters);

      setCatalogListData((prevData) => ({
        ...prevData,
        data: newItems,

        total_items_count: totalItemsCount,
        title: response?.data?.data?.display_title,
      }));
      setTotalItemsCount(totalItemsCount);
      setLastItemIndex((prev) => prev + newItems?.length);
    }else{
      setLoadingbusy(false);
    }
  } catch (error) {
    setLoading(false);
    setLoadingbusy(false);
    if(error?.response?.data?.error?.message === "Show does not exist" || error?.response?.data?.error?.message === "Subcategory does not exist!!"){
     navigate('/home')
    }
    console.error("Error fetching details:", error);
    if (error.message === "Request failed with status code 422") {
    } else if (error?.response?.status === 422) {
      // console.log(error?.response?.data?.error?.message);
    }
  }
  };
  
  
const handleScroll = () => {
  // Check if more data is available and not currently loading
  // const scrollThreshold = isMobile ? 200 : 100; // Adjust the threshold for mobile
  const isMobile = window.innerWidth <= 768; 

  const threshold = isMobile ? 1000 : 100;

  if (
    moreData &&
    !loading && loadingbusy && (moreData?.data?.length < totalItemsCount) &&
    window.innerHeight + window.scrollY >=
      document.body.offsetHeight - threshold // Adjust threshold as needed
  ) {
    fetchMoreDetailsData(getMoreDetailsUrl());
    window.removeEventListener("scroll", handleScroll);

  }
};
useEffect(() => {
  window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  
}, [page,moreData, totalItemsCount]); 

useEffect(() => {
  if(loadingbusy === false){
   // alert("sfds");
    fetchMoreDetailsData(getMoreDetailsUrl());
  }else{
  
  }

}, []); // Add 



  
  const subListClicked = (selectedValue, selectedKey) => {
    setselectedValue(selectedValue);
    setselectedKey(selectedKey);
    // Find the corresponding avilFilter based on selectedValue
    const sublistItem = avilFilters.find((sub) => sub.title === selectedValue);

    if (sublistItem) {
      localStorage.setItem("SublistKey", sublistItem.key);
      localStorage.setItem("SublistTitle", sublistItem.title);

      const pageLocation = window.location.href.split("/")[3];
      const channelName = window.location.href.split("/")[4];

      if (pageLocation === "channels") {
        navigate(
          `/${pageLocation}/${channelName}/${pageName}/${sublistItem.value}`
        );
      } else {
        const path = `/home/${pageName}/${sublistItem.value}`;
        navigate(path);
      }

      // if (channelID!=undefined && pageName!="") {
      //   let returnurl = `${BASE_URL}catalog_lists/${pageName}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&channel_ids=${channelID}&from=0&page=0&page_size=24&start_count=0`;

      //  // https://stagingott.etvwin.com/catalog_lists/featured-shows.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW&channel_ids=etv-plus&from=0&page=0&page_size=24&start_count=0
      //   fetchMoreDetailsData(returnurl);

      // }
    }
  };
  const handleGotoPage = (link, data) => {
    // alert(JSON.stringify("catalog_id"+data.catalog_id));
    //alert(JSON.stringify(data.catalog_id));
    const dynamicUrl = link;
    const ourseourl = dynamicUrl.startsWith("/")
      ? dynamicUrl
      : `/${dynamicUrl}`;
    // console.log(data, "data===>", data?.thumbnails?.web_banner?.url);
    const thumbnail = data?.thumbnails?.web_banner?.url;
    navigate(ourseourl);
    // setDetailsData(data);
    // setSelectedCatlog(data.catalog_id);
    // setSelectedContent(data.content_id);
    // setvideotile(data?.title);
    // setVideoThumbnail(thumbnail);
    // setShowThumbnail(true);
    // dispatch(setSelectedThumbnail(thumbnail));
    localStorage.setItem("selectedCatelog", data.catalog_id);
    localStorage.setItem("selectedContent", data.content_id);
    localStorage.setItem("selectedVideoThumb", thumbnail);
    localStorage.setItem("selectedVideoTitle", data?.title);
    //getEpisodeListData(response?.data?.data?.genres,response?.data?.data?.media_type);
    //   getEpisodeListData(data?.genres,data?.media_type);
  };
  // console.log("moreData", moreData);

const handleMore = (link, data, identifyunic) => {

    fetchMoreDetailsData();
    navigate(link);
    window.location.reload();

};
const [imageLoadedArray, setImageLoadedArray] = useState(new Array(0).fill(false));
const handleImageLoad = (index) => {
  setImageLoadedArray(prevState => {
    const newState = [...prevState];
    newState[index] = true;
    return newState;
  });
};
let no_of_skeltens = 0;
if(moreData?.data?.length >= 6 ){
  no_of_skeltens = 8;
 }else{
  no_of_skeltens = moreData?.data?.length; 
 }
//  console.log("episode morepage");


  return (
    <div className="main-page">
      {/* <Header /> */}
      
        <>
          <div className="main background-layer">
            <Container fluid className="pading-left-right-class">
              {!loading && moreData && (
                <div className="showsFilter">
                  <Row className="align-items-center">
                    {" "}
                    <Col lg="auto" md="auto" sm="auto" xs="auto">
                      <h5>{moreData?.title}: </h5>
                    </Col>
                    <Col lg="auto" md="auto" sm="auto" xs="auto">
                      {avilFilters?.length > 0 && (
                        <select
                          className=""
                          onChange={(event) =>
                            subListClicked(
                              event.target.value,
                              avilFilters[0].key
                            )
                          }
                        >
                          <option value="">{moreData.filter_title} </option>
                          {avilFilters.map((sub) => (
                            <option
                              key={sub.title}
                              value={sub.title}
                              selected={sub.title === selectedvalue}
                            >
                              {sub.title.toUpperCase()}
                            </option>
                          ))}
                        </select>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
              <>
                <br></br>
              </>
              
              {loading ? (
  <>
    <div>
  {[...Array(3)].map((_, rowIndex) => (
    <div key={rowIndex} style={{ display: 'flex', flexDirection: 'row' }}>
      {[...Array(3)].map((_, colIndex) => (
        <div key={colIndex} style={{ marginRight: '10px' }}>
          <>
            {/* <SkeletonLoadBoxMOre height={108} width={185} /> */}
          </>
        </div>
      ))}
    </div>
  ))}
</div>


</>        ) :(
  <> 
  {moreData &&
    (moreData?.web_layout_type ?? moreData?.layout_type) === "genre" &&
    moreData?.data?.length > 0 && (
      <Container fluid className="pading-left-right-class">
        {moreData?.data?.map((item, index) => {
            const dynamicUrl = item.seo_url;
            const ourseourl = dynamicUrl.startsWith("/")
              ? dynamicUrl
              : `/${dynamicUrl}`;
              const ourseourlmore = "/more"+ourseourl;
              let defultLocalimage = "";

          return (
            <>
              {item?.catalog_list_items?.length > 0 && (
                <Row>
                  <Col>
                    <h5>{item?.display_title}</h5>
                  </Col>

                  {item?.catalog_list_items?.length > 7 && (
                    <Col
                      style={{
                        textAlign: "end",
                        padding: "0px",
                      }}
                    > 
                      <span
                        key={index}
                        onClick={() =>
                          handleMore(
                            ourseourlmore,
                            item.catalog_list_items[0],
                            "genre"
                          )
                        }
                        className="more-heading"
                      >
                        
                        {/* {ourseourlmore} */}
                        More
                        <span>
                          <FaAngleDoubleRight />
                        </span>
                      </span>
                    </Col>
                  )}
                </Row>
              )}
              {item?.catalog_list_items?.length > 0 && (
                <Slider
                  {...horizontalmodel}
                  className="slider-two"
                >
                  {item?.catalog_list_items?.map(
                    (innerlist, innerIndex) => {
                      const maxCharacters = 24;

                      let displayTitle = item?.title?.length > maxCharacters 
                          ? item?.title?.substring(0, maxCharacters) + "..." 
                          : item?.title;
                          const dynamicUrlinnerlist = innerlist.seo_url;
                          const ourseourlinnerlist = dynamicUrlinnerlist.startsWith("/")
                            ? dynamicUrlinnerlist
                            : `/${dynamicUrlinnerlist}`;
                         
                      return(
                        <>
                          <Link
                        to={ourseourlinnerlist}
                        key={`${index}-${innerIndex}`}
                      >
                        <div className="carousel-car">
                          <div className="extra lock-icon-class cms-container">
                            <div className="thumbnailMovieCard relateddet">
                              {/* <img
                                src={
                                  innerlist?.thumbnails?.high_16_9
                                    ?.url
                                }
                                width="100%"
                                alt={`Slider ${innerIndex}`}
                                className="img-border-radis"
                              /> */}

{ !imageLoadedArray[innerIndex]? (
           <img
           src={!imageLoadedArray[innerIndex] ? horizontaldefultimage: (innerlist?.thumbnails?.high_16_9
            ?.url)}
            alt=""
           width="100%"
           onLoad={() => handleImageLoad(innerIndex)}
           loading="lazy"
           onClick={() => itemSelected(innerIndex,innerlist,moreData)}

           />
         ):(
          <img
          src={imageLoadedArray[innerIndex] ? (innerlist?.thumbnails?.high_16_9
            ?.url) : horizontaldefultimage}
          width="100%"
          onLoad={() => handleImageLoad(innerIndex)}
          onClick={() => itemSelected(innerIndex,innerlist,moreData)}
          loading="lazy"
          alt={`${innerIndex}`}
          />
         ) }


                            </div>

                            {!innerlist?.access_control
                              ?.is_free ? (
                              <div className="lock-postion-class"></div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <b>{defultLocalimage}</b>
                      </Link>
                        </>
                      )
                    }
                  )}
                </Slider>

                
              )}
            </>
          );
        })}
      </Container>
    )}
    </>
)}


             
            
                <Row>
                {moreData  &&  (moreData?.web_layout_type || moreData?.layout_type) !== "genre" && 
      moreData.data.length > 0 &&
      moreData.data.map((item, index) => {
        if(item.media_list_in_list && item.media_type !== 'episode'){
          item.title = item.display_title;
        }else if(!item.media_list_in_list && item.media_type !== 'episode'){
          item.title = item.title;
        }
        const maxCharacters = 24;
        let displayTitle =
          item.title.length > maxCharacters
            ? item.title.substring(0, maxCharacters) + "..."
            : item.title;
        let seourlbind = `/${item.seo_url}`;
        const dynamicUrl = item.seo_url;
        const ourseourl = dynamicUrl.startsWith("/")
          ? dynamicUrl
          : `/${dynamicUrl}`;
          let releaseDate = new Date(item.release_date_string);
          const day = releaseDate.getDate().toString().padStart(2, "0");
          const year = (releaseDate.getFullYear() % 100).toString().padStart(2, "0");
          const monthAbbreviation = releaseDate.toLocaleString("default", { month: "short" });
          let formattedDate = `${day} ${monthAbbreviation} ${year}`;
        return (
          <React.Fragment key={index}>
             
            <Col lg={2} md={3} sm={4} xs={6}>
              <Link
                to={ourseourl}
              >
                <div style={{marginBottom: "20px"}} className="carousel-car continuewatch">
                  <div className="lock-icon-class cms-container cw-card">
                    
                      <>
                        <div className="thumbnailMovieCard">
                      {/* {loading && (
                      <SkeletonLoadBoxMOre loading={loading} />
                      )} */}
                        {/* {!loading && (
                          <img
                            src={
                              item.layout_type === "recipe"
                                ? item.list_item_object.banner_image
                                : item?.thumbnails?.high_16_9?.url
                            }
                            width="100%"
                            loading="lazy"
                            alt={`${index}`}
                          />
                          
                          )} */}

{ !imageLoadedArray[index]? (
           <img
           src={!imageLoadedArray[index] ? verticaldefultimage: (((moreData?.web_layout_type || moreData?.layout_type) === 'tv_shows' || (moreData?.web_layout_type || moreData?.layout_type) === 'movies') ? (item?.thumbnails?.medium_3_4?.url || item?.thumbnails?.high_4_3?.url) : item?.thumbnails?.high_16_9?.url || item.list_item_object.banner_image)}
           width="100%"
           onLoad={() => handleImageLoad(index)}
           alt={`${(moreData?.web_layout_type ?? moreData?.layout_type)}`}
           onClick={() => itemSelected(index,item,moreData)}
           />
         ):(
          <img
          src={imageLoadedArray[index] ? (((moreData?.web_layout_type || moreData?.layout_type) === 'tv_shows' || (moreData?.web_layout_type || moreData?.layout_type) === 'movies') ? (item?.thumbnails?.medium_3_4?.url || item?.thumbnails?.high_4_3?.url) : item?.thumbnails?.high_16_9?.url || item.list_item_object.banner_image) : verticaldefultimage}
          width="100%"
          onLoad={() => handleImageLoad(index)}
          loading="lazy"
          alt={`${(moreData?.web_layout_type ?? moreData?.layout_type)}`}
          onClick={() => itemSelected(index,item,moreData)}
          />
         ) }
         
         <div className="FoodType">
{
  item?.sub_genres?.length > 0 ? (
    item.sub_genres.includes("veg") ? (
      <img
        src={vegicon}
        className=""
        loading="lazy"
        alt="Vegetarian Icon"
      />
    ) : item.sub_genres.includes("non_veg") ? (
      <img
        src={nonvegicon}
        className=""
        loading="lazy"
        alt="Non-Vegetarian Icon"
      />
    ) : null
  ) : null
}

                            </div>
                          
                           <>
                           
                      {pageName2 === "watch-history" && (
                      <div>
                      <ProgressBar variant="danger" now={item.percentage} />
                      <div className="iconcame">
                      <FaPlay />
                    </div>
                      </div>
                      
                      )}

                     
                        </>

                          {item.media_type && pageName2 !== "watch-history"  &&
                            acceptedMediaTypes.includes(
                              item.media_type
                            ) && (
                              <div className="iconcame">
                                <FaPlay />
                              </div>
                            )}
                        </div>
                        {!loading && (   <p className="displayTitle">  {displayTitle.length > 13 ? `${displayTitle.substring(0, 13)}...` : displayTitle}
                              {item.release_date_string ? ` | ${formattedDate}` : ""}</p>  )}
                      </>
               
                    {!loading && item.access_control &&
                      !item.access_control.is_free && (
                        <div className="lock-postion-class"></div>
                      )}
                 
                  </div>
                </div>
              </Link>
            </Col>
            {/* {loading && (
            <SkeletonLoadBoxMOre loading={loading} />
            )} */}
           
          </React.Fragment>
        );
      })}


                </Row>
            
                   
            </Container>

          
          </div>
          {  moreData.data.length > 0 &&
          <Footer />
          }
        </>
  

      {loading && <Loader />}
    </div>
  );
};

export default EpisodeMore;
