import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  throughSeach: false,
};

export const searchmodalSlice = createSlice({
  name: "searchmodal",
  initialState,
  reducers: {
    setThroughSeach: (state, action) => {
        state.throughSeach = action.payload.throughSeach  
      },
  },
});

export const {setThroughSeach} = searchmodalSlice.actions;

export default searchmodalSlice.reducer;