import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Hls from "hls.js";
import "plyr-react/plyr.css";
import "./player-custom-styles.css";
import { usePlyr } from "plyr-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setModalOpen } from "../Redux/Slice/loginModalSlice";
import { BASE_URL, auth_token } from "../services/config";
import axios from "axios";
import { setShowStreamInfo } from "../Redux/Slice/streamlimitSlice";
import { setAuth } from "../Redux/Slice/authSlice";
import { setAccountData } from "../Redux/Slice/accountsDataSlice";
import LiveStream from "../pages/Videoplayer/LiveStream";
import { setIsrelatedVideosClicked } from "../Redux/Slice/videodetailSlice";
import useGoogleIMA from "../plugins/useGoogleIMA";
import { initializeAds, loadAds, cancelAds } from "../plugins/ads";
import { guestuserid } from "../App";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const isIOS = /iPhone|iPod/.test(navigator.userAgent);
const isMobile = window.innerWidth <= 768;

var controls = [
  "play-large",
  "rewind",
  "play",
  "fast-forward",
  "progress",
  "current-time",
  "mute",
  "volume",
  "captions",
  "settings",
  !isMobile && "pip",
  "airplay",
  "fullscreen",
];

const videoOptions = {
  muted: isIOS ? true : false,
  controls,
  autoplay: isIOS ? false : true,
  clickToPlay: false,
};

const useHls = (src, options) => {
  const { getallDetails } = useSelector((state) => state.getalldata || {});

  const navigate = useNavigate();
  const hls = useRef(null);
  const hasQuality = useRef(false);
  const [plyrOptions, setPlyrOptions] = useState(options);
  let defaultQuality = 0;
  const { category } = useParams();
  const [isTrailerPlaying, setIsTrailerPlaying] = useState(getallDetails?.stream_info?.adaptive_url !== "" ? false : true)
  const dispatch = useDispatch()
  useEffect(() => {
    hasQuality.current = false;
  }, [options]);

  // console.log("snnsnsnn", isTrailerPlaying)

  useEffect(() => {
    // Clean up the HLS instance when the component unmounts or src changes
    return () => {
      if (hls.current) {
        hls.current.destroy();  // Clean up the HLS instance
        hls.current = null;     // Prevent further access to the HLS instance
      }
    };
  }, [src]);


  useEffect(() => {
    if (!hls.current && Hls.isSupported()) {
      hls.current = new Hls();
    }

    if (Hls.isSupported() && src && typeof src?.videoUrl === 'string' && src?.videoUrl && src?.videoUrl?.trim() !== "") {
      hls.current.config.xhrSetup = (xhr) => {
        // Ensure headers are set properly if available
        Object.entries(src?.headers || {}).forEach(([header, value]) => {
          xhr.setRequestHeader(header, value);
        });
      };

      // Hls

      // console.log("sjsmsms", !src.thumbnailUrl)

      hls?.current?.loadSource(src?.videoUrl);
      const videoElement = document.getElementById("player");
      hls?.current?.attachMedia(videoElement);
      hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
        if (hasQuality.current) return;

        const levels = hls.current.levels;
        const qualityOptions = levels.map((level) => level.height);
        qualityOptions.sort((a, b) => b - a);
        qualityOptions.unshift(defaultQuality);  // Add "Auto" option

        const quality = {
          default: defaultQuality,
          options: qualityOptions,
          forced: true,
          onChange: async (newQuality) => {
            // console.log("Quality changed to:", newQuality);
            // Pause the video when quality changes
            const videoElement = document.getElementById("player");
            if (videoElement) {
              videoElement.pause();
            }
        
            // Handle quality change logic
            if (newQuality === defaultQuality) {
              hls.current.startLoad();
              await hls.current.startLoad(-1);
              hls.current.currentLevel = -1;  // Auto-quality
            } else {
              levels.forEach((level, levelIndex) => {
                if (level.height === newQuality) {
                  hls.current.currentLevel = levelIndex;
                }
              });
            }
          },
        };

        const i18n = {
          qualityLabel: {
            0: "Auto",
          },
        };

        setPlyrOptions((prevOptions) => ({
          ...prevOptions,
          quality,
          i18n,
           // debug: true,
          keyboard: {
            global: true,
          },
          blankVideo: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          previewThumbnails: {
            enabled: isTrailerPlaying ? !src?.thumbnailUrl : !!src?.thumbnailUrl,
            src: src?.thumbnailUrl || "",
          },
        }));

        hasQuality.current = true;
      });

      // Error handling to prevent buffer issues
      hls.current.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.error("Network error:", data.details);
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.error("Media error:", data.details);
              if (data.details === "manifestIncompatibleCodecsError") {
                console.error("Incompatible codecs in manifest:", data.url);
      
                // Show an error toast
                toast.error("Dolby Atmos is not supported on this device.");
      
                navigate("/");
              }
              break;
            default:
              console.error("Fatal error:", data.type, data.details);
              break;
          }
        } else {
          console.warn("Non-fatal error:", data);
        }
      });
      
      
      
      
    } else if (src && src.videoUrl && src.videoUrl.trim() !== "") {
      // Fallback for browsers that don't support HLS.js
      const videoElement = document.getElementById("player");
      if (videoElement) {
        videoElement.src = src.videoUrl;
      }
    }
  }, [src, plyrOptions, category]);

  return { options: plyrOptions };
};

const CustomPlyrInstance = forwardRef((props, ref) => {
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  const { source, videoDetails, options } = props;
  const regionsData = useSelector((state) => state.region.regionsData);
  const pausedRef = useRef(false);
  const navigation = useNavigate();
  const location = useLocation();
  const [startTime, setStartTime] = useState(0);
  const [selectedQuality, setSelectedQuality] = useState(0);

  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isForwarding, setIsForwarding] = useState(false);

  const [watchedDuration, setWatchedDuration] = useState(0);

  const [currentVideo, setCurrentVideo] = useState({
    source: source,
    details: videoDetails,
  });

  const { sessionId } = useSelector((state) => state.auth);
  const session_id = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;

  // console.log("videoDetails", videoDetails);
  const [progress, setProgress] = useState({ currentTime: 0, duration: 0 });
  const [videoEnded, setVideoEnded] = useState(false);
  const [nextVideo, setNextVideo] = useState({ source: null, details: null });
  const [showNextButton, setShowNextButton] = useState(false);
  const [watchHistoryError, setWatchHistoryError] = useState("");

  const [loginModalOpened, setLoginModalOpened] = useState(false);
  const [nextVideoDetails, setNextVideoDetails] = useState({});
  const [isUpNextAppear, setIsUpNextAppear] = useState(false);
  const [isNextContent, setIsNextContent] = useState(false);
  const [showSkipIntro, setShowSkipIntro] = useState(false);
  const isUserLoggedin = localStorage.getItem("login_status");
  const [isLoggedIn, setIsLoggedIn] = useState(
    (isUserLoggedin === "true" || source?.login_status === true) ? true : false
  );
  
  const isUserSubscribed = localStorage.getItem("isUserSubscribed");
  const [isSubscribed, setIsSubscribed] = useState(
    (source?.is_subscribed || isUserSubscribed) === true ? true : false
  );
  const [isUpgraded, setIsUpgraded] = useState(
    source?.stream_info?.upgrade_plan
  );
  const [currentPlayedTime, setCurrentPlayedTime] = useState(0);
  const [isTrailer, setIsTrailer] = useState(
    source?.stream_info?.adaptive_url !== "" ? false : true
  );
  const [videoSource, setVideoSource] = useState({
    videoUrl: "",
    thumbnailUrl: "",
    headers: {},
  });
  const [adConfig, setAdConfig] = useState({
    preRollAdUrl: "",
    midRollAdUrl: "",
  });
  const isHlsSupported = Hls.isSupported();
  const [cuePoints, setCuePoints] = useState([]);
  const [paused, setPaused] = useState(false);
  const [isAdPlaying, setIsAdPlaying] = useState(false);
  const [adInitialized, setAdInitialized] = useState(false);
  const [adContainerRemoved, setAdContainerRemoved] = useState(false);
  const [processedAds, setProcessedAds] = useState(new Set());
  const [skippedCuePoints, setSkippedCuePoints] = useState(new Set());
  const [isSeeked, setIsSeeked] = useState(false);
  const preRollRef = useRef({
    preRollCalled: false,
  });

  let adQueue = [];

  const countRef = useRef(count);
  const watchedDurationRef = useRef(0);
  const relatedVideoClickedRef = useRef(false);
  // console.log("navigatorsksks", navigator)

  const adaptiveUrl = currentVideo.source?.stream_info?.adaptive_url;
const adaptiveUrlAk = currentVideo.source?.stream_info?.adaptive_url_ak;

// console.log("adaptiveUrl", adaptiveUrl)
// console.log("adaptiveUrl", adaptiveUrl)

  useEffect(() => {
    // adaptive_url_ak
    if (adaptiveUrl || adaptiveUrlAk) {
      setVideoSource({
        videoUrl: adaptiveUrl ? adaptiveUrl : adaptiveUrlAk,
        thumbnailUrl: currentVideo?.source?.stream_info?.seekbar_thumbnail_url,
        // ...(currentVideo?.source?.stream_info?.is_secured === true && {
        //   headers: {
        //     user_id: session_id,
        //     subscriber_id: getallDetails?.user_info?.analytics_user_id,
        //     "User-Agent": navigator.userAgent
        //   }
        // })
      });
      if (!isSubscribed) {
        setAdConfig({
          preRollAdUrl:
            currentVideo?.details?.access_control?.pre_role_settings?.ads_url,
          midRollAdUrl:
            currentVideo?.details?.access_control?.mid_role_settings?.ads_url,
        });
        setCuePoints(
          currentVideo?.details?.access_control?.mid_role_settings
            ?.midroll_position
        );
      }
      setIsTrailer(false);
    } else {
      setVideoSource({
        videoUrl: currentVideo.source?.stream_info?.preview?.adaptive_url,
        thumbnailUrl: currentVideo.source?.stream_info?.seekbar_thumbnail_url,
        // headers: {
        //   user_id: session_id,
        //   subscriber_id: getallDetails?.user_info?.analytics_user_id,
        //   "User-Agent": navigator.userAgent,
        // },
      });
      if (!isSubscribed) {
        setAdConfig({
          preRollAdUrl:
            currentVideo?.details?.access_control?.pre_role_settings?.ads_url,
          midRollAdUrl:
            currentVideo?.details?.access_control?.mid_role_settings?.ads_url,
        });
        setCuePoints(
          currentVideo?.details?.access_control?.mid_role_settings
            ?.midroll_position
        );
      }
      setIsTrailer(true);
    }
    const playbackTimeInMilliseconds = convertTimeToMilliseconds(
      currentVideo.source?.stream_info?.play_back_time
    );
    setCurrentPlayedTime(playbackTimeInMilliseconds);
  }, [source, videoDetails, currentVideo]);

  const raptorRef = usePlyr(ref, {
    ...useHls(videoSource, options),
    speed: { selected: "Normal" },
  });

  const userConfig = useSelector((state) => state.user_config.userConfigData);
  const watchhistoryIntervalTime =
    userConfig?.params_hash2?.config_params?.watchhistory_interval_sec;
  const historyAPI = userConfig?.params_hash2?.config_params?.watchhistory_api;
  const playBackStatusRef = useRef("playing");

  const progressRef = useRef({ currentTime: 0, duration: 0 });
  useEffect(() => {
    if (progress) {
      progressRef.current = progress;
    }
  }, [progress]);

  const triggeredEvent = useRef({
    pbStart: false,
    pbEnd: false,
  });

  const triggeredPercentages = useRef({
    percent10: false,
    percent25: false,
    percent50: false,
    percent75: false,
    percent90: false,
  });
  const sourcetab = localStorage.getItem("selectedMenuItem");
  // console.log("sourcetabjsjsj", sourcetab)
 const traydetails =  localStorage.getItem("traynamedata")
 
//  console.log("tetette", traydetails)

  const { layoutname } = useSelector((state) => state.shows);
  const { getallDetails } = useSelector((state) => state.getalldata || {});
  const { videodetailsitems } = useSelector((state) => state.video_details);
  const traynamedata = localStorage.getItem("traynamedata");
  const { maintraynamedata } = useSelector((state) => state.navmenu_called);
  const { throughSeach } = useSelector((state) => state.searchtrayname);

  // console.log("maintraynamedatasss", maintraynamedata)

  const navitemname = localStorage.getItem("navtab");
  //  console.log("videoDetailssss", videoDetails)
  //  console.log("maintraynamedata", maintraynamedata)
   
  //  getallDetails?.user_info?.analytics_user_id
  const triggeranalytics = async (name, sec, event_id) => {
    const sessionId = session_id || "NA";
    const ip = regionsData?.ip || "NA";
    const state = regionsData?.state || "NA";
    const city = regionsData?.city_name || "NA";
    const postalCode = regionsData?.postal_code || "NA";
    const country = regionsData?.country_name;
    // const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;

    const obj = {
      content_provider: videoDetails?.content_provider || "NA",
      consumption_type: "Online",
      content_type: videoDetails?.media_type || "NA",
      content_value: videoDetails?.content_value || "NA",
      device_id: "NA",
      event_time: new Date().toISOString().slice(0, 19),
      chromecast: 0,
      genre: videoDetails?.display_genres.join(",") || "NA",
      quality: selectedQuality === 0 ? "Auto" : `${selectedQuality}p` || "Auto",
      source: sourcetab?.toLowerCase(),
      show_name: videoDetails?.show_name || "NA",
      show_id: videoDetails?.show_theme_id || "NA",
      series_name: videoDetails?.subcategory_name || "NA",
      series_id: videoDetails?.subcategory_id || "NA",
      session_id: sessionId || "NA",
      platform: "web",
      ip: ip || "NA",
      tray_id: throughSeach === true ? "NA" : videodetailsitems?.content_id,
      state: state || "NA",
      city: city || "NA",
      postal_code: postalCode || "NA",
      country: country,
      tray_name: throughSeach === true ? "NA" : (maintraynamedata?.display_title ? maintraynamedata?.display_title?.toLowerCase() : maintraynamedata?.title?.toLowerCase() ),

      u_id:
        getallDetails?.login_status === true
          ? getallDetails?.user_info?.analytics_user_id
          : guestuserid,
      value: sec,
      video_id: videoDetails?.content_id || "NA",
      video_name: videoDetails?.title || "NA",
      video_language: videoDetails?.language || "NA",
      subtitles: "NA",
      event_id: event_id,
    };
    // console.log("obj: ", obj);

    if (window && window.sdk && window.sdk.trackEvent) {
      window.sdk.trackEvent(name, obj);
    } else {
      console.error("Analytics SDK is not initialized yet.");
    }
  };

  const handlePlaybackStatusChange = (status) => {
    playBackStatusRef.current = status;
    if(session_id && isUserLoggedin === "true"){
      loadWatchHistory();
    }
  };

  const handleUnmount = () => {
    if (countRef.current > 0 && !triggeredEvent.current.pbEnd) {
      triggeranalytics("pb_end", countRef.current, 2);
      triggeredEvent.current.pbStart = false;
      triggeredEvent.current.pbEnd = true;
    }
    // loadWatchHistory();
  };

  useEffect(() => {
    return () => {
      if (!isNextContent) {
        handleUnmount();
      }
    };
  }, [isNextContent]);

  useEffect(() => {
    const handleCloseTab = (event) => {
      event.preventDefault();
      if (countRef.current > 0 && !triggeredEvent.current.pbEnd) {
        triggeranalytics("pb_end", countRef.current, 2);
        setCount(0);
        countRef.current = 0;
        setPaused(true);
        triggeredEvent.current.pbStart = false;
        triggeredEvent.current.pbEnd = true;
      }
      if(session_id && isUserLoggedin === "true"){
        loadWatchHistory();
      }
    };

    if (!isNextContent) {
      window.addEventListener("beforeunload", handleCloseTab);
    }

    return () => {
      if (!isNextContent) {
        window.removeEventListener("beforeunload", handleCloseTab);
      }
    };
  }, [isNextContent]);

  // console.log("getalldetailsss", videodetailsitems);
  const isrelatedVideosClicked = useSelector(
    (state) => state.video_details.isrelatedVideosClicked
  );
  const previousVideoCountRef = useRef(0);

  useEffect(() => {
    // console.log("isrelatedVideosClickedss", isrelatedVideosClicked)

    let countInterval;

    const handleVideoPlayback = () => {
      if (!paused && !isForwarding && !isSeeking && !loading) {
        countInterval = setInterval(() => {
          setCount((prevCount) => {
            const newCount = prevCount + 1;
            countRef.current = newCount;
            return newCount;
          });
          setWatchedDuration((prevDuration) => {
            const newDuration = prevDuration + 1;
            watchedDurationRef.current = newDuration;
            return newDuration;
          });
        }, 1000);
      } else {
        clearInterval(countInterval);
      }
    };

    // if (isrelatedVideosClicked === true) {
    //   triggeranalytics("pb_end", countRef.current, 2);
    // }

    handleVideoPlayback();

    return () => clearInterval(countInterval);
  }, [paused, isForwarding, isSeeking, loading, videoEnded]);

  // useEffect(() => {
  //   if (isrelatedVideosClicked === true) {
  //     // Update previousVideoCountRef to the current video's count
  //     previousVideoCountRef.current = countRef.current;
  //     // Trigger pb_end for the previous video using previousVideoCountRef
  //     triggeranalytics("pb_end", previousVideoCountRef.current, 2);
  //     // Reset the current video's count
  //     setCount(0);
  //     countRef.current = 0; // Ensure the ref is reset as well

  //     // Reset the flag in the redux store
  //     dispatch(setIsrelatedVideosClicked({ isrelatedVideosClicked: false }));
  //     triggeredEvent.current.pbStart = false;
  //     triggeredEvent.current.pbEnd = true;
  //   }
  // }, [isrelatedVideosClicked]);

  // console.log("isforward", isForwarding)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isSeeking) {
        if (paused && count > 0 && !triggeredEvent.current.pbEnd) {
          triggeranalytics("pb_end", count, 2);
          setCount(0);
          triggeredEvent.current.pbStart = false;
          triggeredEvent.current.pbEnd = true;
        } else if (!triggeredEvent.current.pbStart) {
          setLoading(false);
          triggeranalytics("pb_start", 1, 1);
          setPaused(false);
          triggeredEvent.current.pbStart = true;
          triggeredEvent.current.pbEnd = false;
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [paused, isSeeking, isForwarding]);

  const nextItemUrl = `${BASE_URL}catalogs/${currentVideo.details?.catalog_id}/items/${currentVideo.details?.content_id}/next_item?auth_token=${auth_token}&access_token=${getaccessToken?.access_token}&region=${regionsData?.country_code2}&item_language=eng`;

  const loadWatchHistory = async () => {
    const currentTime =
      progressRef && progressRef.current && progressRef?.current?.currentTime;
    // console.log("currentTime", currentTime);
    try {
      var playBackTime = "00:00:00";
      if (currentTime) {
        var formattedTime = new Date(currentTime * 1000)
          .toISOString()
          .substr(11, 8);
        playBackTime = formattedTime;
      }
      if (currentTime > 0 && playBackTime !== "00:00:00") {
        const watchHistoryAPI = `${historyAPI}users/${session_id}/playlists/watchhistory`;

        if (isLoggedIn && !isTrailer) {
          //  const response =
          await axios.post(watchHistoryAPI, {
            auth_token: auth_token,
            access_token: getaccessToken?.access_token,
            listitem: {
              user_id: session_id,
              content_id: currentVideo?.details?.content_id,
              catalog_id: currentVideo?.details?.catalog_id,
              play_back_time: playBackTime,
              play_back_status: playBackStatusRef.current,
            },
          });
          // console.log("watchhistorydata", response.data);
        }
      }
    } catch (error) {
      setWatchHistoryError(error?.response?.data?.error?.message);
      console.log("waa errorsss", error?.response?.data?.error?.message);
    }
  };

  // useEffect(() => {
  //   const updateHistoryIntervalId = setInterval(() => {
  //     if (!paused) {
  //       playBackStatusRef.current = "playing";
  //       loadWatchHistory();
  //     }
  //   }, watchhistoryIntervalTime * 1000);

  //   if (paused) {
  //     playBackStatusRef.current = "stopped";
  //   } else {
  //     playBackStatusRef.current = "playing";
  //   }

  //   return () => {
  //     clearInterval(updateHistoryIntervalId);
  //   };
  // }, [paused, watchhistoryIntervalTime]);

  useEffect(() => {
    const updateHistoryIntervalId = setInterval(() => {
      if (!paused) {
        handlePlaybackStatusChange("playing");
      }
    }, watchhistoryIntervalTime * 1000);

    return () => clearInterval(updateHistoryIntervalId);
  }, [paused, watchhistoryIntervalTime]);

  useEffect(() => {
    if (paused) {
      handlePlaybackStatusChange("stopped");
    } else {
      handlePlaybackStatusChange("playing");
    }
  }, [paused]);

  // Listen for visibility change to handle tab or page switches
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "hidden") {
  //       handlePlaybackStatusChange("stopped");
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  // Handle unmount to update the status correctly when navigating away
  useEffect(() => {
    return () => {
      handlePlaybackStatusChange("stopped");
    };
  }, []);

  useEffect(() => {
    if (watchHistoryError === "Invalid Session Id") {
      localStorage.removeItem("session_id");
      localStorage.setItem("login_status", false);
      localStorage.setItem("showSubscripeButton", true);
      dispatch(setAccountData({ accountData: {} }));
      dispatch(setAuth({ isLoggedIn: false, usersessionID: "" }));
      navigation("/home");
      dispatch(setShowStreamInfo({ showStreamLimit: true }));
      // window.location.href='/home'
    }
  }, [watchHistoryError]);

  const { accountData } = useSelector((state) => state.account_data);
  const { category, seo_url } = useParams();

  useEffect(() => {
    const fetchNextItem = async () => {
      try {
        const response = await axios.get(nextItemUrl);
        if (response.status === 200) {
          setNextVideoDetails(response?.data?.data);
          setIsUpNextAppear(true);
          setNextVideo({ source: null, details: response.data.data });
        }
      } catch (error) {
        setIsUpNextAppear(false);
        console.log(error);
      }
    };
    fetchNextItem();
  }, [currentVideo]);

  const convertTimeToMilliseconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const totalMilliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;

    return totalMilliseconds;
  };

  useEffect(() => {
    const checkLoginStatus = () => {
      setIsLoggedIn(source?.login_status);
    };
    const checkSubscription = () => {
      setIsSubscribed(source?.is_subscribed);
    };
    const checkUpgradeStatus = () => {
      setIsUpgraded(source?.stream_info?.upgrade_plan);
    };
    checkLoginStatus();
    // checkSubscription();
    checkUpgradeStatus();
  }, [isLoggedIn, source, isSubscribed, isUpgraded]);

  useEffect(() => {
    const handleAdInitialized = () => {
      setAdInitialized(true);
    };
    const handleAdPlaying = () => {
      setIsAdPlaying(true);
      setPaused(true);
    };
    const handleAdStopped = () => {
      setAdContainerRemoved(false);
      setIsAdPlaying(false);
      setPaused(false);
      setAdInitialized(false);
      cancelAds();
    };

    const handleAdFailed = () => {
      setAdInitialized(false);
    };

    window.addEventListener("adInitialized", handleAdInitialized);
    window.addEventListener("adPlaying", handleAdPlaying);
    window.addEventListener("adStopped", handleAdStopped);
    window.addEventListener("adFailed", handleAdFailed);

    return () => {
      window.removeEventListener("adInitialized", handleAdInitialized);
      window.removeEventListener("adPlaying", handleAdPlaying);
      window.removeEventListener("adStopped", handleAdStopped);
      window.removeEventListener("adFailed", handleAdFailed);
    };
  }, [isLoggedIn,
     isAdPlaying,
     currentVideo,
     getallDetails,
     category, 
     seo_url,
     currentVideo?.details?.access_control?.pre_role_settings?.ads_url,
     currentVideo?.details?.access_control?.mid_role_settings?.ads_url,
     ref.current,
     preRollRef.current,
     cuePoints,
     adContainerRemoved,
     isSubscribed,
     isSeeking,
     isSeeked,
     adInitialized,
     isHlsSupported,]);

     useEffect(() => {
    // if (!ref.current?.plyr) return;
    if (!ref.current?.plyr || !ref.current?.plyr?.elements) return;
    setTimeout(() => {
      const player = ref && ref.current && ref.current?.plyr;
      const playerContainer = player?.elements?.container;
      // const playerContainer = ref.current?.plyr?.elements?.container;
      // const buttons = ref.current?.plyr?.elements?.buttons;
      const buttons = player?.elements?.buttons;
      const forwardButton = buttons?.fastForward;
      const rewindButton = buttons?.rewind;
      const playButton = buttons?.play[0];
      // const progress = ref.current?.plyr?.elements?.progress;
      const progress = player && player?.elements && player?.elements?.progress;
      const controls = player?.elements?.controls;
            // console.log("nsssss", player?.config)
      // const controls = ref.current?.plyr?.elements?.controls;
      if (selectedQuality !== player?.config?.quality?.selected) {
        setSelectedQuality(player?.config?.quality?.selected);
      }

      if (controls && category === "live") {
        const timer = document.querySelector(".plyr__time");
        if (timer) {
          timer.remove();
        }
        forwardButton.remove();
        rewindButton.remove();
        const existingLiveTag = document.querySelector(".live__tag");
        const liveTag = document.createElement("div");
        liveTag.classList.add("live__tag");
        liveTag.innerHTML = "Live";

        if (!existingLiveTag) {
          controls.insertBefore(liveTag, controls.children[2]);
        }
      }

	 if (progress && category === "live") {
    const progressContainer = document.querySelector('.plyr__progress input[type="range"]');
    if (progressContainer) {
        progressContainer.style.display = 'none';
    }

    const tooltip = document.querySelector('.plyr__tooltip');
    if (tooltip) {
        tooltip.style.display = 'none';

        const bufferedProgress = document.querySelector('.plyr__progress__buffer');
    if (bufferedProgress) {
        bufferedProgress.style.display = 'none'; // Hide the buffered progress
    }
        
    }

    let customProgressBar = document.querySelector('.custom-progress-bar');
    if (!customProgressBar) {
      customProgressBar = document.createElement('div');
      customProgressBar.classList.add('custom-progress-bar'); // Add a class to identify the custom progress bar
      customProgressBar.style.position = 'relative';
      customProgressBar.style.width = '100%';
      customProgressBar.style.height = '6px';
      customProgressBar.style.backgroundColor = '#ccc';
      customProgressBar.style.borderRadius = '5px';

      const fillElement = document.createElement('div');
      fillElement.style.position = 'absolute';
      fillElement.style.height = '100%';
      fillElement.style.width = '100%';
      fillElement.style.borderRadius = '5px';
      // fillElement.style.backgroundColor = '#fff';
      fillElement.style.backgroundColor = 'red';

      const indicatorElement = document.createElement('div');
      indicatorElement.style.position = 'absolute';
      indicatorElement.style.right = '0';
      indicatorElement.style.top = '-7px';
      indicatorElement.style.transform = 'translateX(38%)';
      indicatorElement.style.fontSize = '12px';
      indicatorElement.innerHTML = '🔘';
      // indicatorElement.innerHTML = '🔴';

      customProgressBar.appendChild(fillElement);
      customProgressBar.appendChild(indicatorElement);

      tooltip.parentNode.appendChild(customProgressBar);
    }
   
    progress.addEventListener("mousedown", (event) => {
      event.preventDefault();
  });
}

	 ///////////////////////////////////////////////////////////////////////////////////////////////


      if (buttons) {
        const changeButtonIcon = () => {
          const customizeButton = (button, svgContent) => {
            if (button) {
              const existingButton = button.querySelector(".custom__controls");
              if (!existingButton) {
                button.innerHTML = svgContent;
                const controlsParent = button.closest(".plyr__controls");

                if (controlsParent) {
                  controlsParent.parentNode.insertBefore(
                    button,
                    controlsParent.nextSibling
                  );
                  button?.classList.remove("plyr__controls__item");
                  button?.classList.add("plyr__control--overlaid");
                  button?.classList.add("custom__controls");
                }
              }
            }
          };

          if (playButton) {
            playButton?.classList.add("custom__play");
            const pauseIcon = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "svg"
            );
            pauseIcon.setAttribute("xmlns", "http://www.w3.org/2000/svg");
            pauseIcon.setAttribute("viewBox", "0 0 64 64");
            pauseIcon.setAttribute("stroke-width", "4");
            pauseIcon.setAttribute(
              "class",
              "duration-300 transform transition-all pause__icon"
            );
            pauseIcon.innerHTML =
              '<path d="M19.2 10.99h7.03v41.96H19.2zM35.06 10.99h7.03v41.96h-7.03z"></path>';
            const existingPause = playButton?.querySelector(".pause__icon");
            if (!existingPause) {
              playButton?.appendChild(pauseIcon);
            }
          }

          customizeButton(
            forwardButton,
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="4" class="duration-300 transform transition-all"><path d="M23.93 41.41V23a.09.09 0 00-.16-.07s-2.58 3.69-4.17 4.78" stroke-linecap="round"></path><rect x="29.19" y="22.52" width="11.41" height="18.89" rx="5.7"></rect><path stroke-linecap="round" d="M54.43 15.41l-2.6 8.64-8.64-2.61M51.86 23.94a21.91 21.91 0 10.91 13.25"></path></svg>'
          );

          customizeButton(
            rewindButton,
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" stroke-width="4" class="duration-300 transform transition-all"><path stroke-linecap="round" d="M9.57 15.41l2.6 8.64 8.64-2.61M26.93 41.41V23a.09.09 0 00-.16-.07s-2.58 3.69-4.17 4.78"></path><rect x="32.19" y="22.52" width="11.41" height="18.89" rx="5.7"></rect><path d="M12.14 23.94a21.91 21.91 0 11-.91 13.25" stroke-linecap="round"></path></svg>'
          );
        };
        changeButtonIcon();
      }

      let videoTitle = "";

      if (currentVideo.details?.media_type === "movie") {
        if (isTrailer) {
          videoTitle = `${currentVideo.details?.title} - Trailer`;
        } else {
          videoTitle = currentVideo.details?.title;
        }
      } else {
        if (isTrailer) {
          if (currentVideo.details?.show_name) {
            videoTitle = `${currentVideo.details?.show_name} - ${currentVideo.details?.title} - Trailer`;
          } else {
            videoTitle = `${currentVideo.details?.title} - Trailer`;
          }
        } else {
          if (currentVideo.details?.show_name) {
            videoTitle = `${currentVideo.details?.show_name} - ${currentVideo.details?.title}`;
          } else {
            videoTitle = `${currentVideo.details?.title}`;
          }
        }
      }

      if (playerContainer) {
        const titleElement = document.createElement("div");
        titleElement.className = "plyr_control--overlaid plyr__title";
        const titleHeader = document.createElement("h5");
        titleHeader.textContent = videoTitle;
        const rating = document.createElement("p");
        rating.classList = "content__rating";
        rating.textContent = `RATED ${currentVideo?.details?.cbfc_rating}`;
        const displayGenres = document.createElement("p");
        displayGenres.className = "title__genre";
        const genresString = currentVideo?.details?.display_genres.join(", ");
        displayGenres.textContent = genresString;
        titleElement.appendChild(titleHeader);
        if (currentVideo?.details?.cbfc_rating) {
          titleElement.appendChild(rating);
        }
        if (currentVideo?.details?.display_genres?.length > 0) {
          titleElement.appendChild(displayGenres);
        }

        const existingTitle = playerContainer?.querySelector(".plyr__title");

        if (!existingTitle) {
          playerContainer.insertBefore(
            titleElement,
            playerContainer?.firstChild
          );
        }

        const existingSkipButton = playerContainer?.querySelector(
          ".skip-intro__button"
        );
        if (showSkipIntro && !isTrailer && !existingSkipButton) {
          const skipIntroButton = document.createElement("button");
          skipIntroButton.className =
            "plyr_control plyer__control--overlaid skip-intro__button";
          skipIntroButton.setAttribute("data-type", "skip-intro");
          skipIntroButton.textContent = "Skip Intro";

          playerContainer.appendChild(skipIntroButton);

          skipIntroButton.addEventListener("click", () => {
            player.media.currentTime = currentVideo.details?.intro_end_time_sec;
            setShowSkipIntro(false);
          });
        } else if (!showSkipIntro && existingSkipButton) {
          playerContainer?.removeChild(existingSkipButton);
        }

        if (isUpNextAppear) {
          setIsNextContent(true);
        } else {
          setIsNextContent(false);
        }
        const existingUpNextButton =
          playerContainer?.querySelector(".up-next__button");

        if (
          showNextButton &&
          !existingUpNextButton &&
          isUpNextAppear &&
          !isTrailer
        ) {
          const upNextButton = document.createElement("button");
          upNextButton.className =
            "plyr_control plyer__control--overlaid up-next__button";
          upNextButton.setAttribute("data-type", "up-next");
          upNextButton.textContent = "Up Next";

          playerContainer.appendChild(upNextButton);

          upNextButton.addEventListener("click", () => {
            navigation(nextVideoDetails?.seo_url);
            // window.location.href = nextVideoDetails?.seo_url;
            preRollRef.current.preRollCalled = false;
            triggeranalytics("pb_end", count, 2);
            setCount(0);
            triggeredEvent.current.pbStart = false;
            triggeredEvent.current.pbEnd = true;
            setShowNextButton(false);
            setIsUpNextAppear(false);
          });
        } else if (!showNextButton && existingUpNextButton) {
          playerContainer?.removeChild(existingUpNextButton);
        }

        if (isUpNextAppear && videoEnded && !isTrailer) {
          // window.location.href = nextVideoDetails?.seo_url;
          navigation(nextVideoDetails?.seo_url);
          triggeranalytics("pb_end", count, 2);
          setCount(0);
          triggeredEvent.current.pbStart = false;
          triggeredEvent.current.pbEnd = true;
        }

        if (isTrailer && videoEnded) {
          const modalContainer = document.createElement("div");
          modalContainer.className = "plyr_control--overlaid custom__modal";
          modalContainer.setAttribute("show-modal", "show");
          const existingModal =
            playerContainer?.querySelector(".custom__modal");
            // console.log("isLoggedInsss", isLoggedIn)
            // console.log("isUserLoggedinsss", isUserLoggedin)
            // console.log("sourcesss", source)

            if (isLoggedIn || isSubscribed || isUpgraded) {
              const existingModal = playerContainer?.querySelector(".custom__modal");
              if (existingModal) {
                existingModal.remove(); // Remove modal once logged in
              }
            }
            
          if (!existingModal) {
            const modalContent = document.createElement("div");
            modalContent.className = "modal__content";

            const modalTitle = document.createElement("h6");
            modalTitle.className = "modal__title";
            if (isUpgraded && !isSubscribed) {
              modalTitle.textContent =
                "Please Upgrade your Account to Start Streaming";
            } else {
              modalTitle.textContent = "Subscribe to watch this content";
            }
            const buttonContainer = document.createElement("div");
            buttonContainer.className = "button__container";
            buttonContainer.setAttribute("is-loggedin", isLoggedIn);
            const LoginButton = document.createElement("button");
            LoginButton.className = "modal__button";
            LoginButton.setAttribute("data-type", "login");
            LoginButton.textContent = "Login";
            LoginButton.title = "Login";
            LoginButton.addEventListener("click", () => {
              const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement;
              const exitFullscreen = document.exitFullscreen || document.webkitExitFullscreen;
            
              if (isFullscreen) {
                exitFullscreen.call(document)
                  .then(() => {
                    document.body.focus(); // Fix potential focus issues
                    dispatch(setModalOpen());
                    setLoginModalOpened(true);
                  })
                  .catch(err => {
                    console.error("Failed to exit fullscreen: ", err);
                  });
              } else {
                dispatch(setModalOpen());
                setLoginModalOpened(true);
              }
            });
            

            const SubsCribeButton = document.createElement("button");
            SubsCribeButton.className = "modal__button";
            SubsCribeButton.setAttribute("data-type", "subscribe");
            SubsCribeButton.textContent = "Subscribe";
            SubsCribeButton.title = "Subscribe";
            SubsCribeButton.addEventListener("click", () => {
              if(isUserLoggedin === "true"){
                navigation("/profile?tab=subscription");
              }else{
                navigation('/subscription-plans');
              }
            });

            const upgradeButton = document.createElement("button");
            upgradeButton.className = "modal__button";
            upgradeButton.setAttribute("data-type", "upgrade");
            upgradeButton.textContent = "Upgrade Now";
            upgradeButton.title = "Upgrade Now";
            upgradeButton.addEventListener("click", () => {
              if(isUserLoggedin === "true"){
                navigation("/profile?tab=subscription");
              }else{
                navigation('/subscription-plans');
              }
            });

            const pauseIcon = document.querySelector(".pause__icon");

            if (!isLoggedIn && !isSubscribed) {
              buttonContainer.appendChild(LoginButton);
              buttonContainer.appendChild(SubsCribeButton);
              modalContent.appendChild(modalTitle);
              modalContent.appendChild(buttonContainer);
              modalContainer.appendChild(modalContent);
              if (pauseIcon) {
                pauseIcon.style.display = "none";
              }

              playerContainer?.appendChild(modalContainer);
            } else if (isUpgraded && !isSubscribed) {
              buttonContainer.appendChild(upgradeButton);
              modalContent.appendChild(modalTitle);
              modalContent.appendChild(buttonContainer);
              modalContainer.appendChild(modalContent);
              if (pauseIcon) {
                pauseIcon.style.display = "none";
              }

              playerContainer?.appendChild(modalContainer);
            } else {
              buttonContainer.appendChild(SubsCribeButton);
              modalContent.appendChild(modalTitle);
              modalContent.appendChild(buttonContainer);
              modalContainer.appendChild(modalContent);
              if (pauseIcon) {
                pauseIcon.style.display = "none";
              }

              playerContainer?.appendChild(modalContainer);
            }
          }
        }
      } else {
        return;
      }

      const callLoadWatchHistory = () => {
        if(session_id && isUserLoggedin === "true"){
          loadWatchHistory();
        }
      };

      if (buttons) {
        const setupDoubleClickEventListeners = (button) => {
          const handleButtonDoubleClick = (event) => {
            event.preventDefault();
            event.stopPropagation();
          };

          if (button) {
            button.addEventListener("dblclick", handleButtonDoubleClick);
            return () => {
              button.removeEventListener("dblclick", handleButtonDoubleClick);
            };
          }
        };

        if (forwardButton) {
          forwardButton.addEventListener("click", () => {
            setIsForwarding(true);
            callLoadWatchHistory();
          });
        }

        if (rewindButton) {
          rewindButton.addEventListener("click", () => {
            setIsForwarding(true);
            callLoadWatchHistory();
          });
        }
        const cleanUpForwardButton =
          setupDoubleClickEventListeners(forwardButton);
        const cleanUpRewindButton =
          setupDoubleClickEventListeners(rewindButton);
        const cleanUpPlayButton = setupDoubleClickEventListeners(playButton);
        return () => {
          if (forwardButton) {
            forwardButton.removeEventListener("click", () => {
              callLoadWatchHistory();
              // setIsForwarding(false)
            });
          }
          if (rewindButton) {
            rewindButton.removeEventListener("click", () => {
              callLoadWatchHistory();
              // setIsForwarding(false)
            });
          }
          cleanUpForwardButton && cleanUpForwardButton();
          cleanUpRewindButton && cleanUpRewindButton();
          cleanUpPlayButton && cleanUpPlayButton();
          if (progress && category === "live") {
            progress.removeEventListener("mousedown", () => {});
          }
        };
      }
    }, 300);
  }, [
    ref.current?.plyr,
    category, 
    seo_url,
    progressRef?.current?.currentTime,
    isTrailer,
    nextVideoDetails,
    videoDetails,
    isLoggedIn,
    isSubscribed,
    showNextButton,
    isUpNextAppear,
    videoEnded,
    showSkipIntro,
  ]);

  useEffect(() => {
    const player = ref.current?.plyr;
        // console.log("snnsnsn", player)

    if (player) {
      const adContainerId = "adContainer";
      let adContainer = document.getElementById(adContainerId);

      if (adContainer && !isAdPlaying && !adContainerRemoved) {
        adContainer.remove();
        setAdContainerRemoved(true);
      }

      if (!adContainer && !isSubscribed) {
        adContainer = document.createElement("div");
        adContainer.className = player?.config?.classNames?.ads;
        adContainer.id = adContainerId;
        adContainer.style.position = "absolute";
        adContainer.style.width = "100%";
        adContainer.style.height = "100%";
        adContainer.style.top = 0;
        adContainer.style.left = 0;
        adContainer.style.display = "flex";
        adContainer.style.justifyContent = "center";
        adContainer.style.alignItems = "center";

        player?.elements?.container.appendChild(adContainer);
      }

      const videoElement = player?.media;
      if (videoElement && adContainer && isHlsSupported) {
        const addCuePoints = () => {
          if (player && cuePoints.length > 0) {
            const seekElement =
              player && player?.elements && player?.elements?.progress;
            if (seekElement) {
              seekElement
                .querySelectorAll(`.${player?.config?.classNames?.cues}`)
                .forEach((cue) => cue.remove());

              cuePoints.forEach((cuePoint) => {
                if (
                  cuePoint !== 0 &&
                  cuePoint !== -1 &&
                  cuePoint < player.duration
                ) {
                  const cuePercentage = (100 / player.duration) * cuePoint;
                  const cue = document.createElement("span");
                  cue.className = player.config.classNames.cues;
                  cue.style.left = `${cuePercentage}%`;
                  seekElement.appendChild(cue);
                }
              });
            }
          }
        };

        const handleCanPlay = () => {
          addCuePoints();
        };

        const handlePlay = async (event) => {
          if (
            !isAdPlaying &&
            !preRollRef.current.preRollCalled &&
            adInitialized
          ) {
            try {
              await videoElement.play();
              loadAds(event);
              preRollRef.current.preRollCalled = true;
            } catch (error) {
              console.error("Playback failed: ", error);
              cancelAds();
            }
          } else if (isAdPlaying) {
            event.preventDefault();
            try {
              if (
                videoElement.readyState >= HTMLMediaElement.HAVE_ENOUGH_DATA
              ) {
                await videoElement.play();
                videoElement.pause();
              }
            } catch (error) {
              console.error("Playback failed: ", error);
            }
          } else {
            try {
              if (
                videoElement.readyState >= HTMLMediaElement.HAVE_ENOUGH_DATA
              ) {
                await videoElement.play();
              }
            } catch (error) {
              console.error("Playback failed: ", error);
            }
          }
        };

        if (!adInitialized) {
          let isProcessingAd = false;

          const handleProgress = (event) => {
            if (
              !isAdPlaying &&
              cuePoints.length > 0 &&
              adConfig.midRollAdUrl &&
              !isSeeking
            ) {
              const currentTime = Math.floor(event?.target?.currentTime);
              let updatedCuePoints = [...cuePoints];

              if (isSeeked) {
                const lastCuePoint = Math.max(
                  ...cuePoints.filter((cp) => cp <= currentTime)
                );

                if (
                  lastCuePoint &&
                  lastCuePoint > 0 &&
                  !processedAds.has(lastCuePoint)
                ) {
                  adQueue.push(lastCuePoint);
                  setProcessedAds((prevProcessedAds) =>
                    new Set(prevProcessedAds).add(lastCuePoint)
                  );
                  const index = cuePoints.indexOf(lastCuePoint);
                  updatedCuePoints.splice(index, 1);
                  skippedCuePoints.delete(lastCuePoint);
                  setIsSeeked(false);
                }
              } else {
                cuePoints.forEach((cuePoint, index) => {
                  if (
                    currentTime >= cuePoint &&
                    !processedAds.has(cuePoint) &&
                    !skippedCuePoints.has(cuePoint)
                  ) {
                    adQueue.push(cuePoint);
                    setProcessedAds((prevProcessedAds) =>
                      new Set(prevProcessedAds).add(cuePoint)
                    );
                    updatedCuePoints.splice(index, 1);
                    skippedCuePoints.delete(cuePoint);
                  }
                });
              }

              setCuePoints(updatedCuePoints);

              if (!isProcessingAd && adQueue.length > 0) {
                processNextAd();
              }
            }
          };

          const seekElement =
            player && player?.elements && player?.elements?.progress;

          const handleMouseDown = () => {
            if (seekElement) {
              setIsSeeked(true);
            }
          };

          const handleSeeking = (event) => {
            setIsSeeked(true);
            const targetTime = Math.floor(event.target.currentTime);
            if (cuePoints.length > 0) {
              cuePoints.forEach((cuePoint) => {
                if (targetTime > cuePoint) {
                  setSkippedCuePoints((prevSkippedCuePoints) =>
                    new Set(prevSkippedCuePoints).add(cuePoint)
                  );
                }
              });
            }
          };

          const processNextAd = () => {
            if (adQueue.length > 0 && !isAdPlaying) {
              const nextCuePoint = adQueue.shift();
              isProcessingAd = true;

              if (seekElement) {
                const cues = seekElement.querySelectorAll(
                  `.${player?.config?.classNames?.cues}`
                );
                cues.forEach((cue) => {
                  const cuePosition = parseFloat(cue.style.left);
                  if (
                    Math.abs(
                      cuePosition - (100 / player.duration) * nextCuePoint
                    ) < 0.1
                  ) {
                    cue.remove();
                  }
                });
              }

              setTimeout(() => {
                initializeAds(
                  videoElement,
                  adContainer,
                  adConfig.midRollAdUrl,
                  true,
                  isIOS
                )
                  .then(() => {
                    isProcessingAd = false;
                  })
                  .catch((error) => {
                    console.error("error: ", error);
                    isProcessingAd = false;
                  });
              }, 1500);
            }
          };

          videoElement.addEventListener("seeking", handleSeeking);
          videoElement.addEventListener("timeupdate", handleProgress);
          seekElement.addEventListener("mousedown", handleMouseDown);

          return () => {
            videoElement.removeEventListener("seeking", handleSeeking);
            videoElement.removeEventListener("timeupdate", handleProgress);
            seekElement.removeEventListener("mousedown", handleProgress);
          };
        }

        videoElement.addEventListener("canplay", handleCanPlay);
        videoElement.addEventListener("play", handlePlay);

        return () => {
          videoElement.removeEventListener("canplay", handleCanPlay);
          videoElement.removeEventListener("play", handlePlay);
        };
      }
    }
  }, [
    ref.current,
    preRollRef.current,
    isAdPlaying,
    cuePoints,
    adContainerRemoved,
    isSubscribed,
    isSeeking,
    isSeeked,
    adInitialized,
    isHlsSupported,
  ]);
  
  const adjustContentMargin = () => {
    const videoElement = document.getElementById('player');
    const content = document.getElementById('contentvideo');

    // Check if the screen width is within mobile range (e.g., max width 768px)
    if (window.innerWidth <= 768 && videoElement && content) {
      const videoHeight = videoElement.offsetHeight; // Adding extra 10px
      content.style.marginTop = `${videoHeight}px`;
    } else if (content) {
      // Reset margin if not mobile
      content.style.marginTop = '0px';
    }
  };

  useEffect(() => {
    // Run only if screen width is within mobile range
    if (window.innerWidth <= 768) {
      adjustContentMargin();
      window.addEventListener('resize', adjustContentMargin);
    }

    // Cleanup on component unmount
    return () => window.removeEventListener('resize', adjustContentMargin);
  }, []);

  const callback = useCallback(() => {
    const videoElement = ref.current?.plyr?.media;
    const adContainer = document.getElementById("adContainer");

    if (
      videoElement &&
      adContainer &&
      adConfig.preRollAdUrl !== "" &&
      adConfig.preRollAdUrl &&
      !preRollRef.current.preRollCalled &&
      isHlsSupported
    ) {
      initializeAds(
        videoElement,
        adContainer,
        adConfig.preRollAdUrl,
        false,
        isIOS
      )
        .then(() => {})
        .catch((error) => {
          console.error("error: ", error);
        });
    }
  }, [ref.current, preRollRef.current]);

  useGoogleIMA(callback);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsForwarding(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [isForwarding]);

  const handleKeyDown = (event) => {
    if (event.key === "c" || event.key === "C") {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  };

  useEffect(() => {
    if (getallDetails?.stream_info?.play_back_time !== "") {
      var splittedtime = getallDetails?.stream_info?.play_back_time?.split(":");
      const timeInSec =
        parseInt(splittedtime[0]) * 3600 +
        parseInt(splittedtime[1]) * 60 +
        parseInt(splittedtime[2]);
      if (timeInSec > 0) {
        setStartTime(timeInSec);
      } else {
        setStartTime(0);
      }
    } else {
      setStartTime(0);
    }
  }, [getallDetails?.stream_info?.play_back_time]);

  // console.log("startTimess", startTime)
  // console.log("durationnn", progress.duration)

  useEffect(() => {
    if (!ref.current?.plyr || !ref.current?.plyr?.elements) return;
    // if (!ref.current?.plyr) return;
    setTimeout(() => {
      const player = ref && ref?.current && ref?.current?.plyr;
      // console.log("plyrSource: ", plyrSource);

      if (player) {
        const videoElement =
          ref &&
          ref?.current &&
          ref?.current?.plyr &&
          ref?.current?.plyr?.media;

        const handlePlay = (event) => {
          pausedRef.current = false;
          setPaused(false);

          if (
            (category === "live" || category === "livetv") &&
            paused
          ) {
            videoElement.currentTime = event?.target?.duration
          }
        };

        const handleProgress = (event, t) => {
          setProgress({
            currentTime: event?.target?.currentTime,
            duration: event?.target?.duration,
          });

          if (
            currentVideo.details?.intro_start_time_sec > 0 &&
            Math.floor(event?.target?.currentTime) >=
              currentVideo.details?.intro_start_time_sec &&
            currentVideo.details?.intro_end_time_sec > 0 &&
            Math.floor(event?.target?.currentTime) <=
              currentVideo.details?.intro_end_time_sec - 1
          ) {
            setShowSkipIntro(true);
          } else {
            setShowSkipIntro(false);
          }

          if (
            currentVideo.details?.end_credits_start_time_sec > 0 &&
            Math.floor(event?.target?.currentTime) >=
              currentVideo.details?.end_credits_start_time_sec &&
              nextVideoDetails?.end_credits_start_time
          ) {
            setShowNextButton(true);
            setIsUpNextAppear(true);
          }  else {
            setShowNextButton(false);
            setIsUpNextAppear(false);
          }
        };

        const handleVideoEnded = () => {
          setVideoEnded(true);
          setPaused(true);
          setStartTime(0);
          setWatchedDuration(0);
          triggeredPercentages.current.percent10 = false;
          triggeredPercentages.current.percent25 = false;
          triggeredPercentages.current.percent50 = false;
          triggeredPercentages.current.percent75 = false;
          triggeredPercentages.current.percent90 = false;
          if (
            nextVideo?.details !== null &&
            nextVideo?.source !== null &&
            !isTrailer
          ) {
            navigation(nextVideo?.details?.seo_url);
            preRollRef.current.preRollCalled = false;
            window.location.reload();
            setCurrentVideo({
              source: nextVideo?.source,
              details: nextVideo?.details,
            });
          }
        };

        if (videoElement) {
          // videoElement.addEventListener("loadedmetadata", () => {
          //   const currentTime = currentPlayedTime / 1000;
        
          //   // Ensure currentTime is finite and valid
          //   if (Number.isFinite(currentTime) && currentTime >= 0) {
          //     videoElement.currentTime = currentTime;
          //   } else {
          //     console.warn("Invalid currentTime value:", currentTime);
          //   }
          // });

          videoElement.addEventListener("loadedmetadata", () => {
            // Check if currentPlayedTime is defined and valid
            if (typeof currentPlayedTime === 'number' && !isNaN(currentPlayedTime) && currentPlayedTime >= 0) {
              const currentTime = currentPlayedTime / 1000;
        
              // Double-check if currentTime is finite and valid
              if (Number.isFinite(currentTime) && currentTime >= 0) {
                videoElement.currentTime = currentTime;
              } else {
                console.warn("Invalid currentTime value:", currentTime);
              }
            } else {
              console.warn("Invalid or undefined currentPlayedTime:", currentPlayedTime);
            }
          });

          // console.log("currentTimess",  currentVideo.source?.stream_info?.play_back_time)
          // console.log("videoElement",  videoElement)

          videoElement.addEventListener("ended", handleVideoEnded);
          videoElement.addEventListener("timeupdate", handleProgress);
          videoElement.addEventListener("pause", () => {
            setPaused(true);
            pausedRef.current = true;
          });
          videoElement.addEventListener("waiting", () => {
            setLoading(true);
          });
          videoElement.addEventListener("stalled", () => {
            setLoading(true);
          });
          videoElement.addEventListener("canplay", () => {
            setLoading(false);
           
          });

          videoElement.addEventListener("play", handlePlay);

          videoElement.addEventListener("playing", () => {
            setLoading(false);
            setPaused(false);
          });


          videoElement.addEventListener("seeking", () => {
            setIsSeeking(true);
          });

          videoElement.addEventListener("seeked", () => {
            setTimeout(() => {
              setIsSeeking(false);
            }, 1000);
          });

          videoElement.addEventListener("ratechange", () => {
            setPaused(true);
          });

          videoElement.addEventListener("qualitychange", () => {
            setPaused(true);
          });

          document.addEventListener("keydown", handleKeyDown);

          return () => {
            videoElement.removeEventListener("ended", handleVideoEnded);
            videoElement.removeEventListener("timeupdate", handleProgress);
            document.removeEventListener("keydown", handleKeyDown);
            videoElement.removeEventListener("play", handlePlay);
            videoElement.removeEventListener("pause", () => {});
            videoElement.removeEventListener("seeking", () => {});
            videoElement.removeEventListener("seeked", () => {});
            videoElement.removeEventListener("playing", () => {});
            videoElement.removeEventListener("waiting", () => {});
            videoElement.addEventListener("stalled", () => {
            });
            videoElement.addEventListener("canplay", () => {
            });
            videoElement.removeEventListener("qualitychange", () => {
              setPaused(false);
            });
            videoElement.removeEventListener("ratechange", () => {
              setPaused(false);
            });
          };
        }
      }
    }, 300);
  }, [ref.current?.plyr, videoEnded, paused]);

  // const { category, seo_url } = useParams();

  useEffect(() => {
    if (category !== "live" && progress && progress?.duration > 0) {
      const duration = Math.floor(progress && progress?.duration - startTime);
      const percent10 = Math.floor(duration * 0.1);
      const percent25 = Math.floor(duration * 0.25);
      const percent50 = Math.floor(duration * 0.5);
      const percent75 = Math.floor(duration * 0.75);
      const percent90 = Math.floor(duration * 0.9);

      if (
        percent10 > 0 &&
        watchedDuration === percent10 &&
        !triggeredPercentages.current.percent10
      ) {
        console.log("percent10: ", percent10);
        triggeranalytics("pb_10", percent10, 16);
        triggeredPercentages.current.percent10 = true;
      } else if (
        percent25 > 0 &&
        watchedDuration === percent25 &&
        !triggeredPercentages.current.percent25
      ) {
        console.log("percent25: ", percent25);
        triggeranalytics("pb_25", percent25, 17);
        triggeredPercentages.current.percent25 = true;
      } else if (
        percent50 > 0 &&
        watchedDuration === percent50 &&
        !triggeredPercentages.current.percent50
      ) {
        console.log("percent50: ", percent50);
        triggeranalytics("pb_50", percent50, 18);
        triggeredPercentages.current.percent50 = true;
      } else if (
        percent75 > 0 &&
        watchedDuration === percent75 &&
        !triggeredPercentages.current.percent75
      ) {
        console.log("percent75: ", percent75);
        triggeranalytics("pb_75", percent75, 19);
        triggeredPercentages.current.percent75 = true;
      } else if (
        percent90 > 0 &&
        watchedDuration === percent90 &&
        !triggeredPercentages.current.percent90
      ) {
        console.log("percent90: ", percent90);
        triggeranalytics("pb_90", percent90, 20);
        triggeredPercentages.current.percent90 = true;
      }
    }
  }, [progress?.duration, progress?.currentTime, category, videoEnded]);


  const isSafariBrowser = () => {
    const ua = navigator.userAgent;
    return /Safari/.test(ua) && !/Chrome/.test(ua);
  };
  
  const resetLoadingState = () => {
    if (loading) {
      // setControlsEnabled(false);
      setTimeout(() => setLoading(false), 450);
  
      // setLoading(false);
    }
  };
  
  useEffect(() => {
    if (isSafariBrowser()) {
      resetLoadingState();
    }
  }, [category, seo_url, loading]);

  // useEffect(() => {
  //   if (paused) {
  //     setPreviousWatchTime(prevTime => prevTime + progress.currentTime);
  //   }
  // }, [paused]);

  // console.log("getallDetails", getallDetails);
  function convertToSeconds(timeString) {
    if (!timeString) {
      return 0; // or handle the undefined case as needed
    }

    const timeParts = timeString.split(":");

    if (timeParts.length !== 3) {
      return 0; // or handle invalid time format as needed
    }

    const hours = parseInt(timeParts[0], 10) || 0;
    const minutes = parseInt(timeParts[1], 10) || 0;
    const seconds = parseInt(timeParts[2], 10) || 0;

    return hours * 3600 + minutes * 60 + seconds;
  }

  const WatchedTime = getallDetails?.stream_info?.play_back_time;
  const WatchedTimeInSeconds = convertToSeconds(WatchedTime);
  // console.log("getallDetailssdsd", WatchedTimeInSeconds);

  // console.log("videoSource", videoSource?.stream_info);

  //   useEffect(() => {
  // console.log("videoUrl", currentVideo.source?.stream_info?.adaptive_url)
  //     if (!currentVideo.source?.stream_info?.adaptive_url) {
  //       raptorRef.current.disablePictureInPicture = true;
  //     }
  //   }, [category]);

  const handleClosePip = () => {
    if (document.pictureInPictureElement) {
      document
        .exitPictureInPicture()
        .then()
        .catch((error) => {
          console.error("Failed to exit Picture-in-Picture mode:", error);
        });
    }
  };

  useEffect(() => {
    const player = document.querySelector(".plyr");
    if (player) {
      return () => {
        player.remove();
        handleClosePip();
      };
    }
  }, []);


  // const [dolbyAtmosSupported, setDolbyAtmosSupported] = useState(false);

  // useEffect(() => {
  //   // Check for Dolby Digital Plus (E-AC-3) support
  //   const audio = document.createElement('audio');
  //   const isDolbySupported = audio.canPlayType('audio/mp4; codecs="ec-3"') !== '';

  //   // Log the detection result to the console
  //   console.log("Dolby Atmos Support Detected:", isDolbySupported);

  //   // Set state based on detection result
  //   setDolbyAtmosSupported(isDolbySupported);
  // }, []);
  

  return (
    <>
     <div style={{ position: "relative" }}>
      <video
        ref={raptorRef}
        className="plyr-react plyr"
        crossOrigin="anonymous"
        playsInline={true}
        id="player"
        style={{ width: "100%"}}
      />
      {loading && (
        <div
          className="text-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1050,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Spinner animation="border" variant="danger" />
        </div>
      )}
    </div>
    </>
  );
});

// const checkDolbyAtmosSupport = () => {
//   const audio = document.createElement("audio");
//   const eac3Support = audio.canPlayType('audio/mp4; codecs="ec-3"') !== "";
//   const ac4Support = audio.canPlayType('audio/mp4; codecs="ac-4"') !== "";

//   if (eac3Support || ac4Support) {
//     console.log("Dolby Atmos is supported in this browser!");
//   } else {
//     console.log("Dolby Atmos is not supported in this browser.");
//   }
// };


const CustomVideoPlayer = ({ videoSource }) => {
  const { videodetailsitems } = useSelector((state) => state.video_details);
  const { category, seo_url } = useParams();

  const videoRef = useRef(null);

  // useEffect(() => {
  //   checkDolbyAtmosSupport();
  // }, []);
  return (
    <div className="sticky-container">
      <div className="sticky-video">
      <CustomPlyrInstance
          ref={videoRef}
          source={videoSource}
          options={videoOptions}
          videoDetails={videodetailsitems}
        />
      </div>
      {category === "live" && seo_url === "etv-andhra-pradesh-1" ? (
        <div>
          <LiveStream videoSource={videoSource} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomVideoPlayer;
