
import axios from 'axios';
import { toast } from 'react-toastify';

export const fetchData = async (apiUrl, setInnerCatalogListData,setNetworkError, setData, catalogListData, setCatalogListData, setLoading, toast) => {
  try {
    setLoading(true);
    const response = await axios.get(apiUrl);
    const responsedata = response?.data?.data;

    if (response?.status === 200) {
      setNetworkError(false)
      setLoading(false);
      const flattenedData = responsedata?.catalog_list_items.map(
        (item) => item.catalog_list_items || []
      );

      // window.sdk.trackEvent("page_view", { page: "home" });

      // let updatedData = [ catalogListData , [1,2,3] ]

      // console.log('api response updatedData', updatedData)

  

      setInnerCatalogListData(flattenedData);
      // setData(responsedata);
      // setCatalogListData(responsedata?.catalog_list_items || []);
      return {catlogData:   responsedata?.catalog_list_items || [] , homePageData : responsedata } 
    }
  } catch (error) {
    console.error("Error fetching data:kkkk", error.message);
    if(error.message === 'Network Error'){
      setNetworkError(true)
    }
    // if(error.response.status == 422){
    // }

  }
};





export const fetchGetData = async (url, setLoading) => {
  setLoading(true); 
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      setLoading(false); 
      return response.data;
    } else {
      setLoading(false); 
      throw new Error(`Received unexpected status code: ${response.status}`);
    }
  } catch (error) {
    // setLoading(false); 
    console.error('Error fetching data:', error);
    throw error;
  }finally{
    setLoading(false); 
  }
};



export const postData = async (url, postData, setSuccess, setError) => {
  try {
    const response = await axios.post(url, postData, {
      headers: { 'Content-Type': 'application/json' },
    });
    // console.log("coupon code data", response.data)
    setSuccess(response.data.data?.message)
    return response.data;
  } catch (error) {
    if(error?.response?.status === 422){
      setError(error?.response?.data?.error?.message)
    }
    console.error('Error posting data:', error);
    throw error; 
  }
};