// export const BASE_URL = "https://preprodapi.etvwin.com/";
// export const BASE_URL = 'http://3.6.224.174/'
// export const BASE_URL = 'https://stagingott.etvwin.com/'

// export let BASE_URL;

// if (window.location.hostname === 'etwinseo.colormoon.in' || window.location.hostname === 'stagingweb.etvwin.com' || window.location.hostname === 'stagingweb.etvwin.com'  || window.location.hostname === 'staging.etvwin.com:3000' || window.location.hostname === 'staging.etvwin.com') {
//   BASE_URL = process.env.REACT_APP_STAGING_URL;
// } else if (window.location.hostname === 'prodweb.etvwin.com') {
//   BASE_URL = process.env.REACT_APP_PREPROD_URL;
// } else if (window.location.hostname === 'etvwin.com' || window.location.hostname === 'www.etvwin.com') {
//   BASE_URL = process.env.REACT_APP_PROD_URL;
// } else if (window.location.hostname === 'localhost') {
//   BASE_URL = process.env.REACT_APP_STAGING_URL;
// }
// console.log("sjjsjjj", process.env.REACT_APP_BASE_URL)
// if (
//   window.location.hostname === "etvwin.com" ||
//   window.location.hostname === "www.etvwin.com"
// ) {
//   BASE_URL = process.env.REACT_APP_BASE_URL;
// } else if (
//   window.location.hostname === "prodweb.etvwin.com" 
// ) {
//   BASE_URL = "https://preprodapi.etvwin.com/";
// } else if (
//   window.location.hostname === "etvwinseo.colormoon.in" ||
//   window.location.hostname === "stagingweb.etvwin.com" ||
//   window.location.hostname === "stagingweb.etvwin.com" ||
//   window.location.hostname === "staging.etvwin.com:3000" ||
//   window.location.hostname === "staging.etvwin.com"
// ) {
//   BASE_URL = "https://stagingott.etvwin.com/";
// } else {
//   BASE_URL = "http://preprodapi-748193122.ap-south-1.elb.amazonaws.com/";
// }


export const BASE_URL = process.env.REACT_APP_BASE_URL || "https://preprodapi.etvwin.com/";

// export const BASE_URL = process.env.WIN_APP_API_BASE_URL || 'https://preprodapi.etvwin.com/' || 'https://firetvapi.etvwin.com/';

export const VERIFY_EMAIL = (confirmationToken, authToken, accessToken) =>
  `${BASE_URL}users/verification/${confirmationToken}?auth_token=${authToken}&access_token=${accessToken}`;

export const USERS_ENDPOINT = BASE_URL + "users";
export const SIGN_IN_URL = BASE_URL + "users/sign_in";
export const EXTERNAL_SIGNIN_URL = BASE_URL + "users/external_auth/sign_in";
export const FORGOT_PASSWORD_URL = BASE_URL + "users/forgot_password";
export const GET_ALL_DETAILS = BASE_URL + "v2/users/get_all_details";
// export const GET_ALL_DETAILS ='https://prod.api.etvwin.com/v2/users/get_all_details'

export const RESEND_VERIFICATION_URL =
  BASE_URL + "users/resend_verification_link";
export const FEEDBACK_URL = BASE_URL + "users/feedback";

export const RESET_PASSWORD_URL = BASE_URL + "users/reset_password";
export const OTP_VERIFICATION_URL = BASE_URL + "users/verify_otp";
export const SIGNUP_OTP_URL = BASE_URL + "users/signup_otp";
export const SIGNIN_OTP_URL = BASE_URL + "users/generate_signin_otp";

export const MOBILE_NUMBER_UPDATE_URL = BASE_URL + "users/generate_mobile_otp";
export const GENERATE_SESSION_URL = BASE_URL + "generate_session_tv";
export const GENERATE_TOKEN_URL = BASE_URL + "generate_token";
export const external_signin_auth_token = "VJ8WEaqygbpYSMzBtsGz";
let auth_token = process.env.REACT_APP_AUTH_TOKEN_WEB || "q5u8JMWTd2698ncg7q4Q"; // Default for web
let getSecretKey = process.env.REACT_APP_SECRET_KEY_WEB || "2fd66b173c16e012e90e";

// function isIOS() {
//   return /iPhone|iPad|iPod/i.test(navigator.userAgent);
// }

// function isAndroid() {
//   return /Android/i.test(navigator.userAgent);
// }


function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent) && !window.MSStream;
}

function isAndroidNative() {
  // Look for a custom native Android app identifier in the user agent
  return /Android/i.test(navigator.userAgent) && /NativeAppIdentifier/i.test(navigator.userAgent);
}

if (isIOS()) {
  auth_token = process.env.REACT_APP_AUTH_TOKEN_IOS || "VJ8WEaqygbpYSMzBtsGz"; // ios token
  getSecretKey = process.env.REACT_APP_SECRET_KEY_IOS || "c839a9abd2dbb3772bfa"
} else if (isAndroidNative()) {
  auth_token = process.env.REACT_APP_AUTH_TOKEN_ANDROID || "xttqeMn2dYtthp8aaUr2"; // Android token
  getSecretKey = process.env.REACT_APP_SECRET_KEY_ANDROID || "zhzYoPFgziyYCjSBGFiU"
} else {
  auth_token = process.env.REACT_APP_AUTH_TOKEN_WEB || "q5u8JMWTd2698ncg7q4Q"; // Web token
  getSecretKey = process.env.REACT_APP_SECRET_KEY_WEB || "2fd66b173c16e012e90e"
}


// console.log(auth_token);


export const access_token = process.env.REACT_APP_ACCESS_TOKEN || "Ay6KCkajdBzztJ4bptpW";
export const getSessionIdFromLocalStorage = () => {
  return localStorage.getItem("session_id");
};

export const sessionID = localStorage.getItem("session_id");

export const acceptedMediaTypes = [
  "video_songs",
  "video",
  "episode",
  "latest_episode",
  "best_scenes",
  "promo",
  "mini_clips",
];

// console.log("sessionID from profile", sessionID)

export const SIGN_OUT_URL = `${USERS_ENDPOINT}/${sessionID}/sign_out`;

export const page = 0;
export const page_size = 5;
export const npage_size = 10;

// https://stagingott.etvwin.com/catalog_lists/home.gzip?item_language=eng&region=IN&auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW&page=0&page_size=4&npage_size=10

export const HOME_PAGE_DETAILS = `${BASE_URL}catalog_lists/${sessionID}/playlists/watchhistory/listitems.gzip?&auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=IN`;
export const CONTINUE_WATCHING_DETAILS = `${BASE_URL}users/${sessionID}/playlists/watchhistory/listitems.gzip?&auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=IN`;
export const SUBSCRIPTION_PAGE_DETAILS = `${BASE_URL}catalogs/subscription/items.gzip?item_language=eng&region=IN&auth_token=${auth_token}&access_token=${access_token}`;
export const ABOUT_US_PAGE = `${BASE_URL}config/static_page/about_us?auth_token=${auth_token}&access_token=${access_token}`;
export const CHANNELS_PAGE_DETAILS = `${BASE_URL}catalog_lists/subscription/channels.gzip?item_language=eng&region=IN&auth_token=${auth_token}&access_token=${access_token}`;
export const PROFILE_DETAILS = `${BASE_URL}users/${sessionID}/account.gzip?auth_token=${auth_token}&access_token=${access_token}`;
export const TOP_MENU = `${BASE_URL}catalog_lists/top-menu-web.gzip?nested_list_items=false&auth_token=${auth_token}&access_token=${access_token}`;
export const PROFILE_INFO = `${BASE_URL}users/${sessionID}/account.gzip?auth_token=${auth_token}&access_token=${access_token}`;
export const SHOWS_DETAILS = `${BASE_URL}catalogs/shows/items/jabardasth.gzip?item_language=eng&region=IN&auth_token=${auth_token}&access_token=${access_token}`;
export const PREFERENCES_DETAILS = `${BASE_URL}catalog_lists/preferences.gzip?region=IN&auth_token=${auth_token}&access_token=${access_token}`;
export const PREFERENCES_UPDATE_DETAILS = `${BASE_URL}users/${sessionID}/playlists/favourite/list.gzip?region=IN`;
export const ALL_RECIPES_DETAILS = `${BASE_URL}catalogs/recipes/genres.gzip?region=IN&auth_token=${auth_token}&access_token=${access_token}`;
export const RECIPES_SELECTED = `${BASE_URL}catalogs/recipes/items.gzip?item_language=eng&region=IN&auth_token=${auth_token}&access_token=${access_token}&genres=Healthy_Food&page_size=500&sub_genres=non_veg,veg`;
export const CONTINUE_WATCHING = `${BASE_URL}users/${sessionID}/playlists/watchhistory/listitems.gzip?&auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=IN`;
export const SEARCH_DETAILS = `${BASE_URL}search?q=tv&page_size=24&from=0&start_count=0&page=0&item_language=eng&region=IN&auth_token=${auth_token}&access_token=${access_token}`;
export const ABOUT_US = `${BASE_URL}/config/static_page/about_us?auth_token=${auth_token}&access_token=${access_token}`;
export const TERMS_CONDITONS = `${BASE_URL}/config/static_page/terms_conditions?auth_token=${auth_token}&access_token=${access_token}`;
export const PLAN_DETAILS_API = `${BASE_URL}/catalogs/subscription/items.gzip?auth_token=${auth_token}&access_token=${access_token}&region=IN`;
// export const getSecretKey = "2fd66b173c16e012e90e";
export const subscription_plas_api = `${BASE_URL}catalogs/subscription/items.gzip?region=IN&auth_token=${auth_token}&access_token=${access_token}`;
export const appconfigparamapi = `${BASE_URL}/catalogs/message/items/app-config-params.gzip?region=IN&auth_token=${auth_token}&access_token=${access_token}&current_version=1.1`;
export const AUtoDetectURL = `${BASE_URL}/regions/autodetect/ip.gzip?auth_token=${auth_token}&access_token=${access_token}`;
export const userregionapi = `${BASE_URL}regions/autodetect/ip.gzip?auth_token=${auth_token}&access_token=${access_token}`;

//https://stagingott.etvwin.com/users/3df8d60790912d923a96160d0f223fc5/account.gzip?auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW
//export const getUpgradePlansDetails_api= `${BASE_URL}users/${sessionID}/user_plans/upgrade_plan?region=IN&auth_token=${auth_token}&access_token=${access_token}&sub_theme_id=${subThemeId}&to_plan=${toPlan}&upgrade_plan=${selectUpgrade}`;

//                url: `${appConstants.baseUrl}/users/${session}/user_plans/upgrade_plan?region=${topmenuService.regionData.country_code2}&auth_token=${appConstants.authToken}${topmenuService.accessTokenParam}&sub_theme_id=${subThemeId}&to_plan=${toPlan}&upgrade_plan=${selectUpgrade}`

//https://prod.api.etvwin.com/catalogs/subscription/items.gzip?region=IN&auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW
// export const VIDEO_DETAILS_URL = `${BASE_URL}/catalogs/telugu-movies/items/how-is-that-for-a-monday?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=IN`

// export const TELUGU_MOVIES_DETAILS_URL = ` ${BASE_URL}/catalogs/telugu-movies/items/${SeoUrl}?auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW&item_language=eng&region=IN`

// https://prod.api.etvwin.com/catalog_lists/preferences.gzip?region=IN&auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW

// export const getHomePageDetailsURL = ({ auth_token, access_token, page, page_size, npage_size }) => {
//   return `${BASE_URL}catalog_lists/home.gzip?item_language=eng&region=IN&auth_token=${auth_token}&access_token=${access_token}&page=${page}&page_size=${page_size}&npage_size=${npage_size}`;
// };

export { auth_token, getSecretKey };
