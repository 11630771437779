import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  couponCodedata: {},
}

export const couponcodeSlice = createSlice({
  name: 'couponcoded',
  initialState,
  reducers: {
    setCouponCodeData: (state, action) => {
      state.couponCodedata = action.payload.couponCodedata
    },
  },
})

export const { setCouponCodeData } = couponcodeSlice.actions

export default couponcodeSlice.reducer