import React, { useRef, useState } from "react";
import "../pages/Homepage/ImageSlider";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
  FaPlay,
  FaPlus,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import { BASE_URL, auth_token } from "../services/config";
import { toast } from "react-toastify";
// import useRegionData from "../../Componets/regions/regions";
import { useEffect } from "react";
import { SkeletonLoadSlider } from "../pages/skeletonLoad/skeletonthumbnail";
import { setModalOpen } from "../Redux/Slice/loginModalSlice";
import { FaVolumeHigh, FaVolumeXmark } from "react-icons/fa6";
import Slider from "react-slick";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { guestuserid } from "../App";
import { setThroughSeach } from "../Redux/Slice/throughSearchSlice";
import { setMainTraynamedata } from "../Redux/Slice/navlinkcallSlice";

function CommonBannerSlider({
  catalogListData,
  homePageData,
  loading,
}) {

  // console.log("homePageDatasjj", homePageData?.display_title)
  const loginStatus = localStorage.getItem("login_status")

  const topbanneritems = catalogListData[0]?.display_title
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  useEffect(() => {
   localStorage.setItem("selectedItem", topbanneritems)
  }, [catalogListData])

  // console.log("homePageDataskks", catalogListData)

  const notitleimage = "/images/no-image-1.png";

  const SampleNextArrow = (props) => {
    const { className, onClick } = props
    return (
        <div 
            className={className}
            // className={`arrow ${className}`}
            onClick={onClick}
            style={{ cursor: "pointer" }} 
        >
          <GoChevronRight  />
  
            {/* <FaChevronRight className='arrows' style={{ fontWeight: 100, background: 'transparent' }} /> */}
        </div>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props
    return (
        <div 
            className={className}
            // className={`arrow ${className}`}
            onClick={onClick}
            style={{ cursor: "pointer" }} 
        >
           <GoChevronLeft />
            {/* <FaChevronLeft className='arrows' style={{ fontWeight: 100, background: 'transparent'}} /> */}
        </div>
    );
}



  const [activeSlide, setActiveSlide] = useState(0);

  const sliderRef = useRef();
  // console.log("livePageData:", livePageData); 
  // console.log("newsPageData:", newsPageData); 
  // console.log("agriculturePageData:", agriculturePageData); 

  const regionsData = useSelector((state) => state.region.regionsData);
  // console.log("nsnsnsnsn", regionsData?.country_name)
  const getUserId = localStorage.getItem("user_id");

  const dispatch = useDispatch();
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991); 
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { sessionId } = useSelector((state) => state.auth);

  const Max_Limit = 5;
  // const { region, regiondetails } = useRegionData();

  const isUserLoggedIn = localStorage.getItem("login_status");
  const { likeData } = useSelector((state) => state.like_dislike_details);
  // console.log("likeDataaaa", likeData)
  const [hovered, setHovered] = useState(false);
  const [mylistaddresponse, setMylistaddesponse] = useState({});
  const session_id = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;
  const [addedtomylist, setAddedtomylist] = useState(false);
  const [addedinMobilelist, setAddedinMobilelist] = useState(false);
  const [foundInMyList, setFoundInMyList] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [noVideoUrl,setNoVideoUrl] = useState(false);
  const effectRan = useRef(false)
  
  const [mylistremoveresponse, setMylistremoveresponse] = useState({});
  const user_id = localStorage.getItem("user_id");
  const WatchLaterURL = `${BASE_URL}users/${session_id}/playlists/watchlater`;
  const storedDetailsDataJSON = localStorage.getItem("videodetailsdata");
  const [isSliderClicked, setIsSliderClicked] = useState(false);
  const [clickDelay, setClickDelay] = useState(null);

  const storedDetailsData = JSON.parse(storedDetailsDataJSON);
  const [myList, setMyList] = useState([]);
  const [currentIndex, setcurrentIndex] = useState(0);

  const [previewImg, setPreviewImg] = useState("");
  const [previewVideo, setPreviewVideo] = useState("");

  const [initialBannerImageLimit, setInitialBannerImageLimit] = useState(0);
  const [maxBannerImageLimit, setMaxBannerImageLimit] = useState(5);
  const [watchLaterData, setWatchLaterData] = useState([]);
  const [listItemIdForDelete, setListItemIdforDelete] = useState("");
  const [isMuted, setIsMuted] = useState(true); 
const playerRef = useRef(null); 

const handleVolumeToggle = () => {
  setIsMuted(prevState => !prevState); 
};

  // console.log("mylistdata", storedDetailsData);

  // const handleMyListAdd = async (event, catalog_id, content_id) => {
  //   event.preventDefault();
  //   try {
  //     const response = await axios.post(
  //       WatchLaterURL,
  //       {
  //         auth_token: auth_token,
  //         access_token: access_token,
  //         user_id,
  //         list_type: "watchlater",
  //         listitem: {
  //           catalog_id: catalog_id,
  //           content_id: content_id,
  //         },
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (response?.status === 200) {
  //       window.sdk.trackEvent('watch_later', {
  //         content_type: storedDetailsData.media_type,
  //         genre: storedDetailsData.genres,
  //         video_name: storedDetailsData.title,
  //         video_language: storedDetailsData.language,
  //        event_id: 7,
  //       });
  //       setAddedtomylist(true);
  //       toast.success("Added to my list");
  //       const mylistc = response?.data?.data;
  //       setMylistaddesponse(mylistc[0]);
  //       console.log("Watch Later data", mylistc[0]);
  //     }
  //   } catch (err) {
  //     console.error("Server responded with an error:", err.response.data);
  //   }
  // };

  const getMyListURL = `${BASE_URL}users/${session_id}/playlists/watchlater/listitems?auth_token=${auth_token}&access_token=${getaccessToken?.access_token}&region=${regionsData?.country_code2}`;

  useEffect(() => {
    if(effectRan.current === false){
      if(session_id){
        fetchGetMyListData();
      }
      return () => {
        effectRan.current = true
      }
    }
  }, [session_id]);

  const fetchGetMyListData = async () => {
    try {
      const response = await axios.get(getMyListURL);
      if (response.status === 200) {
        // setMywatchlistdata(response?.data?.data);
        // setCatalogListData(response?.data?.data?.items);
        // const listitemid = response?.data?.data?.items.map(
        //   (item) => item.listitem_id
        // );
        // const listitemtitle = response?.data?.data?.items.map(
        //   (item) => item.title
        // );
        // setListTitle(listitemtitle);
        // setlistitemid(listitemid);
        setWatchLaterData(response?.data?.data.items);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };

  const handleMyListAdd = async (event, catalog_id, content_id, index, list) => {
    // console.log("listssss", list)
    if (isUserLoggedIn === "false") {
      dispatch(setModalOpen());
    } else {
      try {
        const response = await axios.post(
          WatchLaterURL,
          {
            auth_token: auth_token,
            access_token: getaccessToken?.access_token,
            user_id,
            list_type: "watchlater",
            listitem: {
              catalog_id: catalog_id,
              content_id: content_id,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.data
        ) {
          toast.success("Added to my list", {
            className: "custom-toast",
            // style: {
            //   backgroundColor: "#28a745", 
            //   color: "white", 
            // },
          });
          if (window && window.sdk && window.sdk.trackEvent) {
            window.sdk.trackEvent("watch_later", {
              content_type: list?.media_type,
              genre: list?.genres?.join(', '),
              video_name: list?.title,
              video_language: list?.language,
              event_id: 7,
              platform: "web",
              u_id:
                  loginStatus === "true"
                  ? getUserId
                  : guestuserid,
              country: regionsData?.country_name,
              event_time: new Date().toISOString().slice(0, 19),
            });
          } else {
            console.error('Analytics SDK is not initialized yet.');
            // Handle initialization state or queue events for later
          }
         
          fetchGetMyListData();
          const mylistc = response?.data?.data;
          setMylistaddesponse(mylistc[0]);
          setMyList([...myList, index]);
        }
      } catch (err) {
        console.error("Server responded with an error:", err?.response?.data);
      }
    }
  };

  // console.log("listItemIdForDelete", listItemIdForDelete)

  const WatchLaterRemoveURL = `${BASE_URL}users/${session_id}/playlists/watchlater/listitems/${listItemIdForDelete}?auth_token=${auth_token}&access_token=${getaccessToken?.access_token}&region=${regionsData?.country_code2}`;

  const handleMyListDelete = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.delete(WatchLaterRemoveURL);
      if (response?.status === 200) {
        toast.error("Removed from my list", {
          // className: "custom-toast",
          // style: {
          //   backgroundColor: "#e80e16 ", 
          //   color: "white", 
          // },
        });
        fetchGetMyListData();
        // console.log("remove mylist data", response?.data);
        setMylistremoveresponse(response?.data);
      }
    } catch (err) {
      console.error("Server responded with an error:", err.response.data);
    }
  };

  const handleMyListDeleteitems = async (event, index, listItemIdForDelete) => {
    event.preventDefault();
    // console.log(`Deleting item with index: ${index}, listItemIdForDelete: ${listItemIdForDelete}`);
    
    const WatchLaterRemoveURL = `${BASE_URL}users/${session_id}/playlists/watchlater/listitems/${listItemIdForDelete}?auth_token=${auth_token}&access_token=${getaccessToken?.access_token}&region=${regionsData?.country_code2}`;
  
    try {
      const response = await axios.delete(WatchLaterRemoveURL);
      if (response?.status === 200) {
        toast.error("Removed from my list", {
          // className: "custom-toast",
          // style: {
          //   backgroundColor: "#e80e16 ", 
          //   color: "white", 
          // },
        });
        // console.log(`Successfully deleted item with index: ${index}, listItemIdForDelete: ${listItemIdForDelete}`);
        fetchGetMyListData();
        setMylistremoveresponse(response?.data);
        setMyList(myList.filter(i => i !== index));
      }
    } catch (err) {
      console.error("Server responded with an error:", err.response.data);
    }
  };
  
  

  useEffect(() => {
    const foundInMyList = watchLaterData.map(item => item.content_id);
    const myListIndexes = bannerContentIDList.map((contentId, index) => {
      if (foundInMyList.includes(contentId)) {
        return index;
      }
      return null;
    }).filter(index => index !== null);
  
    setMyList(myListIndexes);
  }, [watchLaterData]);

  const handleMouseEnter = () => {
    if (!hovered) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (hovered) {
      setHovered(false);
    }
  };

  // console.log("livePageData", livePageData);

  // const bannerList =  homePageData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "top_banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // console.log("gsgsgs", moviePageData.catalog_list_items)
  const getBannerList = (data) => {
    // console.log("datasss", data)
    return data?.catalog_list_items
      ?.map((item, index) =>
        ((item?.web_layout_type ?? item?.layout_type) === "top_banner" || (item?.web_layout_type ?? item?.layout_type) === "banner") &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean) 
      .flat();
  };

  const getBannerListDisplayTitle = (data) => {
    return data?.catalog_list_items
    ?.map((item, index) =>
      ((item?.web_layout_type ?? item?.layout_type)) &&
      item
    ).filter(Boolean) 
      .flat();
    
  };

  const getMoviesBannerList = (data) => {
    return data?.catalog_list_items
      ?.map((item, index) =>
        item?.web_layout_type === "banner" &&
        item.layout_structure === "horizontal" &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean)
      .flat();
  };

  const getNewsbannerList = (data) => {
    return data?.catalog_list_items
      ?.map((item, index) =>
        item?.web_layout_type === "news_slider" &&
        item.layout_structure === "horizontal" &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean)
      .flat();
  };

  const getFoodbannerList = (data) => {
    return data?.catalog_list_items
      ?.map((item, index) =>
        item?.web_layout_type === "recipe" &&
        item.layout_structure === "horizontal" &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean)
      .flat();
  };

  const getHealthbannerList = (data) => {
    return data?.catalog_list_items
      ?.map((item, index) =>
        item?.web_layout_type === "episode" &&
        item.layout_structure === "horizontal" &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean)
      .flat();
  };

  const getAgriculturebannerList = (data) => {
    return data?.catalog_list_items
      ?.map((item, index) =>
        item?.web_layout_type === "videos" &&
        item.layout_structure === "horizontal" &&
        item.catalog_list_items
          ? item.catalog_list_items.map((initem, inindex) => initem)
          : null
      )
      .filter(Boolean)
      .flat();
  };

  //  const LtvList = livePageData.layout_type ===  "live" &&
  //  livePageData.layout_structure === "vertical" && livePageData?.catalog_list_items ? livePageData?.catalog_list_items
  //     ?.map((item, index) => item) : null;

  const getLiveTVbannerList = (data) => {
    return data?.catalog_list_items
      ? data?.catalog_list_items.map((item, index) => item)
      : null;
  };

  const bannerList = getBannerList(homePageData);
  const bannerDisplayTitlesList = getBannerListDisplayTitle(homePageData);


  // const bannerDescriptionList = (
  //   bannerList || 
  //   []
  // )
  //   .filter((item) => item && item.description)
  //   .map((item) => item.description)
  //   .slice(initialBannerImageLimit, maxBannerImageLimit);

  const bannerDescriptionList = (
    bannerList || 
    []
  )
    .filter((item) => item && item.description)
    .map((item) => {
      const truncatedDescription = item.description.substring(0, 200);
      return truncatedDescription.length < item.description.length ? `${truncatedDescription}...` : truncatedDescription;
    })
// Function to check if the browser is Safari
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const bannerURLList = (
  bannerList || []
)
  .filter((item) => item && item.seo_url && item.title)
  .map((item, index, array) => {
    if (isSafari) {
      // For Safari, each item navigates to its specific seo_url
      return item.seo_url;
    } else {
      // For other browsers, apply the prefix-based condition
      const prefix = item.title.slice(0, 3); // Take the first two letters and space
      const matchingItem = array.find(
        (entry) =>
          entry !== item &&
          entry.title.startsWith(prefix) &&
          !entry.seo_url.includes("dolby")
      );
      // If there's a matching item without "dolby" in the seo_url, use its seo_url
      return matchingItem ? matchingItem.seo_url : item.seo_url;
    }
  });

// console.log("Updated Banner URL List:", bannerURLList);

    
    // console.log("Updated Banner URL List:", bannerURLList);
    
      const bannerTitlesList = (
        bannerList || 
        []
      )
        .filter((item) => item && item.title)
        .map((item) => item.title)
  // console.log("bannerTitlesList", bannerTitlesList);
  
    //.slice(initialBannerImageLimit, maxBannerImageLimit);

    // const mobilebannerURLList = (
    //   bannerList ||
    //   []
    // )
    //   .filter((item) => item && item.seo_url)
    //   .map((item) => item.seo_url)



      // Function to check if the browser is Safari

const mobilebannerURLList = (
  bannerList || []
)
  .filter((item) => item && item.seo_url && item.title)
  .map((item, index, array) => {
    if (isSafari) {
      // For Safari, each item navigates to its specific seo_url
      return item.seo_url;
    } else {
      // For other browsers, apply the prefix-based condition
      const prefix = item.title.slice(0, 3); // Take the first two letters and space
      const matchingItem = array.find(
        (entry) =>
          entry !== item &&
          entry.title.startsWith(prefix) &&
          !entry.seo_url.includes("dolby")
      );
      // If there's a matching item without "dolby" in the seo_url, use its seo_url
      return matchingItem ? matchingItem.seo_url : item.seo_url;
    }
  });

// console.log("ssjjsjsj:", bannerURLList);


      const bannerListInfo = (
        bannerList || 
        []
      )
        .map((item) => item)

  const bannerCatalogList = (
    bannerList || 
    []
  )
    .filter((item) => item && item.catalog_id)
    .map((item) => item.catalog_id)
    //.slice(0, Max_Limit);

    const mobilebannerCatalogList = (
      bannerList || 
      []
    )
      .filter((item) => item && item.catalog_id)
      .map((item) => item.catalog_id)

  const bannerContentIDList = (
    bannerList || 
    []
  )
    .filter((item) => item && item.content_id)
    .map((item) => item.content_id)

    const bannerInfoList = (
      bannerList || 
      []
    )
      .filter((item) => item)
      .map((item) => item)
    //.slice(initialBannerImageLimit, maxBannerImageLimit);


    const mobilebannerContentIDList = (
      bannerList || 
      []
    )
      .filter((item) => item && item.content_id)
      .map((item) => item.content_id)


  const bannerPremiumBadgeList = (
    bannerList || 
    []
  )
    .map((item) => item?.access_control?.is_free)
   // .slice(0, Max_Limit);


    const bannerMobilePremiumBadgeList = (
      bannerList || 
      []
    )
      .map((item) => item?.access_control?.is_free)

  const bannerVideoBadgeList = (
    bannerList || 
    []
  )
    .map((item) => item?.media_type)
   // .slice(0, Max_Limit);

  // console.log("bannerVideoBadgeList", bannerVideoBadgeList)

  // const bannerImages = (
  //   bannerList || 
  //   moviesbannerList || 
  //   liveTVbannerList || 
  //   newsbannersList ||
  //   foodbannersList ||
  //   healthbannersList ||
  //   []
  // )
  //   .filter(
  //     (item) =>
  //       item &&
  //       (item.thumbnails.web_banner
  //         ? item.thumbnails.web_banner.url
  //         : item.thumbnails.high_4_3.url)
  //   )
  //   .map((item) => item.thumbnails.web_banner.url)
  //   .slice(0, Max_Limit);

   const bannerImages = (
    bannerList || 
    []
  )
  .map((item) =>
      item?.thumbnails?.web_tvbanner_image?.url ? item?.thumbnails?.web_tvbanner_image?.url : item?.thumbnails?.high_16_9?.url
  )

  // console.log("jsjsjsj", bannerImages[selectedImage])
    // .filter(
    //   (item) =>
    //     item &&
    //     (item.thumbnails.web_tvbanner_image && item.thumbnails.high_16_9.url !== null
    //       ? item.thumbnails.web_tvbanner_image.url
    //       : item.thumbnails.medium_16_9.url)
    // )
    // .map((item) =>
    //   item.thumbnails.web_tvbanner_image && item.thumbnails.high_16_9.url !== null
    //     ? item.thumbnails.web_tvbanner_image.url
    //     : item.thumbnails.medium_16_9.url
    // )
   // .slice(initialBannerImageLimit, maxBannerImageLimit);

   const bannerMobileViewImages = (bannerList || []).map(item => {
    const mobileBannerUrl = item.thumbnails?.mobile_banner_image?.url;
    const alternativeUrl = item.thumbnails?.medium_3_4?.url;
  
    if (mobileBannerUrl && mobileBannerUrl.includes('default-images')) {
      if (alternativeUrl) {
        return alternativeUrl;
      }
      return mobileBannerUrl;
    }
  
    if (mobileBannerUrl) {
      return mobileBannerUrl;
    }
  
    return alternativeUrl || mobileBannerUrl; 
  });
  

  
  // console.log("bannerMobileViewImages", bannerMobileViewImages)


  const bannerVidList = (
    bannerList || 
    []
  )
    // .filter(
    //   (item) =>
    //     item && item.preview_adaptive_url !== "" && item.preview_adaptive_url
    // )
    .map((item) => item.preview_url_en)



    function decodeUrl(encodedUrl) {
      if (!encodedUrl || typeof encodedUrl !== "string") {
        return null; // Return null for null or non-string values
      }
    
      const parts = encodedUrl.split(".");
      if (parts.length !== 2) {
        // console.error("Invalid encoded URL format:", encodedUrl);
        return null;
      }
    
      try {
        const urlPart = atob(parts[0]);
        const keyPart = atob(parts[1]);
        const secretKey = "2fd66b173c16e012e90e";
    
        if (keyPart !== secretKey) {
          // console.error("Invalid secret key for URL:", encodedUrl);
          return null;
        }
    
        return urlPart;
      } catch (error) {
        // console.error("Error decoding URL:", encodedUrl, error.message);
        return null;
      }
    }
  
  const bannerVideosList = bannerVidList.map(encodedUrl => {
    try {
      // Decode the encoded URL
      return decodeUrl(encodedUrl);
    } catch (error) {
      console.error("Error:", error.message);
      console.error("Encoded URL:", encodedUrl);
      // Return null for invalid URLs
      return null;
    }
  });

  // console.log("sssnsnns", bannerVidList)

  // Function to check if the URL is playable
  async function isPlayableUrl(url) {
    if (!url) return false;
  
    try {
      const response = await fetch(url, { method: "HEAD" });
      return response.ok; // URL is accessible if status is 200-299
    } catch (error) {
      console.error("Error checking URL:", url, error.message);
      return false; // URL is not playable
    }
  }
  

  async function getPlayableBannerVideos(bannerVidList) {
    const playableVideos = await Promise.all(
      bannerVidList.map(async (encodedUrl) => {
        // Retain empty strings as they are
        if (encodedUrl === "") return "";
  
        try {
          const url = decodeUrl(encodedUrl);
          if (!url) return null; // Skip invalid or undecodable URLs
  
          const isPlayable = await isPlayableUrl(url);
          return isPlayable ? url : null; // Include playable URLs only
        } catch (error) {
          // console.error("Error processing URL:", encodedUrl, error.message);
          return null;
        }
      })
    );
  
    // Return array with playable URLs and empty strings, excluding nulls
    return playableVideos.map((url, index) => (url === null ? "" : url));
  }

// // Usage
// let playableVideosArray = []; // Define the array to store playable videos
// getPlayableBannerVideos(bannerVidList).then((playableList) => {
//   playableVideosArray = playableList; // Assign the result to the array
//   console.log("Playable Videos Array:", playableVideosArray);
// });

// Usage
let playableVideosArray = []; // Define the array to store playable videos

getPlayableBannerVideos(bannerVidList).then((playableList) => {
  playableVideosArray = playableList;
  // console.log("Playable Videos Array:", playableVideosArray);
});

  
  // console.log("bannerVideosList:", bannerVideosList);
  

  const bannerImagesList = (
    bannerList || 
    []
  )
    .filter((item) => item && item?.thumbnails?.medium_4_3?.url)
    .map((item) => item?.thumbnails?.medium_4_3?.url)
   
  const bannerTitleImagesList = (
    bannerList ||
    []
  )
    // .filter((item) => item && item.title_image_display && item.title_image)
    .map((item) => item.title_image_display === true ? item.title_image : item?.thumbnails?.medium_4_3?.url)
   // .slice(initialBannerImageLimit, maxBannerImageLimit);


  //  console.log("bannerTitleImagesListssjs", bannerTitleImagesList)
// console.log("bannerList", bannerList)
const bannerMobileTitleImagesList = (
    bannerList ||
    []
  )
    // .filter((item) => item && item.title_image)
    .map((item) =>  item.title_image_display === true ? item.title_image : item?.thumbnails?.medium_4_3?.url)




  const trendingSequenceList = (
    bannerList || 
    []
  )
    // .filter((item) => item && item.trending_sequence)
    .map((item) => item.trending_sequence)
   // .slice(0, Max_Limit);

    const mobiletrendingSequenceList = (
      bannerList ||
      []
    )
      // .filter((item) => item && item.trending_sequence)
      .map((item) => item.trending_sequence)

  const genresList = (
    bannerList || 
    []
  )
    .map((item) => item.display_genres)
   // .slice(0, Max_Limit);

    const mobilegenresList = (
      bannerList || 
      []
    )
      // .filter((item) => item && item.display_genres)
      .map((item) => item.display_genres)


  const mediatypeList = (
    bannerList || 
    []
  )
    .filter((item) => item && item.theme)
    .map((item) => item.theme)
    .slice(0, Max_Limit);

    const MobilemediatypeList = (
      bannerList || 
      []
    )
      .filter((item) => item && item.theme)
      .map((item) => item.theme)

  // const livetvbannerList = livePageData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // const foodbannerList = tvShowsData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // const healthbannerList = healthPageData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // const agriculturebannerList = agriculturePageData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // const newsbannerList = NewsPageData?.catalog_list_items
  //   ?.map((item, index) =>
  //     item?.web_layout_type === "banner" &&
  //     item.layout_structure === "horizontal" &&
  //     item.catalog_list_items
  //       ? item.catalog_list_items.map((initem, inindex) => initem)
  //       : null
  //   )
  //   .filter(Boolean)
  //   .flat();

  // console.log('genresList', genresList);

  const [selectedImage, setSelectedImage] = useState(0);
  // const [videoSrc, setVideoSrc] = useState("");
  const [videoSrc, setVideoSrc] = useState(playableVideosArray[0]);
  // console.log("videoSrc", videoSrc)

  const listItemId = bannerContentIDList[currentIndex];

  const findInWatchLaterList = () => {
    let foundItem = watchLaterData.find(
      (item) => item.content_id === listItemId
    );
    
    let foundItemBoolean = !!foundItem;
  //  console.log("foundItem", foundItem)
    setFoundInMyList(foundItem)
    if (foundItemBoolean) {

      setAddedtomylist(true);
      setAddedinMobilelist(true)
      setListItemIdforDelete(foundItem.listitem_id);
    } else {
      setAddedtomylist(false);
      setListItemIdforDelete("");
      foundItem = "";
    }
  };

  useEffect(() => {
    findInWatchLaterList();

  }, [watchLaterData, listItemId]);

  // console.log("addedinMobilelist", addedinMobilelist)
  const handleVideoEnd = (index) => {
    const nextVideoIndex = index + 1 < bannerVideosList.length ? index + 1 : 0; // Loop back to start
  
    // Check if it's the last video and move to the first slide if needed
    if (index === bannerVideosList.length - 1) {
      // Last video has ended
      setSelectedImage(0); // Reset to the first image
      slidelogo(0); // Move slider to the start
    } else {
      // Move to the next banner
      setSelectedImage(nextVideoIndex);
  
      
      if (nextVideoIndex > 3) {
        slidelogo(nextVideoIndex); // Update slider for next set of images
      }
    }
  
    // Start the next banner or loop back
    handleLogoClick(nextVideoIndex);
  };
  
  
 
// const handleLogoClick = (index) => {
//   console.log(1);
//   setcurrentIndex(index);
//   setSelectedImage(index);
//   setShowVideo(false); // Reset to show image
//   setNoVideoUrl(false);
 
//   if (noVideoUrl === false) {
//     const nextIndex = index + 1 < bannerVideosList.length ? index + 1 : 0;

//     setTimeout(() => {
//       // Add a delay before clicking the next logo
//       console.log("Timeout completed");
//       if (bannerVideosList[index]) {
//         console.log("2");
//         videoplayindex(index);
//       } else {
//         console.log("3");
//         handleLogoClick(nextIndex);
//         if (index > 3) {
//           slidelogo(nextIndex);
//         }
//       }
//     }, 4000); // Adjust the delay as needed
//   }

// };

const handleLogoClick = (index) => {
  setcurrentIndex(index);
  setSelectedImage(index);
  setShowVideo(false); // Reset to show the image initially
  setNoVideoUrl(false);
  setIsMuted(true);
  // Clear any existing timer
  clearTimeout(timerRef.current);

  // Start the timer immediately
  startTimer(index);
};

// console.log("sdsdsdsd", bannerImages[selectedImage])


function getPageName() {
  const path = window.location.pathname;
  const parts = path.split('/'); 
  return parts[1] ?? "home"; 
}
const param1Value = getPageName();
const sourcetab = localStorage.getItem("selectedMenuItem");

// console.log("sourcetabsjjs", sourcetab)
// console.log("selectedBannerDetailssjsj", bannerList)
const handleBannerClick = () => {
  const selectedBannerDetails = bannerList[selectedImage];
  const selectedBannerCatalogDetails = bannerURLList[selectedImage];
  // console.log("jzzsjsjs", selectedBannerCatalogDetails)
  const selectedBannerTitlesDetails = bannerDisplayTitlesList[selectedImage];
  localStorage.setItem("traynamedata", selectedBannerTitlesDetails?.display_title)
  dispatch(setThroughSeach({ throughSeach: false }));
  dispatch(setMainTraynamedata({ maintraynamedata:  catalogListData[0]}));
  // console.log("sourcetabsss", selectedBannerDetails)
  // console.log("topbanneritemssdd", catalogListData[0])
  
  if (window && window.sdk && window.sdk.trackEvent) {
    window.sdk.trackEvent("artwork_tapped", {
      source: homePageData?.display_title?.toLowerCase(),
      platform: "web",
      u_id:
          loginStatus === "true"
          ? getUserId
          : guestuserid,
      country: regionsData?.country_name,
      event_time: new Date().toISOString().slice(0, 19),
      video_name:selectedBannerDetails.title,
      tray_name: topbanneritems?.toLowerCase(),
      event_id: 21,
      content_type: selectedBannerDetails?.media_type || 'NA',
      position_within_tray: selectedImage + 1,
      artwork_type: selectedBannerDetails?.media_type || 'NA',
 });

  } else {
    console.error('Analytics SDK is not initialized yet.');
    // Handle initialization state or queue events for later
  }
};


const startTimer = (index) => {
  const nextIndex = index + 1 < bannerVideosList.length ? index + 1 : 0;

  // Clear existing timer
  clearTimeout(timerRef.current);

  // Set timeout for either image or video duration
  timerRef.current = setTimeout(() => {
    if (playableVideosArray[index]) {
      // If the banner has a video
      // setShowVideo(false); // Display the image while preloading video
      setVideoSrc(playableVideosArray[index]); // Set the video source

      setTimeout(() => {
        setShowVideo(true); // Show video
        videoplayindex(index); // Start video playback
      }, 200); // Slight delay for smooth transition
    } else {
      // If the banner does not have a video, show image and proceed to next
      handleLogoClick(nextIndex);

      if (nextIndex === 0) {
        slidelogo(0); // Reset the slider to the start
      } else if (nextIndex > 3) {
        slidelogo(nextIndex); // Update slider position for other indexes
      }
    }
  }, 4000); // 4 seconds for images without videos
};


async function checkURLValidity(index) {
  try {
      const response = await fetch(bannerVideosList[index], { method: 'HEAD' });
      if (response.ok) {
        console.error('Response OK. Status:', response);

          return true;
      } else {
          console.error('Response not OK. Status:', response.status);
          // Handle the case where the response is not OK (e.g., file not found)
          return false;
      }
  } catch (error) {
      console.error('Error checking URL validity:', error);
      // Handle other errors (e.g., network issues)
      return false;
  }
}
const videoplayindex = (index) =>{
  // console.log("4");
    setShowVideo(true); 
     
      setVideoSrc(playableVideosArray[index]);
      // console.log("playerRef",playerRef);

       setNoVideoUrl(true);  
      //  if (playerRef.current) {
      //   playerRef.current.playing = true;
      //   setShowVideo(true); 
      // }
}


// Use useRef to maintain the timer reference
const timerRef = useRef(null);
  useEffect(() => {
    // console.log("isSliderClicked changed: ", isSliderClicked);
  }, [isSliderClicked]);
  const handlePrevious = () => {
    setSelectedImage((prevImage) =>
      prevImage > 0 ? prevImage - 1 : bannerVideosList.length - 1
    );
  };
  useEffect(() => {
    let lastScrollTop = 0;
  
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        setIsMuted(true); // Scroll down, mute the video
      } else if (currentScrollTop < lastScrollTop) {
       // setIsMuted(false); // Scroll up, unmute the video
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  const handleNext = () => {
    setSelectedImage((prevImage) =>
      prevImage < bannerVideosList.length - 1 ? prevImage + 1 : 0
    );
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowVideo(true);
  //     setVideoSrc(bannerVideosList[0])
  //   }, 6000);

  // }, []);

  useEffect(() => {
    setIsSliderClicked(false);
    //handleLogoClick(currentIndex);

    const elementIdToClick = currentIndex;

    // Get the DOM element by id
    const elementToClick = document.getElementById(elementIdToClick);

    // Check if the element exists before attempting to click
    if (elementToClick) {
      // Simulate a click event
      elementToClick.click();
    }
  }, [selectedImage, myList, videoSrc]);




  useEffect(() => {
    // Clear the timeout on component unmount or when another slider is clicked
    return () => {
      if (clickDelay) {
        clearTimeout(clickDelay);
      }
    };
  }, [clickDelay]);


  const playerConfig = {
    file: {
      forceHLS: true, // Force the use of HLS streaming
    },
  };

  const trendingSequence = trendingSequenceList[selectedImage];
  let number = '';
  if (trendingSequence) {
    const match = trendingSequence.match(/\d+/); 
    number = match ? match[0] : '';
  }
  let lastTwoWords = '';
  if (trendingSequence) {
    const wordsArray = trendingSequence.split(" "); 
    if (wordsArray.length >= 2) {
      lastTwoWords = wordsArray.slice(-2).join(" ");
    } else {
      lastTwoWords = trendingSequence;
    }
  }
const genres = genresList[selectedImage];
const mediatype = mediatypeList[selectedImage];
let genresElement;
if (genres && genres.length > 0) {
  genresElement = (
    <React.Fragment>
      {genres.map((genre, index) => (
        <li key={index}>{genre}</li>
      ))}
    </React.Fragment>
  );
} else {
  genresElement = null;
}

  let isSliding = false;

  let trendnum = '';
const settings = {
  dots: true,
  infinite: true,

    speed: 500,
    slidestoshow: 1,
    slidestoscroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const settingsweb = {
    dots: true,
    infinite: true,
    speed: 500,
    slidestoshow: 3,
    slidestoscroll: 1
  };

  const options = {
    items: 5,
    dots:false,
    startPosition: activeSlide

};
  const [currentLogoIndex, setLogoCurrentIndex] = useState(0);

  const slidelogo = (newIndex) => {
    if (newIndex < 0) {
      newIndex = bannerTitleImagesList.length - 1; // Wrap to the last index
    } else if (newIndex >= bannerTitleImagesList.length) {
      newIndex = 0; // Reset to the start if it exceeds the last index
    }
  
    // Highlight the current index position
    setLogoCurrentIndex(newIndex);
  
    // Check if the current item is the last in the row
    if (newIndex === bannerTitleImagesList.length - 1) {
      // console.log("Last item in row is showing:", newIndex);
  
      // Wait for the image to load completely
      const isVideo = playableVideosArray[newIndex]; // Check if it's a video
      const contentElement = document.querySelector(`#content-${newIndex}`); // Assume each item has a unique ID
      const contentDuration = isVideo ? 4000 : 2000; // Video duration or fallback for images
  
      // Ensure the image/video has appeared before starting the timer
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // console.log("Content fully visible, starting duration timer");
  
            // Start the timer only after the content is visible
            setTimeout(() => {
              // Move to the next row after content is done
              setLogoCurrentIndex(0); // Reset to the start
            }, contentDuration);
  
            observer.disconnect(); // Stop observing once we trigger the timer
          }
        },
        { threshold: 0.5 } // Adjust threshold as needed
      );
  
      if (contentElement) {
        observer.observe(contentElement);
      }
    }
  };
  
  
  
  const slide = () => {
    const maxIndex = Math.max(bannerTitleImagesList.length - 4, 0); // Last valid starting index for a full group of 4
    if (currentLogoIndex < maxIndex) {
      setLogoCurrentIndex(currentLogoIndex + 4);
    } else {
      setLogoCurrentIndex(maxIndex); // Stop exactly at the last set
    }
  };
  
  const slideleft = () => {
    if (currentLogoIndex > 0) {
      setLogoCurrentIndex(Math.max(currentLogoIndex - 4, 0));
    }
  };
  



  const isPrevDisabled = currentLogoIndex === 0;
const isNextDisabled = currentLogoIndex >= bannerTitleImagesList.length - 4;
  

  const handleSlideChange = (event) => {
    setActiveSlide(event.item.index);
  };

  return (
    <>
      {/* {JSON.stringify(bannerList)} */}
      {/* {bannerList  && } */}

      {bannerList?.length > 0  &&
      <div className="image-slider">
        <div className="main-image">
          {/* <img src={images[selectedImage]} alt="Main" /> */}
          {/* <video autoplay muted loop class="background-video">
                            <source src={images[selectedImage]} type="video/mp4"  />
                        </video> */}

          {/* <div className="previous-button" onClick={handlePrevious}>
            <FaChevronLeft onClick={handlePrevious} />
          </div>
          <div className="next-button" onClick={handlePrevious}>
            <FaChevronRight onClick={handleNext} />
          </div> */}

          <div className="">
            <div id="overlay"></div>

            {isMobile ? (
              <>
              
              <Slider
         {...settings}
  className="slider-one"
  beforeChange={() => {
    isSliding = true;
  }}
  afterChange={() => {
    isSliding = false;
  }}
>
{bannerMobileViewImages.map((banimg, index) => {
  let trendingSequence = mobiletrendingSequenceList[index]; 
  let numbers = '';
  let lastTwoWords = '';

  if (trendingSequence) {
    const match = trendingSequence.match(/\d+/);
    numbers = match ? match[0] : '';

    const wordsArray = trendingSequence.split(" ");
    if (wordsArray.length >= 2) {
      lastTwoWords = wordsArray.slice(-2).join(" ");
    } else {
      lastTwoWords = trendingSequence;
    }
  }

  const listItemIdForDelete = watchLaterData.find(item => item.content_id === bannerContentIDList[index])?.listitem_id || '';

  const genresData = (
    <>
      {mobilegenresList[index] && mobilegenresList[index].length > 0 ? (
        <React.Fragment>
          {mobilegenresList[index].map((genre, index) => (
            <li key={index}>{genre}</li>
          ))}
        </React.Fragment>
      ) : null}
    </>
  );


  return (
    <div key={`banner-${index}`}>

      <div className="MobileSliders" style={{
          backgroundImage: `url(${banimg})`
        }} >

        <div className="BannerItems">
          <div className="d-flex item">
            {numbers ? <div className="top-show">
              <div className="top-show-icon">
                  Top  <span>{numbers}</span>
                  </div>
              </div> : ''}
                
              <div className="styling-heading">
              <h4>{lastTwoWords}</h4>
            </div>
          </div>

          <div className="MobileTitleLogo">
              <img src={bannerMobileTitleImagesList[index]} width="" alt="" />
          </div>

          <div className="mvoie mb-2">
              <ul>
                {!bannerMobilePremiumBadgeList[index] ? (
                  <li className="paidMovie">
                     <div className="lock-postion-class-two"></div>
                  </li>
                ) : (
                  ""
                )}
                {/* <li> {MobilemediatypeList[index]} </li> */}
                <>{genresData}</>
              </ul>
          </div>

          <div className="watch-mobile-icons">
            <Row className="align-items-center mt-2">
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="watch-icon w-100">
                  <Link to={mobilebannerURLList[index].startsWith('/') ? mobilebannerURLList[index] : `/${mobilebannerURLList[index]}`} >
                    <FaPlay></FaPlay>
                    &nbsp; Watch Now
                  </Link>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="my-list w-100 d-flex">
                <div
  className="plus-buto"
  onClick={(event) => {
    // Prevent event bubbling if an icon is clicked
    if (event.target.closest('.me-1')) return;

    if (!myList.includes(index)) {
      if (isUserLoggedIn === "true") {
        handleMyListAdd(
          event,
          bannerCatalogList[index],
          bannerContentIDList[index],
          index,
          bannerInfoList[index]
        );
      } else {
        dispatch(setModalOpen());
      }
    } else if (isUserLoggedIn === "true") {
      handleMyListDeleteitems(event, index, listItemIdForDelete);
    } else {
      dispatch(setModalOpen());
    }
  }}
  style={{ cursor: 'pointer' }} // Optional: To show the cursor as clickable
>
  <span className="me-1">
    {!myList.includes(index) ? (
      <>
        {isUserLoggedIn === "true" ? (
          <FaPlus
            onClick={(event) => {
              event.stopPropagation(); // Prevent parent onClick when icon is clicked
              handleMyListAdd(
                event,
                bannerCatalogList[index],
                bannerContentIDList[index],
                index,
                bannerInfoList[index]
              );
            }}
          />
        ) : (
          <Link to="/" onClick={() => dispatch(setModalOpen())}>
            <FaPlus />
          </Link>
        )}
      </>
    ) : isUserLoggedIn === "true" ? (
      <FaCheck
        onClick={(event) => {
          event.stopPropagation(); // Prevent parent onClick when icon is clicked
          handleMyListDeleteitems(event, index, listItemIdForDelete);
        }}
      />
    ) : (
      <>
        <Link to="/">
          <FaPlus onClick={() => dispatch(setModalOpen())} />
        </Link>
      </>
    )}
  </span>
  <span>My List</span>
</div>


                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
})}


</Slider>

              </>
            ) : (
              <>
                <div
                  className="img-layer"
                  style={showVideo ? {} : { display: "none" }}
                >
                  {videoSrc !== "" && (
       <ReactPlayer
       key={`${videoSrc}-${currentIndex}`} // Ensure a unique key for every video
       ref={playerRef}
       url={videoSrc}
       className="yout"
       width="100%"
       height="100%"
       pip={true}
       controls={false}
       playing={true}
       muted={isMuted}
       onEnded={() => handleVideoEnd(currentIndex)}
       onReady={onLoadedData}
       playsinline={true}
     />
                  )}
                </div>
                
                <div
                  className="img-layer"
                  style={showVideo ? { display: "none" } : {}}
                >


                  <div
                    className="MainSliderBanner"
                    style={
                      {
                            backgroundImage: `url(${bannerImages[selectedImage]})`,
                          }
                    }
                  />

                  </div>
                </>
              )}
            </div>
          </div>
          <div className={`sound ${showVideo ? 'videoIcon' : 'imageIcon'}`}>
            <div className="soundMute" onClick={handleVolumeToggle}>
              {isMuted ? <FaVolumeXmark /> : <FaVolumeHigh />}
            </div>
          </div>

      {!isMobile && 
      <>

        <div className={`bannerDescriptionList ${showVideo ? 'videotitle' : 'imagetitle'}`}>
          {bannerTitleImagesList[selectedImage]?(<> <img src={bannerTitleImagesList[selectedImage]} width="180" alt="" />
</>):(<></>)}
         
          <div className="d-flex item">
            {number ? <div className="top-show">
              <div className="top-show-icon">
               Top <br /> {number}
              </div>
            </div> : ''}
            
            <div className="styling-heading">
              <h4>{lastTwoWords}</h4>
            </div>
          </div>
          <div className="mvoie mb-2">
            <ul>
              {/* <li><p>Movie</p></li>
        <li><p>Action</p></li>
        <li><p>Romantic</p></li>
        <li><p>U/A</p></li> */}

              
                {!bannerPremiumBadgeList[selectedImage] ? (
                  <li className="paidMovie">
                     <div className="lock-postion-class-two"></div>
                  </li>
                ) : (
                  ""
                )}
                {/* {(!bannerVideoBadgeList[selectedImage] === "video" || !bannerVideoBadgeList[selectedImage] === 'episode'|| !bannerVideoBadgeList[selectedImage] === 'latest_episode'|| !bannerVideoBadgeList[selectedImage] === 'best_scenes'|| !bannerVideoBadgeList[selectedImage] === 'promo' || !bannerVideoBadgeList[selectedImage] === 'mini_clips') ? <> <div className='iconcame '><FaPlay /></div><> : ""} */}
              

              {/* <li> <div className="lock-postion-class-two"></div></li> */}
              {/* {mediatypeList[selectedImage] && (
 <li> <p>{mediatypeList[selectedImage].toUpperCase()}</p></li>
)} */}
              <>{genresElement && (
  <p>{genresElement}</p>
)}    </>
            </ul>
           

            {/* {(inne?.media_type === 'video' || inne?.media_type === 'episode'|| inne?.media_type === 'latest_episode'|| inne?.media_type === 'best_scenes'|| inne?.media_type === 'promo' || inne?.media_type === 'mini_clips') ? <> <div className='iconcame '><FaPlay /></div><> : ""} */}
          </div>

          <div className="watchbitton">
            {/* deskicons start*/}
            <div className="watchbutton-content d-flex">
              <div className="play-buton">
              <Link
  onClick={handleBannerClick}
  to={
    bannerURLList[selectedImage]?.startsWith('/')
      ? bannerURLList[selectedImage]
      : `/${bannerURLList[selectedImage]}`
  }
>
  <FaPlay></FaPlay>
</Link>
              </div>

              <h4>Watch Now</h4>
              <div className="myListBtn">
              <div className="plus-buto">
                {/* <FaCheck /> */}
                  {!addedtomylist ? (
                    <>
                      {isUserLoggedIn === "true" ? ( 
                        <p onClick={(event) => {
                            handleMyListAdd(
                              event,
                              bannerCatalogList[selectedImage],
                              bannerContentIDList[selectedImage],
                              mobilebannerContentIDList[selectedImage],
                              bannerInfoList[selectedImage]
                            );
                            setMyList([...myList, selectedImage]);
                          }}>
                        <FaPlus />
                      </p> ) : (
                         <p onClick={() => dispatch(setModalOpen())}>
                          <FaPlus />
                        </p> )}
                      {/* <FaPlus onClick={(event) => handleMyListAdd(event, bannerCatalogList[selectedImage], bannerContentIDList[selectedImage])} /> */}
                    </>
                  ) : isUserLoggedIn === "true" ? ( <p onClick={handleMyListDelete}>
                    <FaCheck />
                    </p> ) : ( <p onClick={() => dispatch(setModalOpen())}>
                      <FaPlus />
                    </p>)}
              </div>
              <h4>My List</h4>
              </div>
            </div>
          </div>
          <div className="watch-mobile-icons">
            <Row className="align-items-center mt-2">
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="watch-icon w-100">
                  <Link to={bannerURLList[selectedImage]?.startsWith('/') ? bannerURLList[selectedImage] : `/${bannerURLList[selectedImage]}`}>
                    <FaPlay></FaPlay>
                    &nbsp; Watch Now
                  </Link>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="my-list w-100 d-flex">
                  <div className="plus-buto ">
                    {/* <FaCheck /> */}
                    <span className="me-1">
                      {!addedtomylist ? (
                        <>
                          {isUserLoggedIn === "true" ? (
                            <FaPlus
                              onClick={(event) => {
                                handleMyListAdd(
                                  event,
                                  bannerCatalogList[selectedImage],
                                  bannerContentIDList[selectedImage]
                                );
                              }}
                            />
                          ) : (
                            <Link
                              to="/"
                              onClick={() => dispatch(setModalOpen())}
                            >
                              <FaPlus />
                            </Link>
                          )}

                          {/* <FaPlus onClick={(event) => handleMyListAdd(event, bannerCatalogList[selectedImage], bannerContentIDList[selectedImage])} /> */}
                        </>
                      ) : isUserLoggedIn === "true" ? (
                        <FaCheck onClick={handleMyListDelete} />
                      ) : (
                        <>
                          <Link to="/">
                            <FaPlus  onClick={() => dispatch(setModalOpen())}/>
                          </Link>
                          My List
                        </>
                      )}
                    </span>
                    My List
                  </div>

                        {/* <Link to="/">

                  <FaCheck/> My List
                    
                  </Link> */}
                </div>
              </Col>
            </Row>
          </div>
          {/* mobileplayicons end*/}
          <div className="p-styleting-class">
            <p>{bannerDescriptionList[selectedImage]}</p>

                  {/* {(bannerVideoBadgeList[selectedImage] === "episode" || bannerVideoBadgeList[selectedImage] === 'movie') ? <> <div className='iconcame '><FaPlay /></div><> : ""} */}
                </div>
              </div>
              <div className="logo-slider">
  <div className="bottom-logo-slider">
    <div
      className="logo-slider-inner"
      style={{
        transform: `translateX(-${Math.min(currentLogoIndex * 20, (bannerTitleImagesList.length - 5) * 20)}%)`,
      }}
    >
      {bannerTitleImagesList.map((logo, index) => (
        <div key={index} className={`logo-item ${selectedImage === index ? 'active-line' : ''}`}>
          <img
            className={`logo ${selectedImage === index ? 'active' : ''}`}
            src={logo}
            id={index}
            alt=""
            onClick={() => handleLogoClick(index)}
          />
        </div>
      ))}
    </div>
  </div>
  <div className={`arrow arrow-left ${isPrevDisabled ? 'disabled' : ''}`} onClick={!isPrevDisabled ? slideleft : null}>
    <SamplePrevArrow />
  </div>
  <div className={`arrow arrow-right ${isNextDisabled ? 'disabled' : ''}`} onClick={!isNextDisabled ? slide : null}>
    <SampleNextArrow />
  </div>

  <div className="logo-slider">
    {bannerTitleImagesList.map((_, index) => (
      <span
        key={index}
        className={`dots ${selectedImage === index ? 'active' : ''}`}
        onClick={() => handleLogoClick(index)}
      ></span>
    ))}
  </div>
</div>


            </>
          }
        </div>
      }
    </>
  );
}


export default CommonBannerSlider;
