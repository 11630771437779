import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";

import crypto from "crypto-js";
import axios from "axios";
import { FaPlay } from "react-icons/fa";
import CustomVideoPlayer from "../../Componets/CustomVideoPlayer";
import {
  BASE_URL,
  auth_token,
  getSecretKey,
} from "../../services/config";
import { SkeletonVideoThumbnail } from "../skeletonLoad/skeletonthumbnail";
import { setModalOpen } from "../../Redux/Slice/loginModalSlice";
import WatchListFeature from "../videodetails/watchlistfeature";
import Likefeature from "../videodetails/likefeature";
import ShareFeature from "../videodetails/share";
import { setShowLogoutAllModal } from "../../Redux/Slice/logoutAllModalSlice";
import { setGetallDetails } from "../../Redux/Slice/getalldetailsSlice";
import { Helmet } from "react-helmet";
import GPTAd from "../videodetails/gptadd";
import { setSubscribeSource } from "../../Redux/Slice/subscriptionsource";
import { setAccountData } from "../../Redux/Slice/accountsDataSlice";
import { setAuth } from "../../Redux/Slice/authSlice";
import GPTAdTwo from "../videodetails/gptaddtwo";
import GPTAdFour from "../videodetails/gptadfour";
import AdSection from "../videodetails/gptadload";
import GPTAdSide from "../videodetails/gptaddside";
import GPTAdSideTwo from "../videodetails/gptaddsidet";

const HLSPlayerWithQualityControl = ({
  catalogid,
  contentid,
  loading,
  setShowThumbnail,
  showThumbnail,
  isdropdownContentSelected
}) => {

  // console.log("isdropdownContentSelectedss", isdropdownContentSelected)
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  const tv_shows_banner_image = "/images/tv_shows_banner.png";
  const mainBanner = "/images/mainbannerdefault.jpg";
  const addBanner = "/images/add-01.png";
  const { videodetailsitems } = useSelector((state) => state.video_details);
  const isLoginRequiredMand = false

  const { sessionId } = useSelector((state) => state.auth);
  let sessionID = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;

  // console.log("videodetailsitems", videodetailsitems)

  const {catlogId, contentId} = useSelector(state => state.shows)

// console.log("catlogIdsssss", catlogId)
// console.log("laststtstststst", videodetailsitems.last_episode?.catalog_id)
// console.log("cataloggggsggsgsg", videodetailsitems?.catalog_id)

  const selectedCatlog =
  videodetailsitems?.catalog_id ||  videodetailsitems.last_episode?.catalog_id;
  const selectedContent =
  videodetailsitems?.content_id || videodetailsitems.last_episode?.content_id;
  const videoThumbnail =
    videodetailsitems.last_episode?.thumbnails?.web_banner?.url ||
    videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.high_16_9?.url

  const regionsData = useSelector((state) => state.region.regionsData);
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const playerRef = useRef(null);
  const [likedContentid, setLikedContentid] = useState("");
  const [getPreviewWithoutLogin, setGetPreviewWithoutLogin] = useState({});
  const [showPreviewVideo, setShowPreviewVideo] = useState(true);
  const [layer, setLayer] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const effectRan = useRef(false);
  const isUserLoggedIn = localStorage.getItem("login_status");

  // useEffect(() => {
  //   if (!selectedContent) return;
  //   getPreviewVideosWithoutLogin();
  // }, [selectedCatlog, selectedContent]);

  // useEffect(() => {
  //   if (effectRan.current === false && selectedContent) {
  //     getPreviewVideosWithoutLogin();
  //     effectRan.current = true;
  //   }
  // }, [selectedCatlog, selectedContent]);


  useEffect(() => {
    if(effectRan.current === false || catalogid || contentid){
      getPreviewVideosWithoutLogin();
      return () => {
        effectRan.current = true
      }
    }
  }, [catalogid, contentid, sessionID]);



  // useEffect(() => {
  //   getPreviewVideosWithoutLogin();
    
  // }, [selectedCatlog, selectedContent, getSecretKey]);




  const handleShowLayer = () => {
    setLayer(true);
  };

  const handlePlay = () => {
    if (playerRef.current) {
      playerRef.current.play();
    }
  };

  const getPreviewVideosWithoutLogin = async () => {
    try {
      const timeStamp = JSON.stringify(Math.floor(new Date().getTime() / 1000.0));
      if (sessionID == null) {
        sessionID = "";
      }
  
      const md5val =
      catalogid + contentid + sessionID + timeStamp + getSecretKey;
      const md5Hash = crypto.MD5(md5val).toString();
      
      let payload = {
        auth_token: auth_token,
        access_token: getaccessToken?.access_token,
        catalog_id: catalogid,
        category: "",
        content_id: contentid,
        id: sessionID,
        region: regionsData?.country_code2,
        md5: md5Hash,
        ts: timeStamp,
      };
  
      let res = await axios.post(`${BASE_URL}v2/users/get_all_details`, payload, 

      //   {
      //   headers: {
      //    "Content-Type": "application/json",
      //    "User-Agent": navigator.userAgent,
      //           "Accept-Encoding": "gzip",
      //           "Content-Length":244,
      //   },
      // }
    );
      
      if (res.status === 200) {
        if(isUserLoggedIn === "true" && res.data.data.login_status === false){
          localStorage.removeItem("session_id");
          localStorage.clear()
          localStorage.setItem("login_status", false);
          localStorage.setItem("showSubscripeButton", true);
          dispatch(setAccountData({ accountData: {} }));
          dispatch(setAuth({ isLoggedIn: false, usersessionID: "" }));
        }
        setLayer(false);
        setShowPreviewVideo(false);
        setGetPreviewWithoutLogin(res.data.data);
        dispatch(setGetallDetails({ getallDetails: res.data.data }));
      } else {
        console.error("Error occurred with status:", res.status, res.statusText);
      }
    } catch (error) {
      console.error("An error occurred while fetching preview videos:", error);
    }
  };
  

  const {seasondropdownData} = useSelector(state => state.dropdown_data)

  // console.log("seasondropdownData", seasondropdownData)

  // useEffect(() => {
  //   if(seasondropdownData?.length > 0){
  //     setShowThumbnail(true);
  //   }else{
  //     setShowThumbnail(false);
  //   }
  //   // if(getPreviewWithoutLogin?.stream_info?.adaptive_url !== ''){
  //   //   setShowThumbnail(false);
  //   // }
  // }, [seasondropdownData, getPreviewWithoutLogin])


  // useEffect(() => {
  //   // if(seasondropdownData?.length < 0){
  //   //   setShowThumbnail(false);
  //   // }else{
  //   //   setShowThumbnail(true);
  //   // }
  //   if(getPreviewWithoutLogin?.stream_info?.adaptive_url !== ''){
  //     setShowThumbnail(false);
  //   }

  //   // console.log("seasondropdownData", seasondropdownData)
  // }, [seasondropdownData, getPreviewWithoutLogin])


  // useEffect(() => {
  //   // if(seasondropdownData?.length < 0){
  //   //   setShowThumbnail(false);
  //   // }else{
  //   //   setShowThumbnail(true);
  //   // }
  //   if(getPreviewWithoutLogin?.stream_info?.adaptive_url !== ''){
  //     setShowThumbnail(false);
  //   }
  // }, [seasondropdownData, getPreviewWithoutLogin])


  // console.log("hshshs",   getPreviewWithoutLogin?.stream_info)
  const { getallDetails } = useSelector((state) => state.getalldata || {});

const navigate = useNavigate()
  
  useEffect(() => {
    if((getallDetails?.login_status === false && isLoginRequiredMand === true) || isdropdownContentSelected === true){
      setShowThumbnail(true);
    }else{
      setShowThumbnail(false);
    }

  }, [getallDetails])

  // console.log("lskllslkslls", getallDetails)
  const handleShowVideo = () => {
    // setShowThumbnail(false);
    if(isLoginRequiredMand === true && getallDetails?.login_status === false){
      dispatch(setModalOpen())
    }else{
      setShowThumbnail(false)
    }
    setTimeout(() => {
      handlePlay();
    }, 200)  };

  const [imageLoadedArray, setImageLoadedArray] = useState(
    new Array(0).fill(false)
  );
  const handleImageLoad = (index) => {
    setImageLoadedArray((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);

const { loginDetails } = useSelector((state) => state?.signin_data || {});

// useEffect(() => {
// if(loginDetails?.email_id){
//  window.location.reload()
// }
// }, [loginDetails])

// console.log("loginDetails", loginDetails)

  let renderVideoPlayer = () => {
    if (showPreviewVideo) {
      const url = videodetailsitems?.share_url || 'ETVWin';
      const extractedUrl = url.replace(/^https?:\/\//, ''); 
      return (
        <>
          <Helmet>
    <meta charSet="utf-8" />

    <title>{videodetailsitems?.title || 'ETVWin'}</title>
    <meta charset="utf-8" />
     <meta http-equiv="X-UA-Compatible" content="IE=edge" />
     <meta name="csrf_token" content="" />
     <meta property="type" content="website" />
     <meta property="url" content="" />
     <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
     <meta name="msapplication-TileColor" content="#ffffff" />
     <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
     <meta name="theme-color" content="#ffffff" />
     <meta name="_token" content="" />
     <meta name="robots" content="noodp" />
     <meta property="title" content={videodetailsitems?.title} />
     <meta property="quote" content="" />
     <meta name="description" content={videodetailsitems?.description || 'Share Check out this video on ETVWin'} />
     <meta property="image" content={videodetailsitems?.thumbnails?.medium_4_3?.url || 'default-image-url'} />
     <meta property="og:locale" content="en_US" />
     <meta property="og:type" content="website" />
     <meta property="og:title" content={videodetailsitems?.title} />
     <meta property="og:quote" content="" />
     <meta property="og:hashtag" content="" />
     <meta property="og:image" content={videodetailsitems?.thumbnails?.medium_4_3?.url || 'default-image-url'} />
     <meta content="image/*" property="og:image:type" />
     <meta property="og:url" content={videodetailsitems?.web_share_url} />
     <meta property="og:site_name" content="CampersTribe" />
     <meta property="og:description" content={videodetailsitems?.description || 'Share Check out this video on ETVWin'}  />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@etvwin" />
        <meta name="twitter:creator" content="@etvwin" />
        <meta name="twitter:title" content={videodetailsitems?.title || 'ETVWin'} />
        <meta name="twitter:description" content={videodetailsitems?.description || 'Check out this video on ETVWin'} />
        <meta name="twitter:image" content={videodetailsitems?.thumbnails?.medium_4_3?.url || 'default-image-url'} />
        <meta name="twitter:url" content={videodetailsitems?.web_share_url} />
      </Helmet>
          <div className="">
            <div className="imgabove-layer">
              <div className="imgabove-layer-in">
                {(videodetailsitems?.last_episode?.thumbnails?.web_banner
                  ?.url || videodetailsitems?.thumbnails?.high_16_9?.url) ? (
                  <div className="circle-icon-two">
                    <Link
                      onClick={() =>
                        getPreviewVideosWithoutLogin(
                          selectedContent,
                          selectedCatlog
                        )
                      }
                    >
                      <FaPlay />
                    </Link>
                  </div>
                ) : null}
              </div>

              {!(videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.high_16_9?.url) ? <SkeletonVideoThumbnail height={430} /> : <img
                src={
                  !imageLoadedArray[0] ||
                  !(videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.high_16_9?.url)
                    ? mainBanner
                    : (videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.high_16_9?.url)
                }
                onLoad={() => handleImageLoad(0)}
                alt=""
                style={{ height: "", objectFit: "" }}
                // className="detailspage"
              />}

              {/* <img
                src={
                  !imageLoadedArray[0]
                    ? mainBanner
                    : imageLoadedArray[0]
                    ? isMobile ? videodetailsitems?.thumbnails?.medium_16_9?.url : videodetailsitems?.thumbnails?.web_banner?.url
                    : mainBanner
                }
                onLoad={() => handleImageLoad(0)}
                loading="lazy"
                style={{ height: "", objectFit: "" }}
                // className="detailspage"
                alt=""
              /> */}
            </div>
          </div>
        </>
      );
    } else if (
      (!getPreviewWithoutLogin?.login_status ||
        getPreviewWithoutLogin?.login_status) &&
      !getPreviewWithoutLogin?.is_subscribed &&
      (getPreviewWithoutLogin?.stream_info?.preview_available === true ||
        getPreviewWithoutLogin?.stream_info?.adaptive_url !== "")
    ) {

      // console.log("videodetailsitemsskskksks", videodetailsitems?.thumbnails)
      return (
        <div>
          <div></div>
          <div className="video-player-vm">
            {showThumbnail && videoThumbnail ? (
              <div className="image-containers image-container_thumnail">
                <div className="imgabove-layer">
                  {/* {!videoThumbnail ? (
                    <SkeletonVideoThumbnail height={430} />
                  ) : (
                    <>
                      <img
                        src={
                            videodetailsitems?.thumbnails?.web_banner?.url
                        }
                        onLoad={() => handleImageLoad(0)}
                        loading="lazy"
                        alt=""
                      />
                    </>
                  )} */}
{/* isMobile ?  */}

{!(videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.high_16_9?.url) ? (
  <SkeletonVideoThumbnail height={430} />
) : (
  <>
    <img
      src={
        !imageLoadedArray[0]
          ? mainBanner
          : isMobile
          ? videodetailsitems?.thumbnails?.high_16_9?.url || videodetailsitems?.thumbnails?.high_4_3?.url
          : videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.high_16_9?.url
      }
      onLoad={() => handleImageLoad(0)}
      alt="Video Banner"
      className= {`${!videodetailsitems?.thumbnails?.web_banner?.url ? "detailsbanner" : ""}`}
    />

    
  </>
)}



                  {!loading && (
                    <div className="showvideodetailsbelow">
                      <div className="horizontal-divider-container">
                        <div className="horizontal-divider"></div>
                      </div>

                      <Container fluid className="pading-left-right-class">
                        <Row>
                          <Col>
                            <div id="contentvideo" className="flex-container videop">
                              {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates}  />
                      <div className="divider"></div> */}

                              <div className="combination">
                                <div className="circle-icon">
                                  <Link>
                                    <FaPlay onClick={handleShowVideo} />
                                  </Link>
                                </div>
                                <div className="text">Watch Now</div>
                              </div>

                              {/* <WatchListFeature  />
                      <Likefeature likedContentid={likedContentid}  /> */}
                              <ShareFeature />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
              <div className="content-container">
                <Row>
                  <Col lg={8}>
                    <CustomVideoPlayer videoSource={getPreviewWithoutLogin} />
                    <div id="contentvideo" className="flex-container videop watchlistblock">
                      <WatchListFeature />
                      <Likefeature likedContentid={likedContentid} />
                      <ShareFeature />
                    </div>
                  </Col>
                  <Col lg={4} text-center>
                    <Link>
                      {/* <img
                        src={addBanner}
                        alt=""
                        className="w-100 add-banner"
                      /> */}
                       {/* <GPTAdTwo />
                       <GPTAdFour /> */}

                       <GPTAdSide />
                       <GPTAdSideTwo />
                    </Link>
                  </Col>
                </Row>
              </div>
              </>
            )}
          </div>
        </div>
      );
    } else if (
      !getPreviewWithoutLogin?.login_status &&
      !getPreviewWithoutLogin?.is_subscribed &&
      !getPreviewWithoutLogin?.stream_info?.preview_available
    ) {

      const handleSubscribe =  () => {
        dispatch(setSubscribeSource({
          subscribeSource: true,
        }))
  
        ; 
        if(getPreviewWithoutLogin?.login_status === true){
          navigate("/profile?tab=subscription");
        }else{
          navigate('/subscription-plans');
        }
        }
      return (
        <>
          {layer && <div className="layer-image"></div>}

          {layer && (
            <div className="banner-login-conte">
              <h6 className="text-center">Subscribe to watch this content</h6>

              <Row className="justify-content-center">
                <Col
                  lg={6}
                  md={6}
                  sm="auto"
                  xs="auto"
                  style={{ textAlign: "center" }}
                >
                  <Link
                    to=""
                    className="login-button"
                    onClick={() => dispatch(setModalOpen())}
                  >
                    {" "}
                    Login{" "}
                  </Link>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  sm="auto"
                  xs="auto"
                  style={{ textAlign: "center" }}
                >
                  <button onClick={handleSubscribe}  className="login-button">
                    Subscribe
                  </button>
                </Col>
              </Row>
            </div>
          )}

          <div className="image-containers">
            <div className="imgabove-layer">
            <img
                src={
                  !imageLoadedArray[0]
                    ? mainBanner
                    : isMobile
                    ? videodetailsitems?.thumbnails?.high_16_9?.url || mainBanner
                    : videodetailsitems?.thumbnails?.web_banner?.url ||
                      videodetailsitems?.thumbnails?.high_16_9?.url ||
                      videodetailsitems?.thumbnails?.medium_4_3?.url ||
                      mainBanner
                }

                onLoad={() => handleImageLoad(0)}
                loading="lazy"
                alt=""
              />
            </div>
          </div>

          {!loading && (
            <div className="showvideodetailsbelow">
              <div className="horizontal-divider-container">
                <div className="horizontal-divider"></div>
              </div>
              <Container fluid className="pading-left-right-class">
                <Row>
                  <Col>
                    <div id="contentvideo" className="flex-container videop">
                      {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates}  />
                      <div className="divider"></div> */}

                      <div className="combination">
                        <div className="imgabove-layer">
                          {(videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.high_16_9?.url) && (
                            <div className="circle-icon">
                              {(videodetailsitems?.thumbnails?.web_banner
                                ?.url || videodetailsitems?.thumbnails?.high_16_9?.url) && (
                                <Link to="" onClick={handleShowLayer}>
                                  <FaPlay />
                                </Link>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="text">Watch Now</div>
                      </div>

                      {/* <WatchListFeature  />
                      <Likefeature likedContentid={likedContentid}  /> */}
                      <ShareFeature />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </>
      );
    } else if (
      getPreviewWithoutLogin?.login_status &&
      !getPreviewWithoutLogin?.is_subscribed &&
      !getPreviewWithoutLogin?.stream_info?.preview_available
    ) {
      const handleSubscribe =  () => {
      dispatch(setSubscribeSource({
        subscribeSource: true,
      }))

      if(getPreviewWithoutLogin?.login_status === true){
        navigate("/profile?tab=subscription");
      }else{
        navigate('/subscription-plans');
      }
      }
      return (
        <>
          {layer && <div className="layer-image"></div>}

          {layer && (
            <div className="banner-login-conte">
              <h6 className="text-center">
                {getPreviewWithoutLogin?.stream_info?.upgrade_plan === true
                  ? "Please Upgrade your Account to Start Streaming"
                  : "Subscribe to watch this content"}
              </h6>

              <Row className="justify-content-center">
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ textAlign: "center" }}
                >
                  <button onClick={handleSubscribe}  className="login-button">
                  {getPreviewWithoutLogin?.stream_info?.upgrade_plan === true
                      ? "upgrade"
                      : "subscribe"}
                  </button>
                  {/* <Link onClick={handleSubscribe} to="/Subscription" className="login-button">
                    {getPreviewWithoutLogin?.stream_info?.upgrade_plan === true
                      ? "upgrade"
                      : "subscribe"}
                  </Link> */}
                </Col>
              </Row>
            </div>
          )}

          <div className="image-containers">
            <div className="imgabove-layer">
              <img
                src={
                  !imageLoadedArray[0]
                    ? mainBanner
                    : imageLoadedArray[0]
                    ? (videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.high_16_9?.url) ||
                      videodetailsitems?.thumbnails?.medium_4_3?.url
                    : mainBanner
                }
                onLoad={() => handleImageLoad(0)}
                loading="lazy"
                alt=""
              />

              <div className="showvideodetailsbelow">
                <div className="horizontal-divider-container">
                  <div className="horizontal-divider"></div>
                </div>

                <Container fluid className="pading-left-right-class">
                  <Row>
                    <Col>
                      <div id="contentvideo" className="flex-container videop">
                        {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates}  />
                      <div className="divider"></div> */}

                        <div className="combination">
                          <div className="imgabove-layer">
                            {(videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.high_16_9?.url) && (
                              <div className="circle-icon">
                                {(videodetailsitems?.thumbnails?.web_banner
                                  ?.url || videodetailsitems?.thumbnails?.high_16_9?.url) && (
                                  <Link to="" onClick={handleShowLayer}>
                                    <FaPlay />
                                  </Link>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="text">Watch Now</div>
                        </div>

                        {/* <WatchListFeature  />
                      <Likefeature likedContentid={likedContentid}  /> */}
                        <ShareFeature />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      getPreviewWithoutLogin?.login_status &&
      getPreviewWithoutLogin?.is_subscribed
    ) {
      return (
        <>
          {isBuffering && <div>Loading...</div>}

          <div className="video-player-vm">
            {showThumbnail && videoThumbnail ? (
              <div className="image-containers image-container_thumnail">
                <div className="imgabove-layer">
                  {!loading && (
                    <div className="showvideodetailsbelow">
                      <div className="horizontal-divider-container">
                        <div className="horizontal-divider"></div>
                      </div>

                      <Container fluid className="pading-left-right-class">
                        <Row>
                          <Col>
                            <div id="contentvideo" className="flex-container videop">
                              {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates}  />
                      <div className="divider"></div> */}

                              <div className="combination">
                                <div className="circle-icon">
                                  <Link to="" onClick={handleShowLayer}>
                                    <FaPlay onClick={handleShowVideo} />
                                  </Link>
                                </div>
                                <div className="text">Watch Now</div>
                              </div>

                              {/* <WatchListFeature  /> */}
                              {/* <Likefeature likedContentid={likedContentid}  /> */}
                              <ShareFeature />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  )}

                  {!(videodetailsitems?.thumbnails?.web_banner?.url || videodetailsitems?.thumbnails?.high_16_9?.url) ? (
                    <>
                    <SkeletonVideoThumbnail height={430} />
                     
                    </>
                  ) : (
                    <img
                        src={
                          videoThumbnail
                        }
                        onLoad={() => handleImageLoad(0)}
                        loading="lazy"
                        style={{
                          height: "100%",
                          maxHeight: "600px",
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                  )}
                </div>
              </div>
            ) : (
              <>
                <Row>
                  <Col lg={8}>
                    {getPreviewWithoutLogin?.stream_info
                      ?.is_stream_limit_exceed === true ? (
                      <>
                        <div
                          className="StreamLimits"
                          style={{
                            backgroundImage: `url(${videodetailsitems?.thumbnails?.high_16_9?.url})`,
                          }}
                        >
                          <div className="info">
                            <h6 className="text-center">
                              Maximum Simultaneous streams reached
                            </h6>
                            <p>
                              Please stop playing on other devices & click retry
                            </p>
                            <p>or</p>
                            <p>
                              logout of all / any devices to continue watching
                            </p>

                            <Row>
                              <Col
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6}
                                style={{ textAlign: "center" }}
                              >
                               <Link onClick={() => {
  if (getPreviewWithoutLogin?.stream_info?.is_stream_limit_exceed === true) {
    getPreviewVideosWithoutLogin();
  } else {
    window.location.reload();
  }
}} className="login-button w-100">
  Retry
</Link>
                              </Col>
                              <Col
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6}
                                style={{ textAlign: "center" }}
                              >
                                <Link
                                  to=""
                                  className="login-button w-100"
                                  onClick={() =>
                                    dispatch(
                                      setShowLogoutAllModal({
                                        showLogoutAllModal: true,
                                      })
                                    )
                                  }
                                >
                                  {" "}
                                  Signout all devices{" "}
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </>
                    ) : (
                      <CustomVideoPlayer videoSource={getPreviewWithoutLogin} />
                    )}

                    <div id="contentvideo" className="flex-container videop playerpage">
                      <WatchListFeature />
                      <Likefeature likedContentid={likedContentid} />
                      <ShareFeature />
                    </div>
                  </Col>
                  <Col lg={4} text-center>
                  <GPTAdSide />
                  <GPTAdSideTwo />
                    {/* <Link>
                      <img
                        src={
                          !imageLoadedArray[0]
                            ? mainBanner
                            : imageLoadedArray[0]
                            ? addBanner
                            : mainBanner
                        }
                        onLoad={() => handleImageLoad(0)}
                        loading="lazy"
                        className="w-100 add-banner"
                        alt=""
                      />
                    </Link> */}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </>
      );
    }
  }; 

  return (
    <>
      <div className={` image-containers ${getPreviewWithoutLogin?.stream_info?.is_stream_limit_exceed === true ? 'streamlimitexceed' : ""}`}>
        <div className="mainbanner">
          <div className="videomainid">
            {!showPreviewVideo ? (
              renderVideoPlayer()
            ) : (
              <SkeletonVideoThumbnail height={480} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HLSPlayerWithQualityControl;
