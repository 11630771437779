import { Col, Modal, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function CommonLogout({ show, handleClose, handleSignout, title, text }) {
  const logoutimg = "/images/logoutimg.png";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab'); 

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      className="border-class"
    >
      <Modal.Body>
        <div className="card-deck row just-content">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
            <div className="login-left-styles">
              <img src={logoutimg} alt={title} />
              <h6 className="text-center">{title}</h6>
              <p>Enjoy ETV Win with more benefits and features.</p>
            </div>

            <div className="form-style">
              <Row className="">
                <Col lg={6} md={6} sm={6} xs={12}>
                  <div className="cancel-button">
                    <Link to={(tab === 'info' && "/profile?tab=info") || (tab === 'transactions' && "/profile?tab=transactions") || (tab === 'subscription' && "/profile?tab=subscription")} onClick={handleClose}>Cancel</Link>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={6} xs={12}>
                  <div className="logout-all">
                    <Link to="" onClick={handleSignout}>
                      {text}
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CommonLogout;
